import React, { useState } from "react";
import View from "components/util/schema_form/fields/dashboard/DashboardBuilder.view";


function DashboardBuilder({
  name,
  label,
  required,
  disabled,
  caption,
  helpMessage,
  values,
  flowId,
  dashboardButtonText,
  setFieldValue,
}) {

  const [isEditDashboardShown, setIsEditDashboardShown] = useState(false);
  const initialDashboard = values[name];

  const modalProps = {
    name,
    label,
    required,
    caption,
    disabled,
    helpMessage,
    values,
    flowId,
    initialDashboard,
    dashboardButtonText,
    isEditDashboardShown,
    setFieldValue 
  }

  const onOpenDashboardModal = () => setIsEditDashboardShown(true);
  const onCloseDashboardModal = () => setIsEditDashboardShown(false);

  return (
    <View
      name={name}
      label={label}
      required={required}
      caption={caption}
      disabled={disabled}
      helpMessage={helpMessage}
      values={values}
      flowId={flowId}
      initialDashboard={initialDashboard}
      modalProps={modalProps}
      dashboardButtonText={dashboardButtonText}
      isEditDashboardShown={isEditDashboardShown}
      setFieldValue={setFieldValue}
      onOpenDashboardModal={onOpenDashboardModal}
      onCloseDashboardModal={onCloseDashboardModal}
    />
  );
}

DashboardBuilder.propTypes = {};

export default DashboardBuilder;