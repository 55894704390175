/* eslint-disable max-len */
import React from "react";
import { ActionElement } from "components/board/toolbox/actions/Actions.sub.ActionElement.view"
import $ from 'jquery';
import "styles/css/board/Toolbox.css";


const CategoryItem = ({ id, groupIdx, label, icon, actionSearch, hasFilters, isSuggestion = false }) =>
  <div key={"category-" + id}
    onClick={e =>
      setTimeout(() => {
        try {
          let currentScrollTop = $('#toolbox-actions').scrollTop()
          let nextScrollTop = $(e.target).offset().top - 400
          if (currentScrollTop - nextScrollTop < 0)
            $('#toolbox-actions').animate({
              scrollTop: nextScrollTop
            }, 500)
        } catch { 
          console.log("Error al hacer scroll")
        }
      }, 150)}
    className={"btn-category-wrapper apps "
      + (groupIdx % 2 == 0 ? "first-col " : "")
      + (isSuggestion ? "suggestions " : "")}
    style={{ display: actionSearch == "" && !hasFilters ? "block" : "none" }}>
    <a
      id={"category-" + id}
      class=" btn-category d-flex justify-content-between px-1 w-100 rounded" data-toggle="collapse"
      href={"#section-" + id} role="button" aria-expanded="false" aria-controls="schema-builder-hidden">
      <div className="d-flex flex-column justify-content-center w-100" style={{ cursor: "pointer" }}>
        <div className={"category-icon ml-0 " + icon}>
        </div>
        <div className="category-title">
          <span>{label}</span>
        </div>
      </div>
    </a>
  </div>;

const CollapseView = ({
  id,
  mainActions,
  hiddenActions,
  actionSearch,
  hasFilters,
  isSuggestion = false,
  groupActions = false,
  hiddenPlaceholder = "Opciones avanzadas",
  onClickAction,
  onDragStart,
  onEnableApp,
}) => {
  const sectionId = "section-" + id
  const hiddenId = "hidden-" + id

  const getInstallButton = (actions, idx) => {
    const app = actions[parseInt(idx)].app;
    const isInstalled = app.appSettings?.enabled ?? false;
    return !isInstalled ?
      <button className="btn btn-sm btn-outline-primary ml-2 mt-n1 py-0 px-2"
        onClick={() => onEnableApp(app.id)}>Instalar</button> : null
  }

  const getTitle = (actions, idx) =>
    (idx == 0 || actions[parseInt(idx)].app.name != actions[idx - 1].app.name) &&
      (groupActions || actionSearch != "" || hasFilters) ?
      <div className={"w-100 collapse-divider pr-1"}>
        <label className="w-100 text-left">
          {actions[parseInt(idx)].app.uiSettings?.label} {getInstallButton(actions, idx)}
        </label>
      </div> : null


  return (
    <div id={sectionId} key={"collapse-" + id}
      className={"collapse w-100 " +
        (actionSearch != "" || hasFilters || isSuggestion ? "show " : "") +
        (mainActions.length + hiddenActions.length == 0 ? "mt-0 mb-0 " :
          (actionSearch != "" || hasFilters ? "pt-1 pb-1 " :
            isSuggestion ? "pt-0 pb-0 mt-n1 " : "pt-0 pb-2 "))}
      data-parent={!isSuggestion ? "#toolbox-actions" : ""}>
      <ul className={"actions-list list-group pt-0 w-100 " + (actionSearch != "" || hasFilters ? "pb-0 " : "pb-1 ")}>
        {mainActions.map((action, idx) => {
          return (
            <div key={action.id}>
              {getTitle(mainActions, idx)}
              <ActionElement
                action={action}
                app={action.app}
                onClickAction={onClickAction}
                onDragStart={onDragStart}
              />
              {idx < mainActions.length - 1 &&
                mainActions[parseInt(idx)].uiSettings?.general?.group_label != mainActions[idx + 1].uiSettings?.general?.group_label ?
                <div className="my-3"></div> : null
              }
            </div>
          );
        })}

        <div className="hidden-actions">
          {hiddenActions.length > 0 ?
            <a
              class="btn btn-sm py-1 px-2 ml-1 text-left hidden-actions-title w-100 text-right" data-toggle="collapse"
              href={"#" + hiddenId} role="button" aria-expanded="false" aria-controls="schema-builder-hidden"
              style={{ color: "#858a90", fontSize: "0.88em" }}>
              {hiddenPlaceholder}
            </a> : null}

          <div id={hiddenId}
            class={"collapse " + (!isSuggestion ? "mt-1 pt-2 mb-2 " : "pt-1 pb-2")} >
            {hiddenActions.map((action, idx) => {
              return (
                <div key={action.id}>
                  <ActionElement
                    action={action}
                    app={action.app}
                    onClickAction={onClickAction}
                    onDragStart={onDragStart}
                  />
                  {idx < mainActions.length - 1 &&
                    mainActions[idx].uiSettings?.general?.group_label != mainActions[idx + 1].uiSettings?.general?.group_label ?
                    <div className="my-3"></div> : null
                  }
                </div>
              );
            })}
          </div>
        </div>

      </ul>
    </div>
  );
}


export { CategoryItem, CollapseView }