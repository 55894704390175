import React from "react";


const CheckboxField = ({
    name,
    label,
    value,
    disabled,
    caption,
    helpMessage="",
    setFieldValue
}) => {
    return (
        <div style={{ marginTop: "0.1rem" }}>
            <div class="custom-control custom-switch">
                <input type="checkbox" class={"custom-control-input"} id={name} disabled={disabled}
                    checked={value === true} onChange={() => setFieldValue(name, !(value === true))} />

                <label class="custom-control-label input-label" for={name}>
                    {label != "" ? <div className="ml-1">
                        <span>{label}</span>
                        {helpMessage != "" ?  <div style={{ fontSize: "0.95em", color: "#90959a" }}>{helpMessage}</div> : null}
                    </div> : null}
                    {caption != "" ? <div>
                        <span style={{ fontSize: "0.9em", color: "#a0a5aa" }}>{caption}</span>
                    </div> : null}
                </label>
            </div>
        </div>
    );
}

export default CheckboxField;