import React, { useState, useEffect } from "react";
import { usePost } from "seed/api";
import { Modal } from "seed/helpers";
import BaseField from "components/util/schema_form/fields/BaseField";
import TextField from "components/util/schema_form/fields/TextField";
import CheckboxField from "components/util/schema_form/fields/CheckboxField";
import SelectField from "components/util/schema_form/fields/SelectField";
import Filters from "components/collections/helpers/Filters";

const TableOptionsField = ({
  name,
	label,
	values,
	flowId,
	required,
	caption,  
	helpMessage,
  setFieldValue,
  query = "",
  showAcceptButton = false,
  showTitleOption = false,
  showColumnsOption = false,
  showViewOption = false,
  showEditableOption = false,
  showFiltersOption = false,
  onClose = () => {}

}) => {

  const [structure, setStructure] = useState(null);
  const [showFilters, setShowFilters] = useState(false);

  const [callStructure] = usePost("/collections/get_structure", {
    onCompleted: (data) => {
      if(data?.length > 0) setStructure(data[0].structure);
      else setStructure(data.structure);
    }
  });

  const getColumnOptions = () => {
    if(!structure) return [];
    return structure.map(c => ({ label: c.label, value: c.name }));
  }

  useEffect(() => {
    callStructure({ flow_id: flowId, query });
  }, []);

  return (
    <BaseField
        name={name}
        label={label}
        required={required}
        values={values}
        caption={caption}
        helpMessage={helpMessage}
        enableComments={false}
        setFieldValue={setFieldValue}
    >

    <ul className={"step step-form step-eicon-xs mb-0"}>

      {
        showTitleOption &&
          <FieldWrapper>
            <div className="d-flex justify-content-between align-items-end">
              <TextField
                required={true}
                label={"Título"}
                name={ `title` }
                values={values}
                setFieldValue={setFieldValue}
              />
            </div>
          </FieldWrapper>
      }

      {
        showColumnsOption &&
          <FieldWrapper>
            <div className="w-100 mt-1 text-dark">
              Columnas <span className='text-danger ml-1'>*</span>
            </div>
            {
              (values.columns??[]).map((col, colIdx) => (
                <div
                  key={`table_${name}_col_${colIdx}`}
                  className="d-flex align-items-center w-100"
                >
                  <SelectField
                    required={true}
                    value={col}
                    values={values}
                    name={`columns[${colIdx}]`}
                    options={getColumnOptions()}
                    setFieldValue={setFieldValue}
                  />
                  <button
                    type="button"
                    class="btn btn-link px-1"
                    onClick={() => {
                      let tempCols = values.columns;
                      tempCols.splice(colIdx, 1);
                      setFieldValue(`columns`, tempCols);
                    }}
                    style={{ color: "#c0c5d5", fontSize: "1.15em" }}
                  >
                    <i class="fas fa-minus-circle"></i>
                  </button>
                </div>
              ))
            }
            <button
              type="button"
              class="btn pl-0 mt-1 pt-1"
              onClick={() => setFieldValue(`columns`, [...(values.columns??[]), null])}
            >
              <div className="d-flex align-items-center">
                <i class="fas fa-plus"></i>&nbsp;Agregar
              </div>
            </button>
          </FieldWrapper>
      }

      {
        showViewOption &&
          <FieldWrapper>
            <SelectField
              required={true}
              label={"Visualización"}
              name={`view`}
              value={values.view}
              values={values}
              setFieldValue={setFieldValue}
              options={[
                { label: "Vista de tabla", value: "TABLE" },
                { label: "Vista de tarjetas", value: "CARDS" },
                { label: "Vista de lista", value: "LIST" }
              ]}
            />
          </FieldWrapper>
      }

      {
        showEditableOption &&
          <FieldWrapper>
            <CheckboxField
              label={"Mostrar controles de edición"}
              name={`is_editable`}
              value={values.is_editable}
              setFieldValue={setFieldValue}
            />
          </FieldWrapper>
      }

      {
        showFiltersOption &&
          <FieldWrapper>
            <button
              type="button"
              className="btn btn-outline-primary"
              onClick={() => setShowFilters(true)}
            >
              Filtros
            </button>
          </FieldWrapper>
      }

      {
        showAcceptButton &&
          <button
            type="button"
            class="btn btn-primary btn-block mt-3"
            onClick={onClose}
          >
            Aceptar
          </button>
      }

      {
        showFilters &&
          <Modal
            width="800"
            height={window.innerHeight - 150}
            onClose={() => setShowFilters(false)}
            component={() =>
              <div className="card card-body w-100">
                <Filters
                  columns={getColumnOptions()}
                  query={values.query}
                  filters={values.filters}
                  onChangeQuery={(query) => setFieldValue(`query`, query)}
                  onChangeFilters={(filters) => setFieldValue(`filters`, filters)}
                  onClose={() => setShowFilters(false)}
                />
              </div>
            }
          />
      }

    </ul>

    </BaseField>
);

}

const FieldWrapper = ({ children, className = "" }) => (
  <li className={"step-item " + className}>
    <div className="step-content-wrapper">
      <span className="step-icon step-icon-pseudo step-icon-soft-dark ml-2"></span>
      <div className="step-content mr-4 pt-0">
        {children}
      </div>
    </div>
  </li>
)

export default TableOptionsField;