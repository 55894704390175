import React from "react";
import { Loading } from "seed/helpers";

const TemplatesView = ({
  ideas,
  isNodeIdeasLoading,
  isUseCaseLoading,
  onClickUseCase
}) => (
  <div className="h-100">
    <div id="toolbox-ideas" className="w-100 scrollbar-narrow d-flex flex-column">

      <div className="w-100 section-title pr-1 mt-0">
        <label className="w-100 text-left">
          Ideas
        </label>
      </div>

      {(isNodeIdeasLoading || isUseCaseLoading) ? <Loading /> : (
        <>
          {ideas.map((idea, i) => (
            <div key={i} className="flow-sample mb-3" onClick={() => onClickUseCase(idea)}>
              <a className="btn btn-outline-secondary btn-flow px-3 w-100 d-inline-block">
                <div className="d-flex align-items-center justify-content-start w-100">

                  <div className="text-left">
                    <div className="pb-0 mb-0 action-title">
                      {idea?.flow?.name}
                    </div>
                    <div className="action-subtitle">
                      {idea?.flow?.description}
                    </div>
                  </div>
                </div>
              </a>
            </div>
          ))}
        </>
      )}
    </div>

  </div>
)

export default TemplatesView;