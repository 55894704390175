import React, { useState } from "react";
import PropTypes from "prop-types";
import View from "components/util/schema_form/fields/rich_text/VariableSelectorModal.view";

function VariableSelectorModal({ flowId, nodeId, onVariableSelected }) {

  const onClickAddReference = (values) => {
    const reference = values?.reference ?? null;
    onVariableSelected(reference)
  }

  return (
    <View
      flowId={flowId}
      nodeId={nodeId}
      onClickAddReference={onClickAddReference}
    />
  );
}

VariableSelectorModal.propTypes = {};

export default VariableSelectorModal;