import React from "react";
import { Link } from "react-router-dom";
import ExecModeCharts from "components/presentation/panels/Charts";
import ExecModeTable from "components/presentation/panels/Data";
import ExecModeForm from "components/presentation/panels/Form";
import HelpMessage from 'components/util/helpers/HelpMessage';
import { HelpComponent } from "components/util/schema_form/util/help_util";
import Tour from "components/util/tour/Tour";

const ExecModeView = ({
  flow,
  leftKey,
  rightKey,
  execModeSettings,
  activeTab,
  chartsRef,
  dataRef,
  isShareMode,
  isTutorialShown,
  currentTutorialStep,
  tutorialSteps,
  setActiveTab,
  onExecuteNode,
  onChangeExecMode,
}) => (
  <Tour
    steps={tutorialSteps}
    startAt={currentTutorialStep}
    show={isTutorialShown && !isShareMode}
    tutorialName={"exec_mode"}
  >
    <div
      id="exec-mode"
      className={"card animate__animated animate__fadeIn animate__faster px-4 px-lg-6"}
      style={{
        height: "100%",
        paddingTop: "1.25rem",
        paddingBottom: "1.25rem",
        boxShadow: "none",
        border: "none",
        background: "transparent",
      }}
    >

      {
        execModeSettings.length == 0
          ? <div className="d-flex justify-content-center align-items-center h-100 pb-7">
            <div className="h4 ml-1 d-flex flex-column justify-content-center align-items-center mb-5">
              <h3 className="text-muted text-center"
                style={{ width: "24rem", lineHeight: "1.7" }}>
                Personaliza tu proceso para utilizar el modo visual
              </h3>
              <button
                type="button"
                className="btn btn-lighter-white mt-2"
                style={{ width: "10rem" }}
                onClick={() => onChangeExecMode(false)}
              >
                <i className="fas fa-edit mr-1"></i> Editar proceso
              </button>
            </div>
          </div>
          : <>

            <div className="d-flex justify-content-between align-items-center"
              style={{ minHeight: "2.75rem" }}>
              {execModeSettings.length == 1 ?
                <div className="h1 d-flex align-items-baseline mb-2">
                  <span style={{ fontSize: "0.985em" }}>
                    {
                      execModeSettings.length > 1
                        ? execModeSettings[activeTab]?.title
                        : flow.name
                    }
                  </span>
                  {
                    flow.description ?
                      <div className="ml-1" style={{ fontSize: "0.75em" }}>
                        <HelpComponent helpMessage={flow.description} />
                      </div> : null
                  }
                </div> : 
                <div className="d-flex justify-content-start mt-n2 scrollbar-narrow"
                  style={{overflowX: "auto"}}>
                  {
                    execModeSettings.length > 1 && execModeSettings.map((execModeSetting, index) => (
                      <a
                        key={"exec-mode-tab-" + index}
                        style={{
                          maxWidth: "16rem",
                        }}
                        className={"btn exec-mode-tab d-flex align-items-center mr-3 " +
                          (activeTab === index ? "active" : "")}
                        onClick={() => setActiveTab(index)}
                      >
                        <span
                          className="text-truncate py-2"
                        >
                          {execModeSetting.title}
                        </span>
                      </a>
                    ))
                  }
                </div>}
              <div className="d-flex">

                {
                  function () {

                    const execModeSetting = execModeSettings[activeTab] ?? {};
                    const leftPanel = execModeSetting.left_panel ?? "";
                    const rightPanel = execModeSetting.right_panel ?? "";

                    if (leftPanel == "ACTIVITY_CHART" || rightPanel == "ACTIVITY_CHART")
                      return;

                    return <HelpMessage message="Visualiza la actividad de tu proceso">
                      <Link to="/flow/activity" className="btn btn-lighter-white ml-2"
                        style={{ paddingRight: "0.85rem", paddingLeft: "0.85rem" }}>
                        <i className="fas fa-chart-line mr-1"></i> Actividad
                      </Link>
                    </HelpMessage>

                  }()
                }

                {
                  (execModeSettings[activeTab]?.actions ?? []).map((action, index) =>
                    <button
                      key={"exec-mode-action-" + index}
                      type="button"
                      className={`btn ${index == 0 ? "btn-success" : "btn-outline-success"} px-3 mb-0 ml-2`}
                      onClick={() => onExecuteNode(action.id)}>
                      <i className="fas fa-play mr-1"></i> {action.title ?? "Ejecutar proceso"}
                    </button>
                  )
                }

              </div>
            </div>

            <div
              id="exec-mode-content"
              className="scrollbar-narrow row h-100 mt-0 mt-lg-2"
              style={{ overflowY: "auto" }}
            >
              {
                function () {

                  const execModeSetting = execModeSettings[activeTab] ?? {};
                  const leftPanel = execModeSetting.left_panel ?? "";
                  const rightPanel = execModeSetting.right_panel ?? "";

                  const getPanel = (panel, isFullWidth = false, isRightPanel = false) => {

                    if (panel === "FORM")
                      return <ExecModeForm
                        key={isRightPanel ? rightKey : leftKey}
                        flow={flow}
                        isFullWidth={isFullWidth}
                        isRightPanel={isRightPanel}
                        execModeSetting={execModeSetting}
                        onExecuteNode={onExecuteNode}
                      />

                    if (panel === "TABLE")
                      return <ExecModeTable
                        key={isRightPanel ? rightKey : leftKey}
                        ref={dataRef}
                        flow={flow}
                        isFullWidth={isFullWidth}
                        isRightPanel={isRightPanel}
                        execModeSetting={execModeSetting}
                        onExecuteNode={onExecuteNode}
                      />

                    if (panel === "ACTIVITY_CHART")
                      return <ExecModeCharts
                        key={isRightPanel ? rightKey : leftKey}
                        ref={chartsRef}
                        flow={flow}
                        isFullWidth={isFullWidth}
                        isRightPanel={isRightPanel}
                        execModeSetting={execModeSetting}
                        onExecuteNode={onExecuteNode}
                      />

                  }

                  if (leftPanel == "EMPTY" || rightPanel == "EMPTY")
                    return <div className="col-lg-12 h-100">
                      <div
                        className="border rounded w-100 h-100 bg-white"
                        style={{
                          maxHeight: "100%",
                          overflowY: "hidden",
                        }}
                      >
                        {getPanel(leftPanel, true, true)}
                        {getPanel(rightPanel, true, true)}
                      </div>
                    </div>

                  return <>
                    <div className="col-lg-6 h-100 mt-3 mt-lg-0 pr-2">
                      <div className="border rounded w-100 h-100 bg-white">
                        {getPanel(leftPanel, false, false)}
                      </div>
                    </div>
                    <div className="col-lg-6 h-100 mt-3 mt-lg-0 pl-2">
                      <div className="border rounded w-100 h-100 bg-white">
                        {getPanel(rightPanel, false, true)}
                      </div>
                    </div>
                  </>

                }()
              }
            </div>
          </>

      }

    </div>
  </Tour>
)

export default ExecModeView;