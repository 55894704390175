import React, { useRef, useEffect, useState } from 'react'

const CollapseMenu = ({
  options,
  component,
  ...props
}) => {

  const key = Math.random().toString(36).substring(7);
  const menuRef = useRef(null);
  const [isShown, setIsShown] = useState(false);

  const checkMenu = (e) => {
    if(menuRef?.current && !menuRef?.current?.contains(e.target))
      setIsShown(false);
  }

  useEffect(() => {
    document.addEventListener("mousedown", checkMenu);
    return () => document.removeEventListener("mousedown", checkMenu);
  }, []);

  return <div 
    ref={menuRef}
    style={{
      position: "relative",
    }}
  >

    {
      React.cloneElement(component, {
        onClick: () => setIsShown(!isShown),
        ...props
      })
    }

    {
      isShown &&
        <div
          className='dropdown show'
          style={{
            position: "absolute",
            zIndex: 1000,
            top: "-10px",
              right: "-5px",
          }}
        >
          <div class="dropdown-menu show" aria-labelledby="add-column-dropdown">
            {
              options.map((option, index) =>
                <a 
                  key={key + index}
                  className="dropdown-item"
                  href="#"
                  onClick={option.onClick}
                >
                  {option.label}
                </a>
              )
            }
          </div>
        </div>
    }

  </div>

}

export default CollapseMenu;