import React, { useEffect, useState } from "react";
import { usePost } from "seed/api";
import swal from "sweetalert";
import View from "components/board/toolbox/templates/Templates.view";


function Templates({
  flowId,
  pageId,
  reloadFlow,
  currentNodeContext
}) {

  const [ideas, setIdeas] = useState([]);

  const [callNodeIdeas, reqNodeIdeas] = usePost(`/flows/get_ideas_from_node`, {
    onCompleted: (data) => {
      setIdeas(data);
    }
  });

  const [callUseCase, reqUseCase] = usePost(`/flows/implement_case`, {
    onCompleted: () => {
      reloadFlow();
    },
    onError: (error) => {
      console.log(error);
      swal("Error", "Error al implementar la idea, por favor intente de nuevo mas tarde", "error");
    }
  });

  useEffect(() => {
    if (currentNodeContext) {
      callNodeIdeas({
        position: currentNodeContext.position,
        node_id: currentNodeContext.node_id,
        mode: currentNodeContext.mode
      })
    }

  }, [currentNodeContext])

  const onClickUseCase = (idea) => {

    const sampleFlowId = idea?.flow?.id;
    const startNodeId = idea?.node?.id;

    callUseCase({
      sample_flow_id: sampleFlowId,
      sample_node_id: startNodeId,
      flow_page_id: pageId,
      node_id: currentNodeContext.node_id,
      position: currentNodeContext.position,
      mode: currentNodeContext.mode
    })

  }

  const isNodeIdeasLoading = reqNodeIdeas.loading;
  const isUseCaseLoading = reqUseCase.loading;
  if (reqNodeIdeas.error) return "Error";


  return (
    <View
      ideas={ideas}
      isNodeIdeasLoading={isNodeIdeasLoading}
      isUseCaseLoading={isUseCaseLoading}
      onClickUseCase={onClickUseCase}
    />
  );
}

export default Templates;