import React, { useEffect, useState } from "react";
import ExcelJS from "exceljs";
import BaseField from "components/util/schema_form/fields/BaseField";
import Table from "components/collections/Table";

function DataViewer({
  name,
  label,
  caption,
  helpMessage,
  values,
  setFieldValue,
  dataName
}) {

  const [data, setData] = useState(null);
  const [ext, setExt] = useState(null);

  const readFile = (onLoad) => {
    const reader = new FileReader();
    reader.onload = (e) => onLoad(e.target.result);
    return reader;
  }

  const onChangeData = () => {

    const fields = dataName.split(".");
    let value = values;
    fields.forEach((field) => {
      if(value)
        value = value[field];
    });

    if(value && value.includes("http")) {
      
      const ext = value.split(".").pop();
      setExt(ext);

      fetch(value)
        .then(response => response.blob())
        .then(blob => {

          switch(ext) {
            case "csv":
              readFile((data) => readCSV(data, setData)).readAsText(blob);
              break;
            case "xls":
            case "xlsx":
              readFile((data) => readExcel(data, setData)).readAsArrayBuffer(blob);
              break;
            case "jpg":
            case "jpeg":
            case "png":
            case "gif":
              readFile((data) => readGeneric(data, setData)).readAsDataURL(blob);
              break;
            case "pdf":
              readFile((data) => readGeneric(data, setData)).readAsDataURL(blob);
              break;
            default:

          }

        });

    }

  }

  useEffect(() => {
    onChangeData();
  }, [values]);

  return (
    <div>
      <BaseField
        name={name}
        label={label}
        values={values}
        caption={caption}
        helpMessage={helpMessage}
        enableComments={false}
        setFieldValue={setFieldValue}
      >
        <input type="hidden" name={name} value={true} />
        {
          data && (ext == "csv" || ext == "xls" || ext == "xlsx") &&
            <div
              style={{
                maxWidth: "95%",
              }}
            >
              <Table
                showExport={false}
                showFilters={false}
                showPagination={false}
                showForm={false}
                showImport={false}
                showSearch={false}
                isReadOnly={true}
                structure={data.headers}
                values={data.rows}
              />
            </div>
        }
        {
          data && (ext == "jpg" || ext == "jpeg" || ext == "png" || ext == "gif") &&
            <img 
              src={data} 
              alt="Preview"
              style={{ maxWidth: "100%", maxHeight: "300px" }} 
            />
        }
        {
          data && ext == "pdf" &&
            <embed 
              src={data} 
              width="100%" 
              height="600px" 
            />
        }
      </BaseField>
    </div>
  );

}

const readCSV = (file, setData) => {

  file = file.replace(/\r/g, "");
  let rows = file.split("\n").map((row) => row.split(","));
  if(rows[rows.length - 1].length == 1 && rows[rows.length - 1][0] == "")
    rows.pop();

  const headers = rows.shift().map((header) => 
    ({ name: header, label: header, type: "string" }));

  rows = rows.map((row) => 
    row.reduce((acc, val, idx) => 
      ({ ...acc, [headers[parseInt(idx)].name]: val }), {}));

  rows = rows.map((row) => ({ data: row }));
  setData({ headers, rows });

}

const readExcel = (file, setData) => {
  
  const workbook = new ExcelJS.Workbook();
  workbook.xlsx.load(file).then(workbook => {

    const sheet = workbook.getWorksheet(1);
    let rows = sheet.getSheetValues();
    rows.shift();
    rows.forEach((row) => row.shift());

    rows.forEach((row) => 
      row.forEach((value, idx) => {
        if(value instanceof Date)
          row[parseInt(idx)] = value.toLocaleDateString();
        else
          row[parseInt(idx)] = value.toString();
      }
    ));

    const headers = rows.shift().map((header) => 
      ({ name: header, label: header, type: "string" }));

    rows = rows.map((row) => 
      row.reduce((acc, val, idx) => 
        ({ ...acc, [headers[idx].name]: val }), {}));

    rows = rows.map((row) => ({ data: row }));
    setData({ headers, rows });

  });
  
}

const readGeneric = (file, setData) =>
  setData(file);

export default DataViewer;