import React, { useState, useRef, useEffect } from "react";
import { usePost } from "seed/api";
import { areas, positions } from "components/studio/ideas/Ideas.lib.flow";
import View from "components/studio/ideas/Ideas.view"

const Ideas = ({
  flowId,
  pageId,
  reloadFlow,
}) => {

  const formikRef = useRef(null);
  const cacheRef = useRef({});
  const [options, setOptions] = useState({ areas });
  const [formKey, setFormKey] = useState(null);
  const [suggestion, setSuggestion] = useState(null);
  const [currentKey, setCurrentKey] = useState(null);
  const [isHistoryShown, setIsHistoryShown] = useState(false);
  const [initialized, setInitialized] = useState(false);

  const [callGenerateGuide, reqGenerateGuide] = usePost('/flows/generate_suggestion_guide', {
    onCompleted: () => reloadFlow()});

  const [callGenerateSolution, reqGenerateSolution] = usePost('/flows/generate_flow_suggestion', {
    onCompleted: (data) => setSuggestion(data)});

  const [callGenerateOptions, reqGenerateoptions] = usePost('/flows/generate_generic_response', {
    onCompleted: (data) => {
      setInitialized(true);
      setOptions({ ...options, [currentKey]: data.response });
      if(formKey) formikRef.current.setFieldValue(formKey, data.response);
    },
  });

  const generateOptions = (text, name, formKey = null) => {
    setOptions({ ...options, [name]: null });
    setCurrentKey(name);
    setFormKey(formKey);
    callGenerateOptions({ text });
  }

  const onGeneratePositions = (area) => {

    if(!area) {
      setOptions({ ...options, positions: [] });
      return;
    }

    if(positions[area]) {
      setOptions({ ...options, positions: positions[area] });
      return;
    }

    const id = Math.random();
    cacheRef.current["area"] = id;
    setTimeout(() => {
      if(cacheRef.current["area"] == id)
        generateOptions("Puestos de trabajo para: " + area, "positions");
    }, 1200);

  }

  const onGenerateProcesses = (area, position) =>
    generateOptions(
      "Procesos para: " + area + " en " + position +
      " (NECESITO QUE LOS PROCESOS QUE PROPONGAS SEAN AUTOMATIZABLES MEDIANTE UNA SAAS, SI NO SE PUEDE NO PROPONGAS NADA)" + 
      " (también tu redacción debe ser clara y concisa, trata de que se hable más de las acciones como sustantivos)"
      , "processes");

  const onGenerateProcess = (process) =>
    generateOptions(
      "Paso por paso el siguiente proceso: " + process +
      " (No agregues numeración) (tu redacción debe ser clara y consica, trata de que las acciones sean verbos)", 
      "processSteps"
    );

  const onGenerateSolution = () => {
    const values = formikRef.current.values;
    setSuggestion(null);
    callGenerateSolution({ flow_id: flowId, page_id: pageId, ...values });
  }

  const onCreateGuide = () => {
    callGenerateGuide({ flow_id: flowId, page_id: pageId, suggestion });
    formikRef.current.setValues({ });
    sessionStorage.removeItem("flow-suggestions-form");
    sessionStorage.removeItem("flow-suggestions-options");
    sessionStorage.removeItem("flow-suggestions-suggestion");
  }

  const onChangeStep = (values) => {
    if(values.initials) return
    sessionStorage.setItem("flow-suggestions-form", JSON.stringify(values));
    sessionStorage.setItem("flow-suggestions-options", JSON.stringify(options));
    sessionStorage.setItem("flow-suggestions-suggestion", JSON.stringify(suggestion));
  }

  const isLoading = reqGenerateSolution.loading || reqGenerateSolution.loading;

  useEffect(() => {
    const previousValues = JSON.parse(sessionStorage.getItem("flow-suggestions-form") || "{}");
    const previousOptions = JSON.parse(sessionStorage.getItem("flow-suggestions-options") || "{}");
    const previousSuggestion = JSON.parse(sessionStorage.getItem("flow-suggestions-suggestion") || "{}");
    formikRef.current.setValues({ ...formikRef.current.values, ...previousValues, initials: false });
    setOptions({ ...options, ...previousOptions });
    setSuggestion(previousSuggestion);
  }, []);

  useEffect(() => {
    if(initialized) return;
    onGenerateProcesses("Administración", "Gerente Administrativo");
    formikRef.current.setValues({ ...formikRef.current.values, area: "Administración", position: "Gerente Administrativo" });
  }, [initialized]);

  return <View 
    formikRef={formikRef}
    flowId={flowId}
    pageId={pageId}
    options={options}
    suggestion={suggestion}
    initialized={initialized}
    isLoading={isLoading}
    isHistoryShown={isHistoryShown}
    setIsHistoryShown={setIsHistoryShown}
    onGeneratePositions={onGeneratePositions}
    onGenerateProcesses={onGenerateProcesses}
    onGenerateProcess={onGenerateProcess}
    onGenerateSolution={onGenerateSolution}
    onCreateGuide={onCreateGuide}
    onChangeStep={onChangeStep}
    reloadFlow={reloadFlow}
  />

}

export default Ideas;