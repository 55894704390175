import React from "react";
import ProcessIcon from "components/util/helpers/ProcessIcon";
import { Link } from "react-router-dom";

const DevView = ({
  flows,
  selectedFlows,
  setSelectedFlows,
  onExport,
  onImport,
}) => (
  <div id="dev">

    <div className="card ">

      <div className="card-header">
        <h3 className="card-title">Importar procesos</h3>
      </div>

      <div className="card-body animate__animated animate__fadeIn animate__faster">
        <p class="card-text">Selecciona un zip con los datos del proceso</p>
        <button
          type="button"
          className="btn btn-sm btn-primary w-100"
          onClick={onImport}>
          <i class="fas fa-upload mr-1"></i>
          Importar
        </button>
      </div>
    </div>


    <div className="card mt-3">

      <div className="card-header">
        <h3 className="card-title">Exportar procesos</h3>
      </div>

      <div className="card-body">
        <ul class="list-group" id="flow-list">

          <li class="list-group-item my-0 py-0 d-flex justify-content-between align-items-center">
            <div className="w-100 h6 m-0 p-0">
              Seleccionar todos
            </div>
            <div className="d-flex align-items-center justify-content-end w-100">
              <div className="d-flex align-items-center h-100">
                <input
                  class="form-control"
                  type="checkbox"
                  onChange={(e) => {
                    if (e.target.checked)
                      setSelectedFlows(flows.map(flow => flow.id));
                    else
                      setSelectedFlows([]);
                  }}
                />
              </div>
            </div>
          </li>

          {flows.map((flow, index) => (
            <li class="flow list-group-item mb-1" key={flow.id}>

              <div className="d-flex align-items-center w-100">
                <div className="mr-3">
                  <ProcessIcon className="avatar avatar-xs" />
                </div>
                <div className="flex-grow-1">
                  <h5 class="mb-0">
                    <Link to={`/flow/${flow.id}/flow`} className="text-dark">
                      {flow.name}
                    </Link>
                    {flow.isTemplate
                      ? <span class="badge badge-soft-primary ml-1">Plantilla</span>
                      : null}
                  </h5>
                  <ul class="list-inline list-separator small">
                    <li class="list-inline-item">
                      <i class="far fa-folder mr-1" style={{ display: flow.folder ? "inline-block" : "none" }}></i>
                      <span>{flow.folder?.name ?? 'General'}</span>
                    </li>
                  </ul>
                </div>
                <div className="d-flex align-items-center h-100">
                  <input
                    id={`flow-${index}`}
                    class="form-control"
                    type="checkbox"
                    checked={selectedFlows.includes(flow.id)}
                    onChange={(e) => {
                      if (e.target.checked)
                        setSelectedFlows([...selectedFlows, flow.id]);
                      else
                        setSelectedFlows(selectedFlows.filter(id => id !== flow.id));
                    }}
                  />
                </div>
              </div>

            </li>
          ))}
        </ul>
        <button
          type="button"
          className="btn btn-sm btn-primary ml-2 mt-2 w-100"
          onClick={onExport}
        >
          <i class="fas fa-download mr-1"></i>
          Exportar
        </button>
      </div>

    </div>
  </div>
);

export default DevView;