import React from "react";
import { EditorContent } from '@tiptap/react'
import MenuBar from "components/util/schema_form/fields/rich_text/Editor.sub.menubar.view";
import { StyleTag } from "seed/helpers";

import { FloatingMenu } from '@tiptap/react'


const EditorView = ({
  wrapperId,
  singleLine,
  rows,
  showStyles,
  editor,
}) => <div className="rich-text" id={"_" + wrapperId}>
    {showStyles && <MenuBar editor={editor} />}
    {singleLine ?
      <StyleTag
        content={`
            #_${wrapperId} .tiptap {
              padding: 0.54688rem 1rem !important;
              overflow-y: hidden;
              white-space: nowrap;
              overflow-x: auto;
              width: 100%;
            }           
            #_${wrapperId} .tiptap>br {
              display: none !important;
            }
          `}
      /> :
      <StyleTag
        content={`
            #_${wrapperId} .tiptap {
              min-height: ${1.55 * rows}rem !important;
              max-height: ${1.55 * rows * 2}rem !important;
          `}
      />

    }
    <EditorContent editor={editor} />
    <div className="help-message">Inserta referencias con #</div>
  </div>

export default EditorView;