
import React from "react";
import { Formik, Form } from "formik";
import * as Fields from "components/util/schema_form/fields";
import { FieldWrapper, MultipleFieldWrapper } from "components/presentation/Settings.sub.wrappers.view";

const TableSettingsView = ({
  execModeDefaults,
  currentDefaults,
  initialValues,
  isOptionRight,
  setParentFieldValue,
  onClose
}) => {

  const forms = Object.keys(execModeDefaults).map(key =>
    execModeDefaults[key].forms).reduce((acc, val) => acc.concat(val), [])

  const onSubmit = (values) => {
    setParentFieldValue("forms", (values.forms??[]).map(t => ({ is_right: isOptionRight, ...t })));
    setParentFieldValue("is_manual_forms", values.is_manual_forms)
    onClose()
  }

  return <div className="card p-3" style={{ overflowX: "hidden" }}>

    <h5 className="">Opciones de formulario</h5>

      <Formik
        initialValues={{
          is_manual_forms: initialValues.is_manual_forms ?? currentDefaults.is_manual_forms,
          forms: initialValues.forms ?? currentDefaults.forms,
        }}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form className="m-0 p-0">
            <MultipleFieldWrapper
              title={"Formularios"}
              isDefault={values.forms == currentDefaults.forms}
              values={values}
              valueKey={"forms"}
              setFieldValue={setFieldValue}
              onChangeDefaults={() => {
                setFieldValue("is_manual_forms", false)
                setFieldValue("forms", currentDefaults.forms)
              }}
            >
              {
                (values.forms??[]).map((form, idx) => (
                  (form.is_right == isOptionRight || form.is_right == null) &&
                  <>
                    <FieldWrapper>
                      <div className="d-flex justify-content-between align-items-end">
                        <Fields.SelectField
                          required={true}
                          value={form.id}
                          values={values}
                          name={`forms[${idx}].id`}
                          options={forms.map(f => ({ value: f.id, label: f.title }))}
                          onChange={(f) => {
                            let selectedForm = forms.find(form => form.id == f)
                            if(!currentDefaults) selectedForm = {};
                            setFieldValue(`forms[${idx}]`, selectedForm);
                          }}
                          setFieldValue={setFieldValue}
                        />
                      </div>
                    </FieldWrapper>
                  </>
                ))
              }
            </MultipleFieldWrapper>
            <button
              type="submit"
              class="btn btn-primary btn-block mt-3"
            >
              Guardar
            </button>
          </Form>
        )}
      </Formik>

  </div>

}

export default TableSettingsView;