import React from "react";
import swal from "sweetalert";
import { useQuery, useDelete, useDetail, useCount } from "seed/gql";
import { DELETE_FLOW } from "seed/gql/queries";
import { Loading } from "seed/helpers"
import { usePost } from "seed/api";
import View from "components/start/flows/Flows.view";


function Flows({ user, selectedFolder, setSelectedFolder }) {

  const userId = sessionStorage.getItem("id");
  let flowFilter = "";
  const [callDelete] = useDelete(DELETE_FLOW, {
    onCompleted: () => onDeleteFlow()
  })

  if (selectedFolder) {
    flowFilter = `user.id=${userId} AND folder.id=${selectedFolder}`;
  } else {
    flowFilter = `user.id=${userId} OR (participants.user.id=${userId} AND participants.isAccepted=True)`
  }

  const reqFlows = useQuery(`{
    flows {
      name
      createdAt
      user {
        firstName
        lastName
        license { name }
      }
      folder { name }
      participants {
        user { email }
      }
    }
  }`, flowFilter, { limit: 6, orderBy: "-updated_at" })  

  const reqFlowCount = useCount('flow', `user.id=${userId}`)

  const reqFolder = useDetail(`{
    folder { name }
  }`, selectedFolder);

  const [callClone] = usePost("/flows/clone", {
    onCompleted: () => {
      reqFlows.refetch();
      reqFlowCount.refetch();
    }
  });

  const onClickDelete = (flowId) => {
    swal({
      title: "Eliminar proceso",
      icon: "warning",
      text: "¿Estás seguro que desea eliminar el proceso?",
      buttons: {
        confirm: {
          text: "Aceptar",
          className: "swal-button swal-button--cancel btn-success",
        },
        cancel: "Cancelar",
      },
    }).then((response) => {
      if (response) {
        callDelete({ id: flowId });
      }
    })
  }

  const onClickClone = (flowId) =>
    callClone({ flow_id: flowId });

  const onCreateFlow = () => { }

  const onEditFlow = () => {
    reqFlows.refetch();
    reqFlowCount.refetch();
  }

  const onDeleteFlow = () => {
    reqFlows.refetch();
    reqFlowCount.refetch();
  }

  if (reqFlows.loading || reqFlowCount.loading || reqFolder.loading)
    return <PlaceholderView />;
  const flows = reqFlows.data?.flows ?? [];
  const flowCount = reqFlowCount.data.flowCount.count;
  const folder = reqFolder.data?.folder ?? null;

  return (
    <View
      user={user}
      folder={folder}
      flows={flows}
      flowCount={flowCount}
      reqFlows={reqFlows}
      reqFlowCount={reqFlowCount}
      setSelectedFolder={setSelectedFolder}
      onCreateFlow={onCreateFlow}
      onEditFlow={onEditFlow}
      onDeleteFlow={onDeleteFlow}
      onClickDelete={onClickDelete}
      onClickClone={onClickClone}
    />
  );
}

const PlaceholderView = () =>
  <div id="start-flows" className="card">
    <div id="start-flows-container" className="card-body mx-1">
      <div id="start-flows-header" className={"d-flex flex-wrap align-items-center justify-content-between"}>

        <small class="nav-subtitle mb-1" style={{ fontSize: "0.88rem", color: "#606570" }}>
          <i class="fas fa-shapes" style={{ marginRight: "0.75rem" }}></i>
          Procesos recientes
        </small>

        <div className="d-flex" style={{ marginTop: "-0.35rem" }}>
          <a href="#" className={"btn btn-primary ml-3 mr-1"}>
            <i class="fas fa-plus mr-2"></i>Crear proceso
          </a>
        </div>

      </div>

      <div id="flows" class="w-100">
        <div id="flows-container" class="d-flex flex-wrap justify-content-between">

          <div key="more" className="flow col-12 px-0 mb-2" style={{ minHeight: "14rem" }}>
            <div className="card card-hover-shadow pb-0 px-0 mr-2 h-100">
              <Loading />
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

export default Flows;