import React from "react";
import View from "components/util/schema_form/fields/schema_builder/SchemaBuilder.view";


function SchemaBuilder({ flowId, schema, schemaFieldsPlaceholder, onSubmit = () => { } }) {

  schema = schema ?? []
  const defaultFieldValue = { type: "TEXT", required: true, show_if: [] }
  const initialValues = schema.length > 0 ? schema : [defaultFieldValue]

  const onSubmitForm = (values) =>
    onSubmit(values)

  return (
    <View
      initialValues={initialValues}
      defaultFieldValue={defaultFieldValue}
      schemaFieldsPlaceholder={schemaFieldsPlaceholder}
      flowId={flowId}
      onSubmit={onSubmitForm}
    />
  );
}

export default SchemaBuilder;