
import React, { useRef } from "react";
import { Formik, Form } from "formik";
import { Modal } from "seed/helpers";
import * as Fields from "components/util/schema_form/fields";
import { FieldWrapper, MultipleFieldWrapper } from "components/presentation/Settings.sub.wrappers.view";
import Filters from "components/collections/helpers/Filters";

const TableSettingsView = ({
  flow,
  collections,
  currentDefaults,
  initialValues,
  isOptionRight,
  setParentFieldValue,
  getColumnOptions,
  onCreateTable,
  onClose
}) => {

  const tableFormRef = useRef(null);

  const onChangeSelectedTable = (value, index) => {
    
    const table = collections.find(c => `@${c.name}` == value);
    if(!table) return;
    
    const columns = table.schema.structure.map(c => c.name);
    const values = tableFormRef.current.values;
    
    if(!values.tables[index].title)
      tableFormRef.current.setFieldValue(`tables[${index}].title`, table.name);

    tableFormRef.current.setFieldValue(`tables[${index}].columns`, columns);
    tableFormRef.current.setFieldValue(`tables[${index}].query`, "");

  }

  const onSubmit = (values) => {
    setParentFieldValue("tables", (values.tables??[]).map(t => ({ is_right: isOptionRight, ...t })));
    setParentFieldValue("is_manual_tables", values.is_manual_tables);
    onClose();
  }

  return <div className="card p-3" style={{ overflowX: "hidden" }}>

    <h5 className="">Opciones de tabla</h5>

      <Formik
        innerRef={tableFormRef}
        initialValues={{
          is_manual_tables: initialValues.is_manual_tables ?? currentDefaults.is_manual_tables,
          tables: initialValues.tables ?? currentDefaults.tables
        }}
        onSubmit={onSubmit}
      >
        {({ values, setValues, setFieldValue }) => (
          <Form className="m-0 p-0">
            
            <MultipleFieldWrapper
              title={"Tablas"}
              isDefault={values.tables == currentDefaults.tables}
              values={values}
              valueKey={"tables"}
              defaultAddValue={{ columns: [], view: "TABLE", is_right: isOptionRight }}
              setFieldValue={setFieldValue}
              onChangeDefaults={() => {
                setFieldValue("is_manual_tables", false)
                setFieldValue("tables", currentDefaults.tables)
              }}
            >
              {
                (values.tables??[]).map((table, idx) => (
                  (table.is_right == isOptionRight || table.is_right == null) &&
                  <>

                    <FieldWrapper key={"table_" + idx}>
                      <Fields.VariableSelector
                        required={true}
                        label={"Tabla"}
                        flowId={flow.id}
                        value={table.name}
                        values={values}
                        name={`tables[${idx}].name`}
                        acceptTypes={["collection"]}
                        enableAttributes={false}
                        showCollectionAddButton={true}
                        showCollectionCondition={false}
                        setFieldValue={setFieldValue}
                        onSaveCollectionSuggestion={onCreateTable}
                        onChange={(value) => onChangeSelectedTable(value, idx)}
                      />
                    </FieldWrapper>

                    <button
                      class="btn btn-link p-0 mb-3"
                      type="button"
                      data-toggle="collapse"
                      data-target={`#table_${idx}_collapse`}
                      aria-expanded="false"
                      aria-controls={`table_${idx}_collapse`} 
                    >
                      Opciones avanzadas
                    </button>

                    <div class="collapse" id={`table_${idx}_collapse`}>

                      <Fields.TableOptionsField
                        flowId={flow.id}
                        name={`tables[${idx}]`}
                        setFieldValue={(name, values) => setFieldValue(`tables[${idx}].${name}`, values)}
                        values={values.tables[idx]}
                        displayAcceptButton={false}
                        query={table.name}
                        showAcceptButton={false}
                        showColumnsOption={true}
                        showTitleOption={true}
                        showFiltersOption={true}
                        showEditableOption={true}
                        showViewOption={true}
                      />

                    </div>

                    <hr className="my-3" />

                    {
                      table.show_filters &&
                        <Modal
                          width="800"
                          height={window.innerHeight - 150}
                          onClose={() => setFieldValue(`tables[${idx}].show_filters`, false)}
                          component={() =>
                            <div className="card card-body w-100">
                              <Filters
                                columns={getColumnOptions(table.name, collections)}
                                query={table.query}
                                filters={table.filters}
                                onChangeQuery={(query) => setFieldValue(`tables[${idx}].query`, query)}
                                onChangeFilters={(filters) => setFieldValue(`tables[${idx}].filters`, filters)}
                                onClose={() => setFieldValue(`tables[${idx}].show_filters`, false)}
                              />
                            </div>
                          }
                        />
                    }

                  </>
                ))
              }
            </MultipleFieldWrapper>

            <button
              type="submit"
              class="btn btn-primary btn-block mt-3"
            >
              Guardar
            </button>

            </Form>
        )}
      </Formik>

  </div>

}

export default TableSettingsView;