import React, { useState } from "react";
import _ from 'lodash';
import View from "components/util/schema_form/fields/dashboard/ModalBuilder.view";


const defaultChart = {
  title: null,
  type: null,
  data: {
    x: {
      value: null,
      grouper: null
    },
    y: {
      value: null,
      grouper: null
    },
  }
}


function ModalBuilder({
  name,
  flowId,
  initialDashboard,
  dashboardButtonText,
  setFieldValue,
  onClose
}) {

  const [charts, setCharts] = useState(
    initialDashboard ? initialDashboard :
      Array.from({ length: 4 }, (_, i) => ({ ...defaultChart, id: i }))
  );
  const [activeChart, setActiveChart] = useState(null);

  const onSelectChart = (chartId) => {
    setActiveChart({ ...charts.find(chart => chart.id == chartId) });
  }

  const onChangeTitle = (title) => {
    if (!activeChart) return;
    const newActiveChart = { ...activeChart };
    newActiveChart.title = title;
    const newCharts = charts.map(chart => chart.id == activeChart.id ? newActiveChart : chart);
    setActiveChart(newActiveChart);
    setCharts(newCharts);
  }

  const onChangeChartType = (chartType) => {
    if (!activeChart) return;
    const newActiveChart = { ...activeChart };
    newActiveChart.type = chartType;
    const newCharts = charts.map(chart => chart.id == activeChart.id ? newActiveChart : chart);
    setActiveChart(newActiveChart);
    setCharts(newCharts);
  }

  const onChangeXData = (value) => {
    if (!activeChart) return;
    const newActiveChart = _.cloneDeep(activeChart)
    newActiveChart.data.x.value = value;
    const newCharts = charts.map(
      chart => chart.id == newActiveChart.id ? newActiveChart : chart
    );
    setActiveChart(newActiveChart);
    setCharts(newCharts);
  }

  const onChangeYData = (value) => {
    if (!activeChart) return;
    const newActiveChart = _.cloneDeep(activeChart)
    newActiveChart.data.y.value = value;
    const newCharts = charts.map(
      chart => chart.id == newActiveChart.id ? newActiveChart : chart
    );
    setActiveChart(newActiveChart);
    setCharts(newCharts);
  }

  const onChangeXDataGrouper = (dataGrouper) => {
    if (!activeChart) return;
    const newActiveChart = _.cloneDeep(activeChart)
    newActiveChart.data.x.grouper = dataGrouper;
    const newCharts = charts.map(
      chart => chart.id == newActiveChart.id ? newActiveChart : chart
    );
    setActiveChart(newActiveChart);
    setCharts(newCharts);
  }

  const onChangeYDataGrouper = (dataGrouper) => {
    if (!activeChart) return;
    const newActiveChart = _.cloneDeep(activeChart)
    newActiveChart.data.y.grouper = dataGrouper;
    const newCharts = charts.map(
      chart => chart.id == newActiveChart.id ? newActiveChart : chart
    );
    setActiveChart(newActiveChart);
    setCharts(newCharts);
  }

  const onSaveDashboard = () => {
    setFieldValue(name, charts);
    onClose();
  }

  return (
    <View
      charts={charts}
      flowId={flowId}
      activeChart={activeChart}
      dashboardText={dashboardButtonText}
      onChangeTitle={onChangeTitle}
      onSelectChart={onSelectChart}
      onChangeXData={onChangeXData}
      onChangeYData={onChangeYData}
      onChangeChartType={onChangeChartType}
      onChangeXDataGrouper={onChangeXDataGrouper}
      onChangeYDataGrouper={onChangeYDataGrouper}
      onSaveDashboard={onSaveDashboard}
    />
  );
}

ModalBuilder.propTypes = {};

export default ModalBuilder;