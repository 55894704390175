import React from "react";
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';


function HelpMessage({ id = null, delay = 250, placement = "bottom", message = "", forceShow = false, style = {}, children }) {
    const showHelps = sessionStorage.getItem("show_helps") == "true";
    const formattedMessage = `<div style="white-space: pre-wrap;">${message}</div>`
    const generateRandomId = () => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        const charactersLength = characters.length;
        for (let i = 0; i < 6; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }       
    
    if(!showHelps && !forceShow)
        return children;
             
    return (
        <OverlayTrigger key="bottom" placement={placement} delay={delay}
            overlay={
                <Popover id={id || `help-message-popover-${generateRandomId()}`}>
                    <Popover.Body className="p-3" style={{
                        backgroundColor: "#f0f3f5",
                        border: "1px solid #d0d5da",
                        color: "#40454a",
                        borderRadius: "10px",
                        maxWidth: "12rem",
                        ...style
                    }}>
                        <div dangerouslySetInnerHTML={{ __html: formattedMessage }} />
                    </Popover.Body>
                </Popover>
            }
        >
            {children}
        </OverlayTrigger>
    )
}

export default HelpMessage;