import React from "react";
import { Field } from "formik";
import BaseField from "components/util/schema_form/fields/BaseField";


const TextAreaField = ({
	name,
	label,
	values,
	rows=5,
	required,
	disabled,
	caption,
	helpMessage,
	placeholder,
	enableComments,
	setFieldValue
}) => {
	return (
		<BaseField
			name={name}
			label={label}
			values={values}
			required={required}
			caption={caption}
			helpMessage={helpMessage}
			enableComments={enableComments}
			setFieldValue={setFieldValue}
		>
			<Field
				as="textarea"
				name={name}
				id={name}
				rows={rows}
				disabled={disabled}
				class={"form-control"}
				placeholder={placeholder}
				required={required}
			/>
		</BaseField>
	)
}

export default TextAreaField;