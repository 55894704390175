import React, { useState, useEffect } from 'react';
import { usePost, useGet, useGetCall } from 'seed/api';
import { Loading } from 'seed/helpers';
import { BrowserRouter, Route } from 'react-router-dom';
import Generator from 'components/tmisc/apps/rpa/generator/Generator';
import Designer from 'components/tmisc/apps/rpa/designer/RPADesigner';

const RouteGenerator = ({ match }) => {

  return (
    <div>

      {/* <div class="container py-5 py-sm-7"> */}

        {/* <a class="d-flex justify-content-center mb-5" href={"/"}>
          <img class="z-index-2"
            src="/theme/svg/logos/logo-white.svg" alt="Logo" style={{ height: "2.2rem" }} />
        </a> */}

        <BrowserRouter basename="/r">
          <Route path="/generator" component={Generator} />
          <Route path="/designer" component={Designer} />
        </BrowserRouter>

      {/* </div> */}
    </div>
  )
}

export default RouteGenerator;