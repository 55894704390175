import React from "react";
import { Modal } from "seed/helpers";
import Filters from "components/collections/helpers/Filters";
import ImportFile from "components/collections/helpers/Import";
import ExportFile from "components/collections/helpers/Export";
import Options from "components/collections/helpers/Options";
import Form from "components/collections/helpers/Form";
import TableControls from "components/collections/Table.sub.controls.view";
import TableDatagrid from "components/collections/Table.sub.datagrid.view";
import PaginationFooter from "components/util/helpers/PaginationFooter";

const TableView = ({
  height = "auto",
  columns = [],
  rows = [],
  filteredData = [],
  filteredColumns = [],
  collections = [],
  collectionColumns = [],
  filterDefault,
  isReadOnly = false,
  isLoading = false,
  isFilePreviewShown = false,
  isFilterModalShown = false,
  isOptionModalShown = false,
  isImportModalShown = false,
  isExportModalShown = false,
  isFormModalShown = false,
  isSearchShown = false,
  showFilters = true,
  showOptions = true,
  showImport = true,
  showExport = true,
  showSearch = true,
  showForm = false,
  tableRef,
  pageSize,
  pageNum,
  totalPages,
  query,
  filters,
  search,
  formValue,
  selectedCell,
  selectedFile,
  setSelectedCell,
  setSelectedFile,
  setFormValue,
  setIsFilePreviewShown,
  setIsFilterModalShown,
  setIsOptionModalShown,
  setIsImportModalShown,
  setIsExportModalShown,
  setIsFormModalShown,
  setIsSearchShown,
  setFilterDefault,
  onAddColumn = () => { },
  onChangeColumn = () => { },
  onChangeColumnOptions = () => { },
  onDeleteColumn = () => { },
  onAddRow = () => { },
  onDeleteRow = () => { },
  onInsertRow = () => { },
  onChangeRow = () => { },
  onImport = () => { },
  onExport = () => { },
  onDownloadZip = () => { },
  onPageChange = () => { },
  onChangeSearch = () => { },
  onChangeQuery = () => { },
  onChangeFilters = () => { },
  onClickCell = () => { },
  onShowEditModal = () => { },
  onSubmitForm = () => { },
  folioComponent
}) => {
  return (
    <div className="h-100" id="tables-board" style={{position: "relative"}}>

      <TableControls
        search={search}
        filters={filters}
        showSearch={showSearch}
        showImport={showImport}
        showExport={showExport}
        showForm={showForm}
        showFilters={showFilters}
        isReadOnly={isReadOnly}
        isSearchShown={isSearchShown}
        setIsImportModalShown={setIsImportModalShown}
        setIsExportModalShown={setIsExportModalShown}
        setIsFormModalShown={setIsFormModalShown}
        setIsSearchShown={setIsSearchShown}
        setIsFilterModalShown={setIsFilterModalShown}
        setSelectedCell={setSelectedCell}
        onChangeSearch={onChangeSearch}
      />

      <div 
        className="pt-1 mt-2 mb-1 scrollbar-narrow" 
        style={{ 
          overflowX: "auto" 
        }}
      >
        <TableDatagrid
          height={height}
          columns={columns}
          rows={filteredData}
          filteredData={filteredData}
          filteredColumns={filteredColumns}
          collections={collections}
          collectionColumns={collectionColumns}
          isReadOnly={isReadOnly}
          isLoading={isLoading}
          isFilePreviewShown={isFilePreviewShown}
          showOptions={showOptions}
          showFilters={showFilters}
          tableRef={tableRef}
          pageSize={pageSize}
          pageNum={pageNum}
          filters={filters}
          selectedCell={selectedCell}
          selectedFile={selectedFile}
          showForm={showForm}
          setSelectedCell={setSelectedCell}
          setSelectedFile={setSelectedFile}
          setFormValue={setFormValue}
          setIsFilterModalShown={setIsFilterModalShown}
          setIsFilePreviewShown={setIsFilePreviewShown}
          setIsOptionModalShown={setIsOptionModalShown}
          setIsFormModalShown={setIsFormModalShown}
          setFilterDefault={setFilterDefault}
          onAddColumn={onAddColumn}
          onChangeColumn={onChangeColumn}
          onDeleteColumn={onDeleteColumn}
          onAddRow={onAddRow}
          onDeleteRow={onDeleteRow}
          onInsertRow={onInsertRow}
          onChangeRow={onChangeRow}
          onDownloadZip={onDownloadZip}
          onClickCell={onClickCell}
          onShowEditModal={onShowEditModal}
          folioComponent={folioComponent}
        />
      </div>

      <div className="w-100 px-3 m-0" id="collection-pagination">
        <nav class="mt-3 scrollbar-narrow">
          <PaginationFooter
            pageNum={pageNum}
            totalPages={totalPages}
            onClickPage={onPageChange}
          />
        </nav>
      </div>

      {
        isFilterModalShown && <Modal
          width="800"
          height={window.innerHeight - 150}
          columns={columns}
          query={query}
          onClose={() => setIsFilterModalShown(false)}
          component={() =>
            <div className="card card-body w-100">
              <Filters
                columns={columns}
                query={query}
                filters={filters}
                filterDefault={filterDefault}
                onChangeQuery={onChangeQuery}
                onChangeFilters={onChangeFilters}
                onClose={() => setIsFilterModalShown(false)}
              />
            </div>
          }
        />
      }

      {
        isOptionModalShown && <Modal
          width="500"
          height={window.innerHeight - 150}
          columns={columns}
          onChangeColumnOptions={onChangeColumnOptions}
          onClose={() => setIsOptionModalShown(false)}
          component={Options}
        />
      }

      {
        isExportModalShown && <Modal
          width="325"
          height="400"
          rows={rows}
          columns={columns}
          onExport={onExport}
          onClose={() => setIsExportModalShown(false)}
          component={ExportFile}
        />
      }

      {
        isImportModalShown && <Modal
          width="600"
          height={window.innerHeight - 150}
          rows={rows}
          columns={columns}
          onImport={onImport}
          onClose={() => setIsImportModalShown(false)}
          component={ImportFile}
        />
      }

      {
        isFormModalShown && <Modal
          width="600"
          height={window.innerHeight - 150}
          title={formValue ? "Editar dato" : "Agregar dato"}
          structure={columns}
          initialRow={formValue}
          onSubmit={onSubmitForm}
          onClose={() => {
            setIsFormModalShown(false);
            setFormValue(null);
          }}
          component={Form}
        />

      }

    </div>
  )
}

export default TableView;