import React from "react";
import { Link } from "react-router-dom";
import { useQuery } from "seed/gql";
import { APP_URL } from "settings";

function stringToBase64(string) {
    const binString = Array.from(new TextEncoder().encode(string), (byte) =>
        String.fromCodePoint(byte),
    ).join("");
    return btoa(binString);
}

const AddAccount = ({ integrationNames = [] }) => {

    const userId = sessionStorage.getItem("id")
    const qIntegrations = useQuery(`{
        integrations {
            name
            uiSettings
            authSettings
        }
    }`, integrationNames ? `name=${integrationNames.join(" OR name=")} AND is_dummy=false` : "");

    if (qIntegrations.loading) return <div></div>;
    const integrations = qIntegrations.data.integrations

    return (
        <div className="card">

            <div className="card-header">
                <h3 className="card-header-title">Agregar cuenta</h3>
            </div>

            <div className="card-body pb-2">

                {
                    integrations.map((integration, idx) => {
                        const uiSettings = (integration.uiSettings)
                        const authSettings = (integration.authSettings)
                        const redirect_url = authSettings.redirect_uri ? authSettings.redirect_uri : `${APP_URL}/app/settings/auth`
                        const state = stringToBase64(JSON.stringify({
                            integration_id: parseInt(integration.id),
                            user_id: parseInt(userId),
                            app_url: `${APP_URL}${window.location.pathname}`,
                            redirect_url: redirect_url,
                        }))

                        const authParamNames = ['client_id', 'key'];
                        let authParamName = authParamNames.find(name => authSettings[name]);
                        let authParamValue = authSettings[authParamName];
                        let additionalParams = authSettings.additional_params || {};

                        let url = `${authSettings.auth_url}?response_type=${authSettings.response_type}&${authParamName}=${authParamValue}&redirect_uri=${redirect_url}&scope=${authSettings.scope}`;

                        for (const [key, value] of Object.entries(additionalParams)) {
                            url += `&${key}=${encodeURIComponent(value)}`;
                        }

                        return <a key={idx}
                            className='btn btn-primary btn-block'
                            onClick={() => {
                                sessionStorage.setItem("auth_state", state);
                                window.location.href = url;
                            }}
                        >
                            Iniciar sesión con  {uiSettings.label ?? integration.name}
                        </a>
                    })
                }
                <Link className='btn btn-link btn-block mt-2' href="/settings/external_apps">Ir a cuentas</Link>
            </div>
        </div>
    );
}

export default AddAccount;