import { parseRowByColumn } from "components/collections/util/datatypes";

const RowCallbaks = ({
  _columns,
  _rows,
  _pageSize,
	_totalPages,
	cacheRef,
	selectedCell,
  isReadOnly,
	showForm,
	checkValidation,
  setRows,
  setSelectedCell,
  onAddRow,
  onChangeRow,
  onDeleteRow,
  onInsertRow,
  _onPageChange
}) => {

  const _onAddRow = (data = {}) => {

		if(isReadOnly && !showForm) return;

		let newRow = { data };
		_columns.forEach(column => {
			if(!newRow.data[column.name]) 
				newRow.data[column.name] = null;
		});

		newRow.data = parseRowByColumn(newRow.data, _columns);
		setSelectedCell({ ...selectedCell, row: _rows.length });

		let newRows = [..._rows, newRow];
		if(newRows.length > _pageSize) {
			setSelectedCell({ ...selectedCell, row: 0 });
			_onPageChange(_totalPages);
		}

		setRows(newRows);
		onAddRow([{ final: newRow, action: "create" }]);

	}

	const _onDeleteRow = (index) => {

		if(isReadOnly) return;

		if(!checkValidation) {
			const newRows = [..._rows];
			newRows.splice(index, 1);
			setRows(newRows);
		}

		onDeleteRow(index);

	}

	const _onInsertRow = (index) => {

		if (isReadOnly) return;

		const newRows = [..._rows];
		const newRow = { data: {} };
		_columns.forEach(column => newRow.data[column.name] = null);
		newRows.splice(index, 0, newRow);

		setRows(newRows);
		setSelectedCell(null);
		document.activeElement.blur();
		
		onInsertRow([{
			aux: _rows[index],
			final: newRow,
			action: "insert"
		}]);

	}

	const _onChangeRow = (index, name, value) => {

		if (isReadOnly) return;

		const id = Math.random();
		const newRows = [..._rows];
		newRows[index].data = { ...newRows[parseInt(index)].data, [name]: value };
    cacheRef.current[`row_${index}`] = id;
		cacheRef.current[`row_${index}_initial`] = cacheRef.current[`row_${index}_initial`] ?? Object.assign({}, newRows[parseInt(index)]);

		setTimeout(() => {

      if(cacheRef.current[`row_${index}`] == id) {

				onChangeRow([
					{
						initial: cacheRef.current[`row_${index}_initial`],
						final: newRows[parseInt(index)],
						action: "update",
					}
				]);

				delete cacheRef.current[`row_${index}`];
				delete cacheRef.current[`row_${index}_initial`];

			}

    }, 1000);

		setRows(newRows);

	}

	const _onUpdateRow = (index, data) => {
		
		if(isReadOnly && !showForm) return;

		const newRows = [..._rows];
		newRows[parseInt(index)].data = data;

		setRows(newRows);
	
	}

  return {
    _onAddRow,
    _onDeleteRow,
    _onInsertRow,
    _onChangeRow,
    _onUpdateRow
  }

}

export default RowCallbaks;