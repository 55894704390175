import React, { useState } from "react";
import { useDetail } from "seed/gql";
import { usePost } from "seed/api";
import { Loading } from "seed/helpers";
import View from "components/tmisc/settings/Billing.view";

function Billing() {

  const userId = sessionStorage.getItem("id");
  const [isDetailsShown, setIsDetailsShown] = useState(false);

  const [callCheckoutUrl, reqCheckoutUrl] = usePost("/payments/get_checkout_url", {
    onCompleted: (data) => {
      console.log("data", data);
    },
    onError: (error) => {
      console.error(error)
    }
  })

  const reqUser = useDetail(`{
    user {
      accountType
      accountSettings
      license {
        name
        usageLimits
      }
    }  
  }`, userId);

  const onRechargePoints = () => {
    callCheckoutUrl({
      user_id: userId,
      amount: "1.00"
    })
  }

  if (reqUser.loading) return <Loading />;
  if (reqUser.error) return "Error";

  const { user = {} } = reqUser.data;

  return (
    <View
      user={user}
      isDetailsShown={isDetailsShown}
      setIsDetailsShown={setIsDetailsShown}
      onRechargePoints={onRechargePoints}
    />
  );
}

export default Billing;