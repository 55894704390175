import React from "react";
import { Field } from "formik";
import { Modal } from "seed/helpers";
import BaseField from "components/util/schema_form/fields/BaseField";
import DashboardModalBuilder from "components/util/schema_form/fields/dashboard/ModalBuilder";
import "styles/css/util/schema_form/DashboardBuilder.css"

const getChartName = (type) => {
  if (type == "bar")
    return "Barras";
  if (type == "line")
    return "Lineal";
  if (type == "pie")
    return "Circular";
  if (type == "scatter")
    return "Dispersión";
  if (type == "doughnut")
    return "Dona";

  return "Sin tipo";
}

const DashboardBuilderView = ({
  name,
  label,
  required,
  caption,
  disabled,
  helpMessage,
  values,
  flowId,
  modalProps,
  initialDashboard,
  dashboardButtonText,
  isEditDashboardShown,
  setFieldValue,
  onOpenDashboardModal,
  onCloseDashboardModal
}) => (
  <>
    <div className="d-flex align-items-center">
      <BaseField name={name} label={label} required={required} caption={caption} helpMessage={helpMessage}
        enableComments={false} values={values} setFieldValue={setFieldValue}>

        {initialDashboard && initialDashboard.length > 0 ? (
          <table class="mb-2 mt-3">
            <tr>
              <th style={{ border: "1px solid #ddd" }}>
                <span class="dropdown-header py-2 px-3"><span style={{ color: "#777" }}>Nombre</span></span>
              </th>
              <th style={{ border: "1px solid #ddd" }}>
                <span class="dropdown-header py-2 px-3"><span style={{ color: "#777" }}>Tipo</span></span>
              </th>
            </tr>

            {initialDashboard.map(chart => (
              <tr key={`chart-${chart.id}`}>
                <td class="px-3"
                  style={{ border: "1px solid #ddd", color: "#444", paddingTop: "0.45rem", paddingBottom: "0.45rem" }}>
                  <span class="mr-2 text-truncate d-block" style={{ maxWidth: "18rem", fontSize: "0.95em" }}>
                    {chart?.title ?? "Sin título"}
                  </span>
                </td>
                <td class="px-3"
                  style={{ border: "1px solid #ddd", color: "#444", paddingTop: "0.45rem", paddingBottom: "0.45rem" }}>
                  <span style={{ color: "#aaa", fontSize: "0.9em" }}>
                    {getChartName(chart?.type)}
                  </span>
                </td>
              </tr>
            ))}
          </table>
        ) : null}

        {!disabled ?
          <button type="button" className="btn btn-sm btn-outline-primary mt-1" onClick={onOpenDashboardModal}>
            <i className="fas fa-edit mr-1"></i> {dashboardButtonText}
            <Field type="hidden" name={name} id={name} required={required} />
          </button> : null}
      </BaseField>
    </div>

    {isEditDashboardShown && (
      <Modal
        width="1000"
        height={window.innerHeight - 150}
        animation=""
        onClose={onCloseDashboardModal}
        component={DashboardModalBuilder}
        {...modalProps}
      />
    )}

  </>
);

DashboardBuilderView.propTypes = {};

export default DashboardBuilderView;