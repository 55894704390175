import React from "react";
import { Link } from "react-router-dom";
import ReactFlow, { Controls, Background, ControlButton, ConnectionLineType } from 'reactflow';
import { Loading, StyleTag } from "seed/helpers";
import Node from "components/board/nodes/Node";
import Edge from "components/board/nodes/Edge";
import Note from "components/board/annotations/Note";
import Pipe from "components/board/annotations/Pipe";
import Text from "components/board/annotations/Text";
import NodeAnnotation from "components/board/annotations/Node";
import ActionItem from "components/board/Board.sub.ActionItem.view";
import "styles/css/board/Board.css";
import 'reactflow/dist/style.css';

const nodeTypes = {
  "pipe": Pipe,
  "note": Note,
  "node": Node,
  "node_annotation": NodeAnnotation,
  "text": Text,
}

const edgeTypes = {
  "edge": Edge
}


const BoardView = ({
  nodes,
  edges,
  flowPages,
  activePageId,
  toolboxRef,
  initialViewport,
  boardFitView,
  reactFlowWrapper,
  annotations,
  showAnimations,
  isFlowBeingSaved,
  isAnonymous,
  isDeleteNodesShown,
  isAnimationOptionShown,
  isPaginationShown,
  isPlaceholderShown,
  isControlsShown,
  setReactFlowInstance,
  onDropNode,
  onDragNode,
  onConnectEdge,
  onUpdateEdge,
  onNodesChange,
  onEdgesChange,
  onDragStart,
  onClickAction,
  onClickChangePage,
  onDeleteSelectedNodes,
  onClickUndo,
  onClickRedo,
  onChangeAnimations,
}) => (

  <div id="board" className="w-100 h-100">
    <div ref={reactFlowWrapper} className="flow-wrapper">

      <ReactFlow
        defaultViewport={initialViewport}
        fitView={boardFitView}
        fitViewOptions={{
          padding: 1,
          minZoom: 1.4,
          maxZoom: 1.6
        }}
        minZoom={0.7}
        maxZoom={1.7}
        nodes={nodes}
        edges={edges}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        connectionMode={"loose"}
        connectionLineType={ConnectionLineType.SmoothStep}
        nodesFocusable={false}
        edgesFocusable={false}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnectEdge}
        onEdgeUpdate={onUpdateEdge}
        onInit={setReactFlowInstance}
        onDrop={onDropNode}
        onDragOver={onDragNode}
      >

        {isControlsShown && (
          <div className="mt-3 px-2 mr-2" style={{ "position": "absolute", top: "0", right: "0", zIndex: 5 }}>
            <div className="d-flex">

              {/*<ActionItem
                actions={controlActions}
                actionName="conditional"
                className="btn btn-sm  btn-board btn-lighter-white mr-2 mb-1"
                type="node"
                onDragStart={onDragStart}
                onClickAction={onClickAction}
              />*/}

              <div class="btn-group h-100">

                <ActionItem
                  actions={annotations}
                  actionName={"annotation"}
                  className="btn btn-sm btn-board btn-lighter-white px-3"
                  type={"node"}
                  customTitle={"Agregar anotación"}
                  onDragStart={onDragStart}
                  onClickAction={onClickAction}
                />

              </div>

            </div>
          </div>
        )}

        <div className="mt-1 px-1 mr-2 py-1" style={{ "position": "absolute", top: "50px", right: "0", zIndex: 5, background: "#f8fafe" }}>
          {isDeleteNodesShown && (
            <button className="btn btn-sm btn-block btn-danger mt-2" onClick={onDeleteSelectedNodes}>
              <i className="fa-solid fa-trash mr-2"></i>Eliminar acciones
            </button>
          )}
        </div>

        {
          nodes?.length == 0 && isPlaceholderShown &&
          <div
            className="card px-3 animate__animated animate__fadeIn d-flex flex-column justify-content-center align-items-center"
            style={{
              position: "absolute",
              top: "36%",
              marginTop: "-35px",
              right: "calc(50% - 195px)",
              width: "390px",
              paddingTop: "2.7rem",
              paddingBottom: "2.9rem",
              zIndex: 5,
              background: "none",
              border: 'none',
              boxShadow: "none"
            }}
          >
            <h3 className="text-muted text-center">
              ¿No sabes por dónde empezar?
            </h3>
            <div className="d-flex flex-column" style={{ marginTop: "0.75rem" }}>
              <div className="d-flex">
                <Link to="/flow/suggestions" className="btn btn-outline-primary-white px-3">
                  <i class="tio-magic-wand mr-1" style={{ fontSize: "1.35em" }}></i> Genera ideas
                </Link>
                <button className="btn btn-outline-secondary-white ml-2"
                  style={{ borderColor: "#ccc" }}
                  onClick={() => toolboxRef.current?.onClickFilterTab("initials")}>
                  <i class="fas fa-shapes mr-1" style={{ fontSize: "1.1em" }}></i> Acciones iniciales
                </button>
              </div>
              {/*<a href="#" className="btn btn-sm mb-1"
                style={{ color: "#858a90", marginTop: "0.15rem" }}
                onClick={onClickClosePlaceholder}>
                <i class="fas fa-times mr-1"></i> Cerrar
              </a>*/}
            </div>
          </div>
        }


        <div style={{
          "position": "absolute", bottom: "0px", right: "0", zIndex: 10,
          background: "#f8fafe", width: "60px", height: "20px"
        }}></div>

        {isAnimationOptionShown &&
          <div
            className="mt-1 px-1 mr-2 py-1 animate__animated animate__fadeIn animate__fast"
            style={{
              position: "absolute",
              bottom: isPaginationShown ? "60px" : "15px",
              right: "0px",
              zIndex: 10,
              background: "#f8fafe"
            }}
          >
            {
              showAnimations
                ? <button className="btn btn-sm btn-block btn-lighter-white mt-1"
                  onClick={() => onChangeAnimations(false)}>
                  <i className="fa-solid fa-ban mr-1"></i> Desactivar animaciones
                </button>
                : <button className="btn btn-sm btn-block btn-lighter-white mt-1"
                  onClick={() => onChangeAnimations(true)}>
                  <i className="fa-solid fa-toggle-on mr-1"></i> Activar animaciones
                </button>
            }
          </div>
        }


        {!isAnonymous && isPaginationShown ?
          <div className="mt-1 px-1 mr-2 py-1"
            style={{ "position": "absolute", bottom: "17px", right: "0", zIndex: 10, background: "#f8fafe" }}>
            <div className="dropdown dropup mt-1">

              <div className="d-flex justify-content-between align-items-center dropdown-toggle btn-lighter-white"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                style={{ border: "1px solid #ccc", background: "white", cursor: "pointer", paddingTop: "0.28rem", paddingBottom: "0.28rem" }}>

                <div></div>

                <div className="d-flex align-items-center" style={{ color: "#777", fontSize: "0.9em" }}>
                  <span className="text-truncate text-center" style={{ maxWidth: "15.7rem", minWidth: "11.7rem" }}>
                    {flowPages.find((page) => page.id == activePageId)?.name ?? "Página 1"}
                  </span>
                </div>
                <i class="fa-solid fa-caret-down px-1 mr-2"></i>

              </div>

              <StyleTag content={`
              .page-item .page-item-edit{
                display: none;
                transition: 0.3s;
              }
              .page-item:hover .page-item-edit{
                display: inline-block;
              }
            `} />

              <div class="dropdown-menu">
                {flowPages.map((page) => (
                  <a class="dropdown-item page-item d-flex justify-content-between align-items-center" key={page.id}
                    style={{ cursor: "pointer" }}
                    onClick={() => onClickChangePage(page.id)}>
                    <div class="py-1">{page.name}</div>
                    <Link class="py-1 btn-lighter page-item-edit rounded"
                      to={`/flow/edit_page/${page.id}`}
                      style={{ paddingLeft: "0.7rem", paddingRight: "0.7rem" }}>
                      <i class="fa-solid fa-pen" style={{ fontSize: "0.9em", color: "#999", border: "none" }}></i></Link>
                  </a>
                ))}
                <div class="dropdown-divider"></div>
                <Link class="dropdown-item" to={`/flow/create_page`}>
                  <i class="fa-solid fa-plus mr-2"></i>Crear página
                </Link>

              </div>
            </div>
          </div> : null}

        {/* Extend Controls to implement undo and redo */}
        <Controls showFitView={false} showInteractive={false}
          position="bottom-right" style={{ bottom: isPaginationShown ? "60px" : "10px" }}>
          {isControlsShown && (
            <>
              <ControlButton onClick={onClickUndo}>
                <i class="fa-solid fa-rotate-left"></i>
              </ControlButton>
              <ControlButton onClick={onClickRedo}>
                <i class="fa-solid fa-rotate-right"></i>
              </ControlButton>
            </>
          )}
        </Controls>

        <Background />
      </ReactFlow>

      {isFlowBeingSaved && (
        <div
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "#f8fafe50",
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 100
          }}
        >
          <Loading />
        </div>
      )}

    </div>

  </div>
);

export default BoardView;