import React, { useState, useEffect } from "react";
import View from "components/util/schema_form/fields/smart_code/SmartCode.view";


function SmartCode({ name, label, values, required, helpMessage, setFieldValue }) {

  const [isSmartCode, setIsSmartCode] = useState(false);

  useEffect(() => {
    setIsSmartCode(values.smartCode != null);
  }, [])


  const onEditorDidMount = (editor, monaco) => {

    monaco.languages.register({ id: 'python' });

    monaco.languages.setMonarchTokensProvider('python', {
      tokenizer: {
        root: [
          [/\b(?:print|if|else|for|in|is|global|None|True|False)\b/, 'keyword'],
          [/[\w_]+/, 'identifier'],
          [/[0-9]+\.[0-9]+|[0-9]+/, 'number'],
          [/"([^"\\]|\\.)*$/, 'string.invalid'],
          [/"/, 'string', '@string']
        ],
        string: [
          [/[^\\"]+/, 'string'],
          [/\\./, 'string.escape.invalid'],
          [/"/, { token: 'string.quote', bracket: '@close', next: '@pop' }]
        ],
      },
    });

    monaco.languages.setLanguageConfiguration('python', {
    });

    monaco.languages.registerCompletionItemProvider('python', {
      provideCompletionItems: (model, position) => {
        const suggestions = [
          {
            label: 'print',
            kind: monaco.languages.CompletionItemKind.Function,
            documentation: 'Prints a message to the console',
            insertText: 'print(${1:message})',
            insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          },
        ];

        return { suggestions };
      },
    });
  };

  const onChangeCode = (newCode, event) => {
    setFieldValue(name, newCode)
  }

  return (
    <View
      name={name}
      label={label}
      required={required}
      helpMessage={helpMessage}
      values={values}
      isSmartCode={isSmartCode}
      onChangeCode={onChangeCode}
      onEditorDidMount={onEditorDidMount}
    />
  );
}

export default SmartCode;