import React, { useEffect } from "react";
import { Field } from "formik";
import lodash from "lodash";
import { useQuery } from "seed/gql";
import { Loading } from "seed/helpers";
import VariableSelector from "components/util/schema_form/fields/variable_selector/VariableSelector"
import SelectField from "components/util/schema_form/fields/SelectField"
import BaseField from "components/util/schema_form/fields/BaseField";


const FilterField = ({
	name,
	label,
	value,
	values,
	flowId,
	required,
	disabled,
	caption,  
	helpMessage,
	valueAPlaceholder = "Referencia del proceso",
	valueBPlaceholder = "Selecciona un valor",
	conditionPlaceholder = "Condición",
	valueAAttrs,
	valueBAttrs,
	valueADefault,
	valueBDefault,
	conditionDefault,
	setFieldValue,
	onChange = () => { }
}) => {

	const pageId = sessionStorage.getItem("page_" + flowId)
	const reqNodes = useQuery(`{
		flowNodes {
			outputValues
		}
  }`, `flow.id=${flowId} ${pageId ? "AND page.id=" + pageId : ""}`)

	const reqDataTypes = useQuery(`{
		appDatatypes {
			name
			structure
		}
  }`, '')

	if (valueAAttrs && valueAPlaceholder == "Referencia del proceso")
		valueAPlaceholder = "Selecciona un campo"

	useEffect(() => {
		if (valueADefault && value?.value_a == null) setFieldValue(name + ".value_a", valueADefault)
		if (conditionDefault && value?.condition == null) setFieldValue(name + ".condition", conditionDefault)
		if (valueBDefault && value?.value_b == null) setFieldValue(name + ".value_b", valueBDefault)
	}, []);

	useEffect(() => {
		reqNodes.refetch();
		reqDataTypes.refetch()
	}, [flowId]);

	const onValueAChanged = (val) => {
		setFieldValue(name + ".condition", "")
		setFieldValue(name + ".value_b", "")
		onChange({ ...lodash.get(values, name), ...{ "value_a": val, "condition": "", "value_b": "" } })
	}
	const onConditionChanged = (val) => onChange({ ...lodash.get(values, name), ...{ "condition": val } })
	const onValueBChanged = (val) => onChange({ ...lodash.get(values, name), ...{ "value_b": val } })


	if (reqNodes.loading || reqDataTypes.loading) return <Loading size={27} />
	if (reqNodes.error || reqDataTypes.error) return "Error"

	const nodes = reqNodes.data.flowNodes;
	const datatypes = reqDataTypes.data.appDatatypes;

	// Get Value A Type

	let valueAType = null

	const getAttributeType = (valueAParts, schema, idx) => {
		if (idx < valueAParts.length) {
			for (let field of schema) {
				if (field.name == valueAParts[idx]) {
					let datatype = datatypes.find(opt => opt.name == field.type)
					let schema = datatype == null ? field.structure ?? [] : datatype.structure
					if (valueAParts.length == idx + 1) return datatype == null ? field.type ?? [] : datatype.name
					return getAttributeType(valueAParts, schema, idx + 1)
				}
			}
		} else return null
	}

	if (value?.value_a != null) {
		if (valueAAttrs == null) {
			const valueAParts = value?.value_a.split(".")
			for (let node of nodes)
				for (let outputData of (node.outputValues ?? [])) {
					if (outputData.value == valueAParts[0]) {
						let datatype = datatypes.find(opt => opt.name == outputData.type)
						let schema = datatype == null ? outputData.structure ?? [] : datatype.structure
						if (valueAParts.length == 1) valueAType = datatype == null ? outputData.type ?? [] : datatype.name
						else valueAType = getAttributeType(valueAParts, schema, 1)
					}
				}
		} else
			valueAType = valueAAttrs.find(opt => opt.name == value?.value_a)?.type
	}

	// Customize value B settings and condition options based on type A

	let showB = true;
	let openTextB = true;
	let textTypeB = "text"
	let acceptTypesB = "all"
	let conditionOptions = [{
		"label": "Sea igual a (=)",
		"value": "=",
	},
	{
		"label": "Sea diferente a (!)",
		"value": "<>",
	}]


	if (valueAType == "int" || valueAType == "float") {
		conditionOptions = conditionOptions.concat([{
			"label": "Sea mayor que (>)",
			"value": ">",
		},
		{
			"label": "Sea menor que (<)",
			"value": "<",
		},
		{
			"label": "Sea mayor o igual que (>=)",
			"value": ">=",
		},
		{
			"label": "Sea menor o igual (<=)",
			"value": "<="
		}
		])
		textTypeB = "number"
	}

	if (valueAType == "string") {
		conditionOptions = conditionOptions.concat([{
			"label": "Contenga (~)",
			"value": "~",
		}])
	}

	if (valueAType == "boolean") {
		conditionOptions = [{
			"label": "Se cumpla",
			"value": "is true",
		},
		{
			"label": "No se cumpla",
			"value": "is false",
		}]
		showB = false
	}

	if (valueAType == "file" || valueAType == "object" || datatypes.find(opt => opt.name == valueAType)) {
		openTextB = false
		acceptTypesB = [valueAType]
	}

	const valueA = lodash.get(values, name + ".value_a")
	const valueB = lodash.get(values, name + ".value_b")

	return (
		<div>
			<BaseField
				name={name}
				label={label}
				values={values}
				required={required}
				caption={caption}
				helpMessage={helpMessage}
				enableComments={false}
				setFieldValue={setFieldValue}
			>

				<Field type={"hidden"} name={name} id={name} required={required} />
				<div className="mt-1">
					{valueAAttrs == null ?
						<VariableSelector
							name={name + ".value_a"}
							label={""}
							flowId={flowId}
							values={values}
							value={valueA}
							required={required}
							disabled={disabled}
							setFieldValue={setFieldValue}
							onChange={onValueAChanged}
							placeholder={valueAPlaceholder}
							selectorFilters={[{
								type: "SOURCE",
								value: "variable"
							}]}
						/>
						: <SelectField
							name={name + ".value_a"}
							values={values}
							placeholder={valueAPlaceholder}
							value={valueA}
							required={required}
							options={valueAAttrs.map((valueOption, idx) =>
								({ label: valueOption.label, value: valueOption.name, type: valueOption.type }))}
							optionsIcon={"fas fa-columns"}
							setFieldValue={setFieldValue}
							onChange={onValueAChanged}
						/>}
				</div>

				<div className="mt-1">
					<Field as="select" name={name + ".condition"} class="form-control select-field mt-1"
						required={required} onChange={e => { 
							setFieldValue(name + ".condition", e.target.value); 
							onConditionChanged(e.target.value)}}>
						<option hidden value="">{conditionPlaceholder}</option>
						{
							conditionOptions.map((opt) =>
								<option key={`${opt.label}-${opt.value}`} value={opt.value}>{opt.label ?? opt.value}</option>
							)
						}
					</Field>
				</div>

				{showB ?
					<div className="mt-1">
						{valueBAttrs == null ?
							<VariableSelector
								name={name + ".value_b"}
								label={""}
								flowId={flowId}
								values={values}
								value={valueB}
								required={required}
								disabled={disabled}
								openText={openTextB}
								textTypeB={textTypeB}
								setFieldValue={setFieldValue}
								onChange={onValueBChanged}
								acceptTypes={acceptTypesB}
								placeholder={valueBPlaceholder}
								selectorFilters={[{
									type: "SOURCE",
									value: "variable"
								}]}
							/>
							: <SelectField
								name={name + ".value_b"}
								values={values}
								placeholder={valueBPlaceholder}
								value={valueB}
								required={required}
								options={valueBAttrs}
								setFieldValue={setFieldValue}
								onChange={onValueBChanged}
							/>}
					</div> : null}
			</BaseField>
		</div>
	)
}

export default FilterField;