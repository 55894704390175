const cropSample = async (screenshot, sampleRegion) => {
  
  return new Promise((resolve, reject) => {

    if(!sampleRegion) return resolve(screenshot);

    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.src = screenshot;
    img.crossOrigin = "Anonymous";

    img.onload = () => {

      let x1 = parseFloat(sampleRegion.x) * img.width / 100;
      let x2 = parseFloat(sampleRegion.x + sampleRegion.width) * img.width / 100;
      let y1 = parseFloat(sampleRegion.y) * img.height / 100;
      let y2 = parseFloat(sampleRegion.y + sampleRegion.height) * img.height / 100;

      // Crop sample
      canvas.width = x2 - x1;
      canvas.height = y2 - y1;

      ctx.drawImage(img, x1, y1, x2 - x1, y2 - y1, 0, 0, x2 - x1, y2 - y1);

      resolve(canvas.toDataURL("image/png").replace("data:image/png;base64,", ""));

    }

    img.onerror = (error) => {
      reject(error);
    }

  });

}

export {
  cropSample
}