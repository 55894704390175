import React, { useEffect, useRef } from "react";
import $ from "jquery"
import View from "components/util/schema_form/SchemaForm.view";


function SchemaForm({
  schema = [],
  initialValues = {},
  ignoreSamplePicker = false,
  submitText = "Guardar",
  submitComponent,
  isReadOnly = false,
  isCommentEnabled = false,
  appId,
  actionId,
  flowId,
  nodeId,
  executionId,
  onChange = () => {},
  onSubmit
}) {

  const isDeveloperMode = sessionStorage.getItem("developer_mode") == "true";
  const formRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      let inputs = $(".schema-form .MuiInputBase-root, .schema-form input, .schema-form textarea")
      if (inputs.length > 0) {
        inputs[0].focus();
      }
    }, 750)
  }, []);

  useEffect(() => {
    if(formRef.current) 
      formRef.current.setValues(initialValues);
  }, [initialValues]);

  return (
    <View
      formRef={formRef}
      schema={schema}
      appId={appId}
      actionId={actionId}
      flowId={flowId}
      nodeId={nodeId}
      executionId={executionId}
      initialValues={initialValues}
      ignoreSamplePicker={ignoreSamplePicker}
      submitText={submitText}
      submitComponent={submitComponent}
      isDeveloperMode={isDeveloperMode}
      isReadOnly={isReadOnly}
      isCommentEnabled={isCommentEnabled}
      onChange={onChange}
      onSubmit={onSubmit}
    />
  );
}

export default SchemaForm;