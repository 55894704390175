import React, { useRef, useState } from "react";
import { Formik, Form, Field } from "formik";
import { StyleTag } from "seed/helpers";
import moment from "moment";

const ChatDebug = () => {

  const apiUrl = "https://api.openai.com/v1/chat/completions";
  const apiKey = "sk-proj-KgOhJUMFYx8JcdZJy4OPT3BlbkFJKMnvS5fQBwnsGMq6vME1";  

  const chatRef = useRef();
  const formikRef = useRef();
  const [isWriting, setIsWriting] = useState(false);
  const [shouldScroll, setShouldScroll] = useState(false);
  const [chat, setChat] = useState([]);
  const [basePrompt, setBasePrompt] = useState(`
Eres un asistente especializado en el diálogo socrático, tu tarea es proponer preguntas y respuestas que ayuden a reflexionar sobre 
el siguiente texto. Quiero que tomes en cuenta el contexto que te proporciono 
y vayas pregunta a pregunta, es decir, QUE SOLO INDIQUES UNA SOLA PREGUNTA AL USUARIO, NO MÁS; de manera que la conversación sea fluida.

Necesito que hagas preguntas específicas de la lectura para evaluar si el usuario leyó el texto, una vez que haya suficientes, 
puedes preguntar cosas abiertas que ayuden a reflexionar sobre el contenido.

Si algo que te responde el usuario está fuera de contexto o es ambiguo, puedes pedirle que sea más específico. O pídele que vuelva a leer el texto.
También toma en cuenta el contexto que da la lectura, si algo sale de este, puedes acotarlo.

Evalúa si el usuario responde correctamente o corrígelo si es necesario. Puedes aclimatar la conversación para que no solo sean preguntas y respuestas.
Cuando consideres que la conversación ha llegado a un punto de reflexión suficiente, puedes finalizar la conversación.
  `);

  const fetchChat = async (messages) => {
    return await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${apiKey}`
      },
      body: JSON.stringify({
        model: "gpt-3.5-turbo",
        messages: messages
      })
    });
  }

  const onSetInitialChat = (prompt) => {
    setChat([
      {
        createdAt: new Date(),
        visible: true,
        role: "system",
        content: prompt
      },
      {
        createdAt: new Date(),
        visible: true,
        role: "system",
        content: "¿Podrías proporcionarme el texto que deseas analizar?"
      }
    ]);
  }

  const onSubmit = (values) => {

    const message = values.message;
    const newChat = [...chat, { content: message, role: "user", createdAt: new Date(), visible: true }];
    setChat(newChat);
    setIsWriting(true);

    fetchChat(newChat)
      .then(response => response.json())
      .then(data => {
        setChat(prev => [...prev, { content: data.choices[0].message.content, role: "system", createdAt: new Date(), visible: true }]);
        setIsWriting(false);
      });


    formikRef.current.resetForm();
    setIsWriting(true);

    chatRef.current.scrollTop = chatRef.current.scrollHeight;

  }

  if(shouldScroll) {
    setTimeout(() => {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }, 1000);
    setShouldScroll(false);
  }

  return <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      position: "relative",
    }}
  >
    <div style={{
      position: "absolute",
      width: "calc(100% - 50px)",
      height: "calc(100% - 50px)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      }}
    >
      <div class="d-flex h-100 w-100">

        <StyleTag
          content={`
            @import url("https://fonts.googleapis.com/css?family=Red+Hat+Display:400,500,900&display=swap");
            * {
              font-family: Red hat Display, sans-serif;
            }

            .chat {
              background-color: #F7F7F7;
              overflow-y: auto;
            }

            .chat-container {
              height: 30rem;
              width: 24rem;
              box-shadow: 0 0 8rem 0 rgba(0, 0, 0, 0.1), 0rem 2rem 4rem -3rem rgba(0, 0, 0, 0.5);
            }

            .picture {
              width: 4rem;
              height: 4rem;
              background-size: cover;
              background-position: center;
              border-radius: 50%;
            }

            .chat-dark {
              background-color: #333;
            }

            .message {
              box-sizing: border-box;
              padding: 0.5rem 1rem;
              background: #FFF;
              border-radius: 1.125rem 1.125rem 1.125rem 0;
              width: -webkit-fit-content;
              width: -moz-fit-content;
              width: fit-content;
              max-width: 66%;
              box-shadow: 0 0 2rem rgba(0, 0, 0, 0.075), 0rem 1rem 1rem -1rem rgba(0, 0, 0, 0.1);
            }

            .message.user {
              border-radius: 1.125rem 1.125rem 0 1.125rem;
              background: #333;
              color: white;
            }

            .typing {
              display: inline-block;
              width: 0.8rem;
              height: 0.8rem;
              margin-right: 0rem;
              box-sizing: border-box;
              background: #ccc;
              border-radius: 50%;
            }

            .typing.typing-1 {
              -webkit-animation: typing 2s infinite;
              animation: typing 2s infinite;
            }

            .typing.typing-2 {
              -webkit-animation: typing 2s 250ms infinite;
              animation: typing 2s 250ms infinite;
            }

            .typing.typing-3 {
              -webkit-animation: typing 2s 500ms infinite;
              animation: typing 2s 500ms infinite;
            }

            @-webkit-keyframes typing {
              0%, 75%, 100% {
                transform: translate(0, 0.25rem) scale(0.9);
                opacity: 0.5;
              }
              25% {
                transform: translate(0, -0.25rem) scale(1);
                opacity: 1;
              }
            }
            @keyframes typing {
              0%, 75%, 100% {
                transform: translate(0, 0.25rem) scale(0.9);
                opacity: 0.5;
              }
              25% {
                transform: translate(0, -0.25rem) scale(1);
                opacity: 1;
              }
            }  
          `}
        />

        <div className="card card-body h-100 w-50 mx-2">
          <div className="d-flex flex-column justify-content-between h-100">
            <h3 className="text-dark">Personalizar prompt</h3>
            <textarea 
              className="form-control" 
              defaultValue={basePrompt}
              style={{ 
                flexGrow: 1,
                width: "100%",
                resize: "none"
              }}
            />
            <button 
              type="button"
              className="btn btn-primary mt-3"
              onClick={() => onSetInitialChat(basePrompt)}
            >
              Iniciar
            </button>
          </div>
        </div>

        <div className="card card-body m-0 p-0 d-flex flex-column chat-container h-100 w-100 mx-2">

          <div className="d-flex align-items-center p-2">
            <div class="picture d-flex justify-content-center align-items-center chat-dark text-white">
              <i class="fas fa-user fa-2x"></i>
            </div> 
            <div className="d-flex flex-column justify-content-center mt-2 mx-3">
              <span className="text-dark" style={{ fontWeight: "500" }}>SeedBot</span>
              <span className="mb-2 text-muted">Today at {moment().format("h:mm A")}</span>
            </div>
          </div>

          <div ref={chatRef} className="d-flex flex-fill flex-column mx-0 chat">

            {
              chat.length > 0 && 
              <div className="d-flex justify-content-center my-2">
                <span 
                  className="text-muted rounded-pill px-2 py-1"
                  style={{ fontSize: "0.7rem", backgroundColor: "#FFFFFF" }}
                >
                  {
                    new Date(chat[0].createdAt).toLocaleString()
                  }
                </span>
              </div>
            }

            {
              chat.filter(chat => chat.visible).map((chat, index) => 
                <div 
                  className={`d-flex flex-column mx-3 mb-1 align-items-${chat.role == "system" ? "start" : "end"}`} 
                  key={index}
                >
                  <div className={`message ${chat.role == "system" ? "" : "user"}`}>
                    {chat.content}
                  </div>
                </div>
              )
            }
            {
              isWriting && <>
                <div class="mx-2 my-2 message">
                  <div class="typing typing-1"></div>
                  <div class="typing typing-2"></div>
                  <div class="typing typing-3"></div>
                </div>
              </>
            }
          </div>

          <div className="d-flex justify-content-between py-2">
          <Formik
              innerRef={formikRef}
              initialValues={{
                message: "",
              }}
              onSubmit={onSubmit}>
              {({ values, setFieldValue, submitForm }) =>
                <Form className="w-100">

                <div className="d-flex align-items-center justify-content-between px-2">

                  <Field 
                    name="message" 
                    className="form-control rounded"
                    placeholder="Type your message here!" 
                    type="text" 
                    onChange={(e) => {
                      if(e.keyCode === 13) submitForm();
                      setFieldValue("message", e.target.value);
                    }}
                  />
                  
                  <i class="fas fa-paper-plane mx-2 fa-2x" onClick={submitForm} style={{ cursor: "pointer" }}></i>
                </div>

              </Form> }
            </Formik>
          </div>

        </div>

      </div>
    </div>
  </div>;

}

export default ChatDebug;