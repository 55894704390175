import React from "react";
import BaseField from "components/util/schema_form/fields/BaseField";
import Editor from "components/util/schema_form/fields/rich_text/Editor";
import VariableSelectorModal from "components/util/schema_form/fields/rich_text/VariableSelectorModal";
import { Modal } from "seed/helpers";
import "styles/css/util/schema_form/RichText.css"


const RichTextView = ({
  editorRef,
  nodes,
  collections,
  datatypes,
  name,
  label,
  required,
  caption,
  helpMessage,
  placeholder,
  singleLine,
  rows,
  flowId,
  nodeId,
  showStyles,
  values,
  initialValue,
  isVariableModalOpen,
  setFieldValue,
  onChangeValue,
  onClickAddReference,
  onCloseAddReference,
  onVariableSelected,
}) => (
  <BaseField name={name} label={label} required={required} caption={caption} helpMessage={helpMessage}
    enableComments={false} values={values} setFieldValue={setFieldValue}>
    <Editor
      editorRef={editorRef}
      nodes={nodes}
      collections={collections}
      datatypes={datatypes}
      singleLine={singleLine}
      rows={rows}
      placeholder={placeholder}
      showStyles={showStyles}
      initialValue={initialValue}
      onChangeValue={onChangeValue}
      onClickAddReference={onClickAddReference} />

    {isVariableModalOpen ?
      <Modal
        width="550"
        component={() => (
          <VariableSelectorModal
            flowId={flowId}
            nodeId={nodeId}
            onVariableSelected={onVariableSelected}
          />
        )}
        onClose={onCloseAddReference}
      />
      : null
    }
  </BaseField>
)

export default RichTextView;