import React from "react";
import moment from "moment";
import { executionStatus } from "components/executions/util/executions";
import { parseInput, parseOutput } from "components/executions/ExecutionDetails.lib.parse";
import { getObjectComponent } from "components/util/table";

const ExecutionSteps = ({
  idx,
  details,
  isDeveloperMode
}) => (<>

  {
    !details?.executionSteps && 
      <div class="d-flex justify-content-center align-items-center">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
  }

  {
    details?.executionSteps?.length === 0 && 
      <div class="d-flex justify-content-center align-items-center h-100 card">
        <div class="text-center mb-4">
          <i class="fas fa-clipboard-list fa-3x text-secondary" />
          <div class="h5 text-secondary">Sin resultados</div>
        </div>
      </div> 
  }

  {
    details?.executionSteps?.map((step, j) => (
      <li key={j} className="d-flex flex-column mb-3">
        <div 
          className="card card-body animate__animated animate__fadeIn animate__faster"
          style={{
            backgroundColor: "white",
          }}
        >
          <div className="d-flex justify-content-between align-items-center py-1 w-100">
            <div className="d-flex justify-content-between align-items-center w-100">
              <div className="d-flex align-items-start">
                <i class={`${executionStatus[details.status]?.icon} ${executionStatus[step.status]?.color} mt-2`} />
                <div className="mx-3">
                  <span className="h6">
                    Paso {j + 1}. 
                    <i className={`fas fa ml-2 mr-1 ${step?.flowNode?.action?.app?.uiSettings?.icon}`} />
                    {(step?.flowNode?.action?.uiSettings.general ?? {})?.title}                        
                  </span>
                  <div className="h6 font-weight-light">
                    {moment(step?.startDate).format(
                      (new Date(step?.startDate))
                        .toDateString() == (new Date()).toDateString() ? "HH:mm" : "DD.MMM")}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="mx-3">

            {
              step.status === "FAILED" && isDeveloperMode &&(
                <div
                  className="d-flex align-items-center text-danger mx-3"
                  style={{
                    cursor: "pointer",
                    userSelect: "none"
                  }}
                >
                  <i class="fas fa-exclamation-triangle mx-2" />
                  {details?.exception?.details}
                </div>
              )
            }

            {
              function(){

                const randomKey = Math.random().toString(36).substring(7);
                const appDatatypes = [
                  ...(step?.flowNode?.action?.app?.datatypes ?? []),
                  {
                    name: "file",
                    label: "Archivo",
                    structure: [
                      {
                        "name": "id",
                        "type": "string",
                        "label": "ID"
                      },
                      {
                        "name": "url",
                        "type": "string",
                        "label": "URL"
                      },
                      {
                        "name": "hash",
                        "type": "string",
                        "label": "Hash"
                      },
                      {
                        "name": "name",
                        "type": "string",
                        "label": "Nombre"
                      },
                      {
                        "name": "size",
                        "type": "number",
                        "label": "Tamaño"
                      },
                      {
                        "name": "created_at",
                        "type": "datetime",
                        "label": "Fecha de creación"
                      }  
                    ]
                  }
                ];

                const inputValues = step?.flowNode?.inputValues ?? [];
                const outputValues = step?.flowNode?.outputValues ?? [];
                const result = step?.result ?? {};
                const input = result.input ?? {};
                const output = result.output ?? result;

                const finalInputResult = parseInput(inputValues, appDatatypes, input);
                const finalOutputResult = parseOutput(outputValues, appDatatypes, output);

                return <>
                  
                  <div class="mt-2 mb-1">
                    <span className="dropdown-header px-0 py-2 w-auto d-inline-block">Entradas</span>
                    <button
                      type="button"
                      class="btn btn-sm btn-link font-weight-normal d-inline-block"
                      data-toggle="collapse"
                      data-target={"#exec_" + idx + "step_inputs_" + j + randomKey}
                      aria-expanded="false"
                      aria-controls={"exec_" + idx + "step_inputs_" + j + randomKey}
                    >
                      Ver detalles
                    </button>
                  </div>
                  <div class="collapse" id={"exec_" + idx + "step_inputs_" + j + randomKey}>
                    {getObjectComponent(finalInputResult)}
                  </div>
                  
                  <div class="dropdown-header p-0 pb-2 mt-2 mb-1">Resultados</div>
                  {getObjectComponent(finalOutputResult)}

                </>;

              }()
            }

          </div>

        </div>
      </li>
    ))
  }

</>);


export default ExecutionSteps;