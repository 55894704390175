import React, { useState } from "react";
import BaseField from "components/util/schema_form/fields/BaseField";
import { formatNumber, getDate, getObjectComponent } from "components/collections/util/format";

const SelectorField = ({
  name,
	label,
	value,
	values,
	flowId,
  multiple,
	required,
	disabled,
	caption,  
	helpMessage,
  setFieldValue,
  dataValues,
  dataStructure,
  dataMultiple,
}) => {

  const [selection, setSelection] = useState([]);

  return (
    <BaseField
      name={name}
      label={label}
      required={required}
      values={values}
      caption={caption}
      helpMessage={helpMessage}
      enableComments={false}
      setFieldValue={setFieldValue}
    >
      <div 
        className="pt-1 mt-2 mb-1 scrollbar-narrow" 
        style={{ 
          overflowX: "auto" 
        }}
      >
        <table className="table table-sm table-bordered table-hover m-0">
          <thead className='table-bordered bg-light'>
            <tr>
              <th scope="col" className="table-column m-0 h5 text-dark font-weight-bold py-2">
                Seleccionar
              </th>
              <th scope="col" className="table-column m-0 h5 text-dark font-weight-bold py-2 text-center">
                #
              </th>
              {
                dataStructure.map((col, i) => (
                  <th
                    key={i}
                    scope="col"
                    className='table-column m-0 h5 text-dark font-weight-bold py-2'
                  >
                    {col.label}
                  </th>
                ))
              }
            </tr>
          </thead>
          <tbody className='table-bordered'>
            {
              dataValues.map((item, i) => (
                <tr key={`selector-${i}`} className='table-row'>
                  <td>
                    <div 
                      className="d-flex justify-content-center align-items-center mt-2 px-2"
                      style={{ minWidth: "30px" }}
                    >
                      <input
                        type="checkbox"
                        checked={selection.includes(i)}
                        value={i}
                        onChange={(e) => {

                          let newSelection = [];
                          if(dataMultiple) {
                            newSelection = e.target.checked 
                              ? [...selection, i] 
                              : selection.filter(s => s != i);
                          } 
                          else {
                            newSelection = [i];
                          }

                          setSelection(newSelection);
                          setFieldValue(name, newSelection.map(s => dataValues[s]));

                        }}
                      />
                    </div>
                  </td>
                  <td className="folio-column">
                    <div 
                      className="d-flex justify-content-center align-items-center mt-2 px-2"
                      style={{ minWidth: "30px" }}
                    >
                      {i + 1}
                    </div>
                  </td>
                  {
                    dataStructure?.map((col, j) => (
                      <td key={col.name} className='m-0 py-0 px-2 border' style={{ minWidth: "100px", height: "100%" }}>
                        <div className="d-flex align-items-center h-100 my-auto" style={{ minHeight: "2.5rem" }}>
                          {
                            function () {

                              if(col.type == "file")
                                return item[col.name] 
                                  ? <a 
                                      href={item[col.name].url} 
                                      target="_blank" 
                                      rel="noreferrer" 
                                      className="text-truncate" 
                                      style={{ cursor: "pointer" }}
                                    >
                                      {`Ver archivo`}
                                    </a>
                                  : "Sin archivo";

                              if(col.type == "collection")
                                return <button
                                  type="button"
                                  className="btn btn-lighter px-2 py-1"
                                  style={{ border: "1px solid #e0e0e0", cursor: "pointer" }}
                                  >
                                    {col.structure?.initials}-${formatNumber(item[col.name])}
                                  </button>

                              if(col.type == "object")
                                return getObjectComponent(item[col.name]);

                              if(col.type == "int" || col.type == "float")
                                return formatNumber(item[col.name]);

                              if(col.type == "date")
                                return getDate(item[col.name]);

                              if(col.type == "boolean")
                                return item[col.name] ? "Si" : "No";

                              return item[col.name]?.toString();

                            }()
                          }
                        </div>
                      </td>
                    ))
                  }
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    </BaseField>
  );

}

export default SelectorField;