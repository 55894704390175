import React, { useState } from "react";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";
import { usePost } from "seed/api";
import { SAVE_FLOW, SAVE_FLOW_PAGE } from "seed/gql/queries";
import { useSave, useQuery } from "seed/gql";
import { Loading } from "seed/helpers";
import View from "components/start/flows/FormFlow.view";

function FormSaveFlow({ match, 
  onSave = (flowId) => window.location.replace(`/app/flow/${flowId}`) }) {

  const userId = sessionStorage.getItem("id");
  const [createdFlowId, setCreatedFlowId] = useState(null);
  const history = useHistory();

  const { folderId = null } = match?.params ?? {};
  const initialValues = {
    folder: folderId,
    show_animations: true,
    show_pagination: false,
    is_published: false,
  }

  const [callCreateToken] = usePost("/flows/create_flow_token", {
    onCompleted: () => {
      onSave(createdFlowId)
    }
  });

  const [callSavePage] = useSave(SAVE_FLOW_PAGE, {
    onCompleted: () => {
      callCreateToken({
        flow_id: createdFlowId
      });
    }, onError: () => {
      swal("Error al crear el proceso", "Por favor cierre sesión y vuelva a entrar", "error")
    }
  })

  const [callSave] = useSave(SAVE_FLOW, {
    onCompleted: (data) => {
      const flow = data.saveFlow.flow;
      setCreatedFlowId(flow.id)
      callSavePage({
        name: "Página 1",
        isDefault: true,
        flow: flow.id
      })
    }
  });

  const reqFolders = useQuery(`{
    folders {
      name
    }
  }`, `user.id = ${userId}`, { orderBy: "name" });

  const onSubmit = (values) => {

    const newValues = {
      name: values.name,
      description: values.description,
      settings: { 
        show_animations: values.show_animations, 
        show_pagination: values.show_pagination,
        is_published: values.is_published,
      },
      usageStats: {},
      isSubroutine: false,
      isTemplate: false,
      user: parseInt(userId),
    }

    if(values.folder != "0")
      newValues.folder = parseInt(values.folder);

    callSave(newValues);
  }

  if (reqFolders.loading) return <Loading />;
  if (reqFolders.error) return "Error";

  const { folders = [] } = reqFolders.data;

  return (
    <View
      folders={folders}
      initialValues={initialValues}
      history={history}
      onSubmit={onSubmit}
    />
  );
}

export default FormSaveFlow;