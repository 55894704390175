import React from 'react';
import moment from 'moment';

const formatNumber = (number) => {
  return number?.toString()?.padStart(3, '0');
}

const toCamelCase = (str) => {
  const removeAccents = (s) => s.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  let words = removeAccents(str).split(/[\s_-]+/);
  return words.map((word, index) => {
      if(index === 0)
          return word.toLowerCase();
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }).join("");
}

const getDateFormats = () => {

  const separators = ["/", "-", ".", ""]
  const dateFormats = [
    "YYYY-MM-DD",
    "YYYY-MM-DDTHH:mm:ss.SSSZ",
    "YYYY-MM-DDTHH:mm:ss.SSS",
    "YYYY-MM-DDTHH:mm:ss",
    "YYYY-MM-DD HH:mm:ss",
    "YYYY-MM-DD HH:mm",
    "YYYY-MM-DDTHH:mm:ss.SSSSSS",
    "YYYY-MM-DDTHH:mm:ss.SSSSSSZ",
    "DD-MM-YYYY",
    "DD-MM-YYYYTHH:mm:ss.SSSZ",
    "DD-MM-YYYYTHH:mm:ss.SSS",
    "DD-MM-YYYYTHH:mm:ss",
    "DD-MM-YYYY HH:mm:ss",
    "DD-MM-YYYY HH:mm",
    "MM-DD-YYYY",
    "MM-DD-YYYYTHH:mm:ss.SSSZ",
    "MM-DD-YYYYTHH:mm:ss.SSS",
    "MM-DD-YYYYTHH:mm:ss",
    "MM-DD-YYYY HH:mm:ss",
    "MM-DD-YYYY HH:mm",
  ];
  const formats = [];
  dateFormats.forEach((format) => {
    separators.forEach((separator) => {
      formats.push(format.replace(/-/g, separator));
    });
  });

  return formats;
    
}

const getDate = (value, format = "DD/MM/YYYY") => {

  if(value instanceof Date)
    return moment.utc(value).format(format);

  const formats = getDateFormats();
  let date = null;
  
  for(let i = 0; i < formats.length; i++) {
    date = moment.utc(value, formats[i], true);
    if(date.isValid()) {
      break;
    }
  }
  
  if(date && !date.isValid()) date = null;
  if(format == "iso") return date?.toISOString() ?? "";
  return date?.format(format) ?? "";
  
}

const getObjectComponent = (value) => {

  // if value is a list, return a list with the list data
  if(value instanceof Array) {
    return <ul className="list-group">
      {
        value.map((item, i) => (
          <li key={i} className="list-group-item">
            {getObjectComponent(item)}
          </li>
        ))
      }
    </ul>
  }
  
  // if value is an object, return a table with the object data

  if(value instanceof Object) {
    return <table className="table-sm w-100">
      <thead>
        <tr>
          <th style={{ minWidth: "80px" }}>Llave</th>
          <th style={{ minWidth: "80px" }}>Valor</th>
        </tr>
      </thead>
      <tbody>
        {
          Object?.keys(value).map((key, i) => (
            <tr key={key}>
              <td>{key}</td>
              <td>{getObjectComponent(value[key])}</td>
            </tr>
          ))
        }
      </tbody>
    </table>
  }

  if(value == null || value == undefined) return "";
  if(value == "") return "";

  // if value is boolean or matches with True or False, return a boolean Si/No
  if(value == true || value == false 
    || ["true", "false"].includes(value?.toString().toLowerCase()))
      return value == true || value?.toString().toLowerCase() == "true" ? "Si" : "No";

  // if value is a date, return the date in format DD/MM/YYYY
  if(value instanceof Date) return getDate(value, "DD/MM/YYYY");

  // if value is a number, return the number
  if(!isNaN(value)) return value;

  // if value is a string, return the string
  return value;
  
}

export {
  formatNumber,
  toCamelCase,
  getDateFormats,
  getDate,
  getObjectComponent
}