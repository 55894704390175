import React from "react";
import swal from "sweetalert";
import { useQuery, useDelete, useDetail } from "seed/gql";
import { DELETE_FLOW } from "seed/gql/queries";
import { Loading } from "seed/helpers"
import View from "components/tmisc/settings/flows/FlowList.view";
import { usePost } from "seed/api";

function FlowList({ user, selectedFolder }) {

  let flowFilter = "";
  const [callDelete, reqDelete] = useDelete(DELETE_FLOW, {
    onCompleted: () => {
      reqFlows.refetch();
    }
  })

  if (selectedFolder) {
    flowFilter = `user.id=${user.id} AND folder.id=${selectedFolder}`;
  } else {
    flowFilter = `user.id=${user.id} OR (participants.user.id=${user.id} AND participants.isAccepted=True)`
  }

  const reqFlows = useQuery(`{
    flows {
      name
      createdAt
      isTemplate
      templateSettings
      user {
        firstName
        lastName
        license { name }
      }
      folder { name }
      participants {
        user { email }
      }
    }
  }`, flowFilter, { orderBy: "name" })

  const [callClone, reqClone] = usePost("/flows/clone", {
    onCompleted: () => reqFlows.refetch()
  });

  const reqFolder = useDetail(`{
    folder { name }
  }`, selectedFolder);

  const onClickDelete = (flowId) => {
    swal({
      title: "Eliminar proceso",
      icon: "warning",
      text: "¿Estás seguro que desea eliminar el proceso?",
      buttons: {
        confirm: {
          text: "Aceptar",
          className: "swal-button swal-button--cancel btn-success",
        },
        cancel: "Cancelar",
      },
    }).then((response) => {
      if (response) {
        callDelete({ id: flowId });
      }
    })
  }

  const onClickClone = (flowId) =>
    callClone({ flow_id: flowId });

  if (reqFlows.loading || reqFolder.loading)
    return <PlaceholderView />;
  const { flows = [] } = reqFlows.data;
  const folder = reqFolder.data?.folder ?? null;

  return (
    <View
      user={user}
      flows={flows}
      folder={folder}
      onClickDelete={onClickDelete}
      onClickClone={onClickClone}
    />
  );
}

const PlaceholderView = () =>
  <div class="card">
    <div class="card-body" style={{ padding: "7rem 0" }}>
      <Loading />
    </div>
  </div>

export default FlowList;