import React from "react";
import { useQuery, useDetail } from "seed/gql";
import { Loading } from "seed/helpers";
import * as parser from "components/studio/Controller.lib.parser";
import View from "components/tmisc/flow_preview/FlowPreview.view";


function FlowPreview({ match }) {

  const flowId = match?.params?.flowId;
  const filterPage = `flow.id=${flowId} AND page.isDefault=True`;

  const reqFlow = useDetail(`{
    flow {
      name
      description
      token
      settings
      executions {
        status
        startDate
        isRead
      }
      user {
        email
      }
    }
  }`, flowId);

  const reqNodes = useQuery(`{
    flowNodes {
      createdAt
      inputValues
      outputValues
      uiSettings
      isEnabled
      action { name }
      isVerified
      isIterable
      iterableField
      flow { name }
    }
  }`, filterPage);

  const reqEdges = useQuery(`{
    flowEdges {
      type
      createdAt
      sourceName
      targetName
      source { }
      target { }
      flow { name }
    }
  }`, filterPage);

  const reqAnnotations = useQuery(`{
    flowAnnotations {
      createdAt
      inputValues
      uiSettings      
      action { name }
      flow { name }
    }
  }`, filterPage);


  if (reqFlow.loading || reqNodes.loading || reqEdges.loading || reqAnnotations.loading)
    return <PlaceholderView><Loading /></PlaceholderView>

  if (reqFlow.error || reqNodes.error || reqEdges.error || reqAnnotations.error)
    return <PlaceholderView></PlaceholderView>

  if (!flowId)
    return <PlaceholderView></PlaceholderView>

  const { flow = {} } = reqFlow.data;
  const { flowNodes = [] } = reqNodes.data;
  const { flowEdges = [] } = reqEdges.data;
  const { flowAnnotations = [] } = reqAnnotations.data;

  const nodes = parser.parseNodesDatabaseToUi(flowNodes);
  const edges = parser.parseEdgesDatabaseToUi(flowEdges);
  const annotations = parser.parseAnnotationsDatabaseToUi(flowAnnotations);


  return (
    <View
      flowId={flowId}
      nodes={nodes}
      edges={edges}
      annotations={annotations}
    />
  );
}


const PlaceholderView = ({ children }) => {
  return (
    <div className="bg-white" style={{ width: "100vw", height: "100vh" }}>
      {children}
    </div>
  )
}



export default FlowPreview;