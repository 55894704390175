import React from "react";
import ReactCardFlip from "react-card-flip";
import SchemaForm from "components/util/schema_form/SchemaForm";
import EditSubmitComponent from "components/board/node_form/NodeForm.sub.EditSubmit.view";
import NodeFormIdeas from "components/board/node_form/NodeFormIdeas";
import { HelpComponent } from "components/util/schema_form/util/help_util";
import "styles/css/studio/NodeForm.css";


const getActionTitle = (action) => {
  let nodeIcon = action.app.uiSettings.icon
  let nodeTitle = action.uiSettings.form?.title ?? action.uiSettings?.general?.title;
  let nodeSubtitle = action.uiSettings.form?.subtitle ?? action.uiSettings?.general?.subtitle;
  let helpMessage = action.uiSettings?.form?.caption ?? action.uiSettings?.general?.caption ?? "";
  let nodeApp = action.app.uiSettings.label;
  return (
    <div>
      {nodeTitle}
      <small className="ml-1" style={{ color: "#aaacb0" }}>
        {nodeSubtitle ? <span className="mr-1">{nodeSubtitle}</span> : null}
        {helpMessage != "" ? 
          <label className="mr-1" id="help-message">
            <HelpComponent helpMessage={helpMessage} />
          </label>: null}
      </small>
      <small className="ml-2 px-2 py-1" id="app-detail">
        <i id="app_detail_icon" className={nodeIcon + " mr-1"}></i> {nodeApp}
      </small>
    </div>
  );
}

const NodeFormView = ({
  node = {},
  action,
  flowId,
  activePageId,
  initialData,
  isEditing = false,
  isAnnotation = false,
  ignoreSamplePicker,
  isDetailsShown = false,
  onDeleteNode = () => { },
  onOpenDetail = () => { },
  onCloseDetail = () => { },
  onSubmit,
}) => (
  <>
    <ReactCardFlip isFlipped={isDetailsShown}>

      <div className="card" id="node-form">

        <div className="card-header">
          <div className="w-100">
            <div className="d-flex justify-content-between align-items-center">
              <h3 className="node-form-title mb-0">
                <span>{!isEditing ? "Crear acción" : "Editar acción"}</span>
                {getActionTitle(action)}
              </h3>

              {/* Flip icon to show detils about the action */}
              <div className="btn btn-outline-primary-white mr-4 d-flex align-items-center" id="flip-icon"
                onClick={() => isDetailsShown ? onCloseDetail() : onOpenDetail()}>
                <i class="fas fa-shapes"></i>
                <span className="ml-1">Ejemplos</span>
              </div>
            </div>
          </div>
        </div>

        <div className="card-body pt-5">
          <div id="node-form-schema">
            <SchemaForm
              schema={action.uiSettings?.form?.fields ?? []}
              appId={action.app.id}
              actionId={action.id}
              flowId={flowId}
              nodeId={node?.id}
              initialValues={initialData}
              ignoreSamplePicker={ignoreSamplePicker}
              submitComponent={isEditing ? () => <EditSubmitComponent onDeleteNode={onDeleteNode} /> : null}
              submitText={!isEditing ? "Crear acción" : null}
              onSubmit={onSubmit}
            />
          </div>

        </div>

      </div>

      <div className="card bg-primary" id="node-form-details">
        <NodeFormIdeas
          flowId={flowId}
          action={action}
          activePageId={activePageId}
          isDetailsShown={isDetailsShown}
          onCloseDetail={onCloseDetail}
          onOpenDetail={onOpenDetail}
        />
      </div>
    </ReactCardFlip>

  </>
)

export default NodeFormView;