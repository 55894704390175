import React, { useState, useEffect } from "react";
import { useQuery } from "seed/gql";
import View from "components/tmisc/templates/Categories.view";


function Categories({
  Category,
  manualCategories,
  initialActiveCategory,
  categoriesQuery = "",
  style = {},
  arrowsStyle = {},
  arrowContainerStyle = {},
  scrollAmount = 400
}) {

  const [activeCategory, setActiveCategory] = useState(initialActiveCategory);

  const reqCategories = useQuery(`{
    templateCategories {
      name
      description
      uiSettings
    }
  }`, categoriesQuery)

  useEffect(() => {
    reqCategories.refetch();
  }, [categoriesQuery])

  useEffect(() => {
    setActiveCategory(initialActiveCategory)
  }, [initialActiveCategory])

  const onSelectCategory = (categoryId) => {
    setActiveCategory(categoryId)
  }

  const isTemplateCategoriesLoading = reqCategories.loading;
  let categories = [];
  if (!manualCategories) {
    const { templateCategories = [] } = reqCategories.data;
    categories = [...templateCategories]
  } else {
    categories = [...manualCategories]
  }
  if (categories != null && Array.isArray(categories))
    categories.sort((a, b) => a?.uiSettings?.relevance - b?.uiSettings?.relevance)

  return (
    <View
      Category={Category}
      categories={categories}
      activeCategory={activeCategory}
      scrollAmount={scrollAmount}
      style={style}
      arrowsStyle={arrowsStyle}
      arrowContainerStyle={arrowContainerStyle}
      isTemplateCategoriesLoading={isTemplateCategoriesLoading}
      onSelectCategory={onSelectCategory}
    />
  );
}

Categories.propTypes = {};

export default Categories;