const parseData = (values, execModeDefaults, collections) => {

  if(!values.reference) return {};

  const defaults = execModeDefaults[values.reference];
  if(!defaults) return {};

  const formattedCollections = collections.map(c => ({ name: `@${c.name}`, id: c.id }));
  const tables = values.tables.map(t => {
    const table = formattedCollections.find(c => c.name == t.name);
    if(!table) return {};
    return {
      id: table.id,
      name: table.name,
      ...t
    };
  });

  return {
    reference: {
      ...defaults.reference,
      title: values.reference_title
    },
    left_panel: values.left_panel,
    right_panel: values.right_panel,
    title: values.title,
    forms: values.forms,
    actions: values.actions,
    tables: tables,
    is_manual_left_panel: values.is_manual_left_panel,
    is_manual_right_panel: values.is_manual_right_panel,
    is_manual_title: values.is_manual_title,
    is_manual_actions: values.is_manual_actions,
    is_manual_forms: values.is_manual_forms,
    is_manual_tables: values.is_manual_tables,
  };

}

const getInitialValues = (settings) => {
  return {
    reference: settings?.reference?.id,
    title: settings?.title,
    left_panel: settings.left_panel,
    right_panel: settings.right_panel,
    actions: settings?.actions??[],
    forms: settings?.forms??[],
    tables: settings?.tables??[],
    is_manual_left_panel: settings.is_manual_left_panel??true,
    is_manual_right_panel: settings.is_manual_right_panel??true,
    is_manual_title: settings?.is_manual_title??true,
    is_manual_reference: settings?.is_manual_reference??true,
    is_manual_actions: settings?.is_manual_actions??true,
    is_manual_forms: settings?.is_manual_forms??true,
    is_manual_tables: settings?.is_manual_tables??true
  };
}

const getPanelOptions = (values, execModeDefaults) => {

  const options = [];
  const defaults = execModeDefaults[values.reference];
  if(!defaults) return [];

  options.push({ label: "Formulario", value: "FORM" });
  options.push({ label: "Tabla", value: "TABLE" });
  options.push({ label: "Actividad", value: "ACTIVITY_CHART" });
  options.push({ label: "Vacío", value: "EMPTY" });
    
  return options;

}

const getColumnOptions = (valueTable, collections) => {

  const formattedCollections = collections.map(c => ({ name: `@${c.name}`, id: c.id, columns: c.schema?.structure??[] }));
  const table = formattedCollections.find(c => c.name == valueTable);
  if(!table) return [];

  return table.columns.filter(column => !column.internal).map(c => ({ label: c.label, value: c.name, name: c.name }));

}

export { parseData, getInitialValues, getPanelOptions, getColumnOptions };