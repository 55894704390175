import React from "react";
import RegionSelect from "react-region-select";
import SampleParameters from "components/tmisc/apps/rpa/designer/SampleParameters";
import { Modal } from "seed/helpers";

const SamplePickerView = ({
  input,
  category,
  parameters,
  screenshot,
  regions = [],
  loading,
  pickerResponse,
  resultScreenshot,
  setRegions = () => { },
  setInput = () => { },
  onChangeValue = () => { },
  onResetZoom = () => { },
  onSelect = () => { },
  showResponseModal, 
  setShowResponseModal,
  onFinish
}) => (
  <div 
    className="h-100 w-100 justify-content-between px-3 py-2 pb-5" 
    style={{ 
      backgroundColor: "white",
      overflowY: "auto",
      position: "relative"
    }}
  >

    <div className="d-flex align-items-center justify-content-between w-100 h3">
      <div>
        {
          function() {

            let text = "Haz zoom hasta obtener la región de la imagen que desea ";

            if(category == "CLICK") text += "hacer clic";
            if(category == "DOUBLE_CLICK") text += "hacer doble clic";
            if(category == "DRAG") text += "arrastrar";
            if(category == "INPUT") text += "escribir";
            if(category == "GET_ELEMENTS") text += "identificar";

            return text;
          }()
        }
      </div>
      <div>
        <button
          className="btn btn-accent mt-2"
          onClick={onResetZoom}
        >
          Restablecer
        </button>
      </div>
    </div>
    
    <RegionSelect
      maxRegions={1}
      regions={regions}
      regionStyle={{ border: "4px dashed #5dc1b9" }}
      constraint
      onChange={setRegions}
    >
      <img 
        src={screenshot}
        style={{
          cursor: regions.length > 0 && regions[regions.length - 1].isChanging ?
            "nwse-resize" : "pointer",
          width: "1140px",
        }}
        alt="Capture" 
      />
    </RegionSelect>

    {
      (category == "CLICK" || category == "DOUBLE_CLICK" ) &&
        <>
          <div class="d-flex mt-2">
            <label class="input-label">
              Tipo de click
            </label>
          </div>
          <select 
            class="form-control" 
            value={input?.click_type}
            onChange={(event) => 
              setInput({ ...input, click_type: event.target.value })
            }
          >
            <option value="LEFT">Click izquierdo</option>
            <option value="RIGHT">Click derecho</option>
          </select>
        </>
    }

    {
      (category == "INPUT") &&
        <>

          {
            (input?.input_type == "TEXT" || input?.input_type == "SELECT") &&
            <div class="form-group mt-2">
              <div class="d-flex">
                <label class="input-label" for="text">
                  Texto a ingresar
                </label>
              </div>
              <input 
                type="text" 
                class="form-control"
                value={input?.input_value}
                onChange={(e) => setInput({ ...input, input_value: e.target.value })}
              />
            </div>
          }

          {
            (input?.input_type == "DATE") &&
              <div class="form-group mt-2">
                <div class="d-flex">
                  <label class="input-label" for="text">
                    Fecha
                  </label>
                </div>
                <input 
                  type="date" 
                  class="form-control"
                  value={input?.input_value}
                  onChange={(e) => setInput({ ...input, input_value: e.target.value })}
                />
              </div>
          }

          <div class="d-flex mt-1">
            <label class="input-label">
              Tipo de valor
            </label>
          </div>
          <select 
            class="form-control" 
            value={input?.input_type}
            onChange={(event) => 
              setInput({ ...input, input_type: event.target.value })
            }
          >
            <option value="TEXT">Texto</option>
            <option value="SELECT">Selección</option>
            <option value="DATE">Fecha</option>
          </select>
        </>
    }

    {
      !loading && 
        <SampleParameters
          data={parameters}
          selectedImage={screenshot}
          onSubmit={onSelect}
          onChangeValue={onChangeValue}
          regions={regions}
        />
    }

    {
      loading && 
        <div className="d-flex justify-content-center align-items-center">
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
    }

    {
      showResponseModal && 
        <Modal
          className="card card-body"
          onClose={() => setShowResponseModal(false)}
          width={700}
          height={500}
        >
          <div>

            <div className="d-flex justify-content-center">
              <img 
                style={{
                  maxHeight: "300px",
                  objectFit: "contain"
                }}
                className="w-100"
                src={resultScreenshot} 
                alt="Result" 
              />
            </div>

            <div className="d-flex justify-content-start mt-2">
              
              {
                pickerResponse.status == "FAILED" && <span className="text-danger">
                  Ha ocurrido un error en la selección
                </span>
              }

              {
                pickerResponse.status == "OK" && <div className="d-flex w-100 align-items-center justify-content-end">
                  <button 
                    className="btn btn-sm btn-outline-danger"
                    onClick={() => setShowResponseModal(false)}
                  >
                    Cancelar
                  </button>
                </div>
              }

            </div>

            <div className="d-flex justify-content-center mt-2">

              {
                pickerResponse.status == "FAILED" &&
                <button
                  className="btn btn-primary w-100"
                  onClick={() => setShowResponseModal(false)}
                >
                  Volver a seleccionar
                </button>
              }

              {
                pickerResponse.status == "OK" && <>
                  <button 
                    className="btn btn-primary w-100"
                    onClick={onFinish}
                  >
                    Confirmar
                  </button>
                </>
              }

            </div>

          </div>
        </Modal>
    }

  </div>
);

export default SamplePickerView;