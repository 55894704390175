import tippy from 'tippy.js'
import { ReactRenderer } from '@tiptap/react'
import { mergeAttributes } from '@tiptap/react'



const getMention = (text, nodes, collections, datatypes) => {

    let parts = text.split(".")
    let name = parts[0]

    let reference = null;
    let referenceType = ""
    
    if (name.startsWith("#")) {
        referenceType = "node"
        for (let node of nodes) {
            let outputValues = node.outputValues ?? []
            for (let outputValue of outputValues) {
                if (name == outputValue.value)
                    reference = node
            }
        }
    }
    if (name.startsWith("@")) {
        referenceType = "collection"
        for (let collection of collections)
            if ("@" + collection.name == name)
                reference = collection
    }

    if (!reference) return null

    let label = ""
    if (referenceType == "node") {
        const uiSettings = reference.action.uiSettings ?? {};
        label += `(#${reference.idx}) `
        label += uiSettings.general?.title
        if (parts.length > 1) {
            const attr = parts[1]
            let outputValues = reference.outputValues ?? []
            for (let outputValue of outputValues) {
                if (name == outputValue.value) {
                    const outputStructure = outputValue.structure
                    for (let structure of outputStructure) {
                        if (structure.name == attr) {
                            label += "."
                            label += structure.label ?? structure.name
                        }
                    }
                }
            }
        }
    }
    if (referenceType == "collection") {
        label += `(Tabla) `
        label += reference.name
        if (parts.length > 1) {
            const attr = parts[1]
            const outputStructure = reference.schema.structure
            for (let structure of outputStructure) {
                if (structure.name == attr) {
                    label += "."
                    label += structure.label ?? structure.name
                }
            }
        }
    }

    return {
        id: text,
        label: label,
    }
}

const getInitialContent = (nodes, collections, datatypes, initialValue) => {

    const content = [];
    const mentionRegex = /(@|#)((\w|(\.))+)/g;
    let lastIndex = 0;

    initialValue?.replace(mentionRegex, (match, p1, p2, offset) => {

        const text = p1 + p2;
        const mention = getMention(text, nodes, collections, datatypes)
        if (mention)
            content.push({
                type: 'mention',
                attrs: mention,
            });

        lastIndex = offset + match.length;
    });



    if (lastIndex < initialValue?.length) {
        content.push({
            type: 'text',
            text: initialValue.substring(lastIndex),
        });
    }

    const initialContent = {
        type: 'doc',
        content: [{
            type: 'paragraph',
            content: content,
        }]
    };

    return initialContent;

}

const renderHtml = ({ options, node }) => {

    const isVariable = node.attrs.id.includes("#");
    const isCollection = node.attrs.id.includes("@");
    const isAtribute = !isVariable && !isCollection;

    if (isVariable || isCollection) {
        return [
            "span",
            mergeAttributes({ type: isVariable ? "variable" : "collection" }, options.HTMLAttributes),
            `${node.attrs.label ?? node.attrs.id}`
        ]
    } else if (isAtribute) {
        return [
            "span",
            mergeAttributes({ type: "attribute" }, options.HTMLAttributes),
            `${node.attrs.label ?? node.attrs.id}`
        ]
    }
}

const render = (ListComponent, extraProps) => {

    let reactRenderer;
    let popup;

    return {

        onStart: (props) => {

            if (!props.clientRect) return

            reactRenderer = new ReactRenderer(ListComponent, {
                props: { ...props, ...extraProps },
                editor: props.editor,

            })

            popup = tippy('body', {
                getReferenceClientRect: props.clientRect,
                appendTo: () => document.body,
                content: reactRenderer.element,
                showOnCreate: true,
                interactive: true,
                trigger: 'manual',
                placement: 'bottom-start',
            })

        },

        onUpdate(props) {
            reactRenderer.updateProps(props)

            if (!props.clientRect) {
                return
            }

            popup[0].setProps({
                getReferenceClientRect: props.clientRect,
            })
        },

        onKeyDown(props) {
            if (props.event.key === 'Escape') {
                popup[0].hide()

                return true
            }

            return reactRenderer.ref?.onKeyDown(props)
        },

        onExit() {
            popup[0].destroy()
            reactRenderer.destroy()
        },

    }
}

export { getMention, getInitialContent, renderHtml, render }