import React, { useState } from 'react';
import { ColumnTypes } from "components/collections/util/datatypes";
import { filterBySearch } from "components/collections/util/filters";
import { formatNumber, getDate } from "components/collections/util/format";

const SearchCollection = ({
  isCollectionDetail,
  column,
  collectionColumns,
  value,
  setShowSearch,
  setIsCollectionDetail,
  onChange,
  onPreviewFile,
}) => {
  
  const [search, setSearch] = useState("");

  return <div className={`card card-body ${!isCollectionDetail ? 'h-100' : ''}`}>
    <div>

      {
        isCollectionDetail &&
          <div className="d-flex justify-content-between align-items-center w-100 py-2">
            <div className="d-flex align-items-center">
              <h5 className="m-0">Editar {column?.label}</h5>
            </div>
          </div>
      }

      {
        !isCollectionDetail &&
          <div className="d-flex justify-content-between align-items-center w-100 py-2">
            <div className="d-flex align-items-center">
              <h5 className="m-0">Seleccionar {column?.label}</h5>
            </div>
            <div
              className="bg-white px-2 py-1 rounded"
              style={{ border: "1px solid #dadde0" }}
            >
              <i className="fas fa-search mr-3"></i>
              <input
                type="text"
                className="border-0"
                placeholder="Buscar"
                onClick={(e) => e.stopPropagation()}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>
      }

      <div className="table-hover table-sm m-0 p-0" style={{ overflowY: "auto" }}>
        <table 
          className="table table-sm table-bordered table-hover m-0"
          style={{
            minWidth: "100%",
            overflowY: "auto",
            maxHeight: "calc(100vh - 200px)",
          }}
        >
          {
            function () {

              const collectionColumn = collectionColumns.find(col => col.id == column?.structure?.collection_id);
              let data = collectionColumn?.collectionDataes ?? [];
              data = data.sort((a, b) => a.folio - b.folio);

              return <>
                <thead className='table-bordered'>
                  <tr>
                    <th scope="col"></th>
                    {
                      collectionColumn?.schema?.structure?.map((col, i) => (
                        col.internal ? null :
                        <th
                          key={i}
                          scope="col"
                          className='table-column m-0 h5 text-dark font-weight-bold py-2'
                        >
                          {col.label}
                        </th>
                      ))
                    }
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody className='table-bordered'>
                  {
                    filterBySearch(data, search).map((item, i) => (
                      ((isCollectionDetail && item.id == value?.id) || !isCollectionDetail) ?
                      <tr key={item?.folio}>
                        <td className="folio-column">
                          <div 
                            className="d-flex justify-content-center align-items-center mt-2 px-2"
                            style={{ minWidth: "65px" }}
                          >
                            <span>
                              {`${collectionColumn?.initials}-${formatNumber(item?.folio)}`}
                            </span>
                          </div>
                        </td>
                        {
                          collectionColumn?.schema?.structure?.map((col, j) => (
                            col.internal ? null :
                            <td key={col.name}>
                              <div className="d-flex align-items-center w-100 h-100">
                                {
                                  function () {

                                    if (col.type === ColumnTypes.FILE)
                                      return <div
                                        className="d-flex justify-content-between align-items-center w-100 h-100 px-2"
                                      >
                                        <span
                                          className="d-flex align-items-center btn-lighter px-2 py-1"
                                          style={{ border: "1px solid #e0e0e0", cursor: "pointer" }}
                                          onClick={() => onPreviewFile(item.data[col.name])}
                                        >
                                          <i class="far fa-file-alt mr-2 ml-2" style={{ fontSize: "1.2em" }}></i>
                                          <span className="text-truncate">
                                            {(item.data[col.name]?.name && item.data[col.name]?.name != "" ? 
                                              item.data[col.name].name : "Archivo").substring(33)}</span>
                                        </span>
                                      </div>

                                    if (col.type === ColumnTypes.COLLECTION) {
                                      const auxColumn = collectionColumns.find(auxCol => auxCol?.id == col?.structure?.collection_id);
                                      const auxValue = item.data[col.name];
                                      if (!auxValue) return "";
                                      if (!auxColumn) return "";
                                      return `${auxColumn?.initials}-${formatNumber(auxValue.folio)}`;
                                    }

                                    if (col.type === ColumnTypes.BOOLEAN)
                                      return <input
                                        type="checkbox"
                                        className="form-check-input d-flex flex-wrap m-0 w-50 h-50"
                                        defaultChecked={item.data[col.name]}
                                        disabled
                                      />

                                    if (col.type === ColumnTypes.DATE)
                                      return getDate(item.data[col.name]);

                                    const v = item.data[col.name]?.toString();
                                    if (v?.length > 30)
                                      return v.substring(0, 30) + "...";

                                    return v;

                                  }()
                                }
                              </div>
                            </td>
                          ))
                        }
                        <td style={{ background: "#fff" }}>
                          <button
                            style={{ width: "120px" }}
                            className={`btn ${item.id == value?.id ? 'btn-primary' : 'btn-outline-primary'}  m-0 p-1 px-3`}
                            onClick={(e) => {

                              if(isCollectionDetail) 
                                return setIsCollectionDetail(false);

                              e.stopPropagation();
                              e.preventDefault();

                              onChange({ id: item.id, folio: item.folio });
                              setShowSearch(false);

                            }}
                          >
                            { 
                              isCollectionDetail 
                                ? <>Editar</> 
                                : <>{ item.id == value?.id ? "Seleccionado" : "Seleccionar" }</>
                            }
                          </button>
                        </td>
                      </tr> : null
                    ))
                  }
                </tbody>
              </>

            }()
          }
        </table>
      </div>
    </div>
  </div>
}

export default SearchCollection;