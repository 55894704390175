import { isSingleEvent, isMultiEvent } from "components/tmisc/apps/rpa/generator/util/events";

const FLOW_QUESTIONS = {
  "mouse_00": {
    "name": "IS_SINGLE",
    "next": [
      {
        "id": "mouse_drag_01",
        "condition": (_, captures, index) =>
          isSingleEvent(captures[index]) && captures[index]?.event == "DRAG",
      },
      {
        "id": "mouse_01",
        "condition": (_, captures, index) =>
          isSingleEvent(captures[index]),
      },      
      {
        "id": "mouse_multiple_00",
        "condition": (_, captures, index) =>
          isMultiEvent(captures[index]),
      }
    ]
  },
  "mouse_01": {
    "name": "ELEMENT_REGION",
    "label": () =>
      "¿Podrías seleccionar el elemento que presionaste?",
    "component": "PICKER",
    "definition": () => "CLICK",
  },
  "mouse_drag_01": {
    "name": "MULTIPLE_ELEMENT_DRAG",
    "component": "CHECKBOX",
    "label": () =>
      "¿Seleccionaste varios elementos?",
    "next": [
      {
        "id": "mouse_drag_02",
        "condition": (answers, captures, index) =>
          answers["MULTIPLE_ELEMENT_DRAG"]
      },
      {
        "id": "mouse_drag_03",
        "condition": (answers, captures, index) =>
          answers["MULTIPLE_ELEMENT_DRAG"] == false
      }
    ],
  },
  "mouse_drag_02": {
    "name": "ELEMENT_REGION_DRAG",
    "label": () =>
      "¿Podrías seleccionar un elemento?",
    "component": "PICKER",
    "definition": () => "DRAG",
  },
  "mouse_drag_03": {
    "name": "ELEMENT_REGION_DRAG",
    "label": () =>
      "¿Podrías seleccionar el elemento?",
    "component": "PICKER",
    "definition": () => "DRAG",
  },

};

export default FLOW_QUESTIONS;