import React from "react";
import { Modal, Loading } from "seed/helpers";
import FileViewer from "components/collections/helpers/FileViewer";
import HeaderCell from "components/collections/helpers/HeaderCell";
import Cell from "components/collections/helpers/Cell";
import Selection from "components/collections/helpers/Selection";

const TableDatagrid = ({
  height = "auto",
  columns = [],
  rows = [],
  filteredData = [],
  filteredColumns = [],
  collections = [],
  collectionColumns = [],
  isReadOnly = false,
  isLoading = false,
  isFilePreviewShown = false,
  showOptions = true,
  showFilters = true,
  showForm,
  tableRef,
  pageSize,
  pageNum,
  filters,
  selectedCell,
  selectedFile,
  setSelectedCell,
  setSelectedFile,
  setFormValue,
  setIsFilterModalShown,
  setIsFilePreviewShown,
  setIsOptionModalShown,
  setIsFormModalShown,
  setFilterDefault,
  onChangeColumn = () => { },
  onDeleteColumn = () => { },
  onAddRow = () => { },
  onDeleteRow = () => { },
  onInsertRow = () => { },
  onChangeRow = () => { },
  onDownloadZip = () => { },
  onClickCell = () => { },
  onShowEditModal = () => { },
  folioComponent
}) => {

  return (
    <div
      id="collection-table"
      ref={tableRef}
      className="scrollbar-narrow"
      style={{ 
        minHeight: "200px",
        height: height
       }}
    >

      {isLoading ? (
        <div
          id="loading-content"
          className="d-flex justify-content-center align-items-center position-absolute"
          colSpan={columns.length + 2}
        >
          <Loading />
        </div>
      ) : null}

      <Selection>
        <table className="table-hover table-sm m-0 p-0">

          <thead className="table-bordered">
            <tr>
              <th scope="col"></th>
              {
                columns.map((column, index) => (
                  !column.internal &&
                  <HeaderCell
                    key={`header-${column.name}-${index}`}
                    collections={collections}
                    column={column}
                    filteredColumns={filteredColumns}
                    isReadOnly={isReadOnly}
                    isActive={selectedCell && selectedCell.column === index && selectedCell.row === -1}
                    showOptions={showOptions}
                    showFilters={showFilters}
                    onChange={(value) => onChangeColumn(index, value)}
                    onDelete={() => onDeleteColumn(index)}
                    onDownloadZip={onDownloadZip}
                    onShowOptions={() => setIsOptionModalShown(true)}
                    onShowEditModal={onShowEditModal}
                    setSelectedCell={setSelectedCell}
                    setIsFilterModalShown={setIsFilterModalShown}
                    setFilterDefault={setFilterDefault}
                  />
                ))
              }

              {
                !isReadOnly &&
                <th
                  scope="col"
                  className="text-center text-primary add-column"
                >
                  <button
                    class="btn p-0 m-0 w-100 text-center"
                    type="button"
                    onClick={onShowEditModal}
                  >
                    <div className="h4 p-0 d-flex justify-content-center align-items-center my-1 py-0 text-center w-100">
                      <span>+</span> {columns.length == 0 && <span className="h6 my-0 ml-1">Agregar columna</span>}
                    </div>
                  </button>
                </th>
              }

            </tr>
          </thead>

          <tbody className="table-bordered">
            {
              filteredData.map((row, i) => (
                <tr key={i}>
                  <td className="folio-column">
                    <div className="d-flex justify-content-center align-items-center mt-2 px-2">
                      <span>
                        {
                          folioComponent
                            ? folioComponent(row, i)
                            : <> {pageSize * (pageNum - 1) + i + 1}</>
                        }
                      </span>
                    </div>
                  </td>
                  {
                    columns.filter(column => !column.internal).map((column, j) => (
                      <Cell
                        key={`${i}-${j}`}
                        column={column}
                        collectionColumns={collectionColumns}
                        value={(row?.data ?? {})[column.name]}
                        isReadOnly={isReadOnly}
                        isActive={selectedCell && selectedCell.row === i && selectedCell.column === j}
                        showOptions={showOptions}
                        showFilters={showFilters}
                        onTypeEnter={() => {
                          if (i === rows.length - 1) onAddRow();
                          else onClickCell(row, i + 1, j);
                        }}
                        onPreviewFile={setSelectedFile}
                        onClick={() => onClickCell(row, i, j)}
                        onChange={(value) => onChangeRow(i, column.name, value)}
                        onInsert={() => onInsertRow(i)}
                        onDelete={() => onDeleteRow(i)}
                        onShowOptions={() => setIsOptionModalShown(true)}
                        setSelectedCell={setSelectedCell}
                        setIsFilterModalShown={setIsFilterModalShown}
                        setFilterDefault={setFilterDefault}
                      />
                    ))
                  }
                  {
                    showForm && <td
                      style={{ cursor: "pointer" }}
                      className="edit-row-button px-2"
                      onClick={() => {
                        setFormValue(row);
                        setIsFormModalShown(true);
                      }}
                    >
                      Editar
                    </td>
                  }
                  {
                    !isReadOnly && <td
                      className="delete-row-button"
                      onClick={() => onDeleteRow(i)}
                    >
                      <div className="h5 d-flex justify-content-center align-items-center my-1 py-0 px-3">
                        <span>-</span>
                      </div>
                    </td>
                  }

                </tr>
              ))
            }
          </tbody>

          {
            !isReadOnly && !filters.length &&
            <tfoot className="table-bordered"
              style={{ borderTop: filteredData.length == 0 ? "1.5rem solid #F8FAFE" : "none" }}>
              <tr>
                <td
                  colSpan={columns.length + 2}
                  style={{ cursor: "pointer" }}
                  onClick={onAddRow}
                >
                  <div
                    className="h5 d-flex justify-content-center align-items-center my-0 py-2"
                    style={{
                      fontSize: "0.97em",
                      borderTop: filteredData.length == 0 ? "1px solid #d5dae0" : "none"
                    }}
                  >
                    <span className="mr-1">+</span> Agregar fila
                  </div>
                </td>
              </tr>
            </tfoot>
          }

        </table>
      </Selection>

      {
        isFilePreviewShown && <Modal
          width="300"
          height="250"
          onClose={() => {
            setSelectedFile(null);
            setIsFilePreviewShown(false);
          }}
          component={() => <FileViewer file={selectedFile} />}
        />
      }

    </div>
  );

}

export default TableDatagrid;