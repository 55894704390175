import React from "react";

const icons = {
  "annotation": "fas fa-vector-square",
  "conditional": "fas fa-diagram-project",
  "note": "fas fa-sticky-note",
  "pipe": "fas fa-object-group",
  "node_annotation": "fas fa-code-branch",
  "text": "fas fa-font"
}

const ActionItem = ({
  style = {},
  customTitle = null,
  type,
  actions,
  actionName,
  iconClassName,
  className,
  onDragStart,
  onClickAction
}) => {

  const action = actions.find((action) => action.name == actionName);
  if (!action) return <></>;

  return (
    <a className={className + " d-flex align-items-center"} draggable style={{ cursor: "pointer", ...style }}
      onDragStart={(event) => onDragStart(event, type, action.id)}
      onClick={() => onClickAction(action.id, type)}>
      {<i className={(icons[actionName] ?? "") + " " + (iconClassName ?? "")}
        style={{ fontSize: "1.1rem", marginRight: "0.7rem" }}></i>}
      <label style={{ marginBottom: "0.07rem" }}>
        {customTitle ? customTitle
          : action.uiSettings?.general?.title ?? action.uiSettings?.node?.title}
        {actionName == "node_annotation" ? <small className="ml-1">(Visual)</small> : null}
      </label>
    </a>
  )

}


export default ActionItem;