import React, { useEffect, useState, useRef } from "react";
import { usePost } from "seed/api";
import View from "components/tmisc/apps/rpa/designer/RPADesigner.view";
import { 
  postMessage, 
  addListener, 
  closeWindow, 
  minimizeAllWindows,
  showBlackScreen,
  hideBlackScreen,
  restoreWindow,
  topMostWindow,
  minimizeWindow,
  resizeWindow,
  locateWindow
} from "components/util/win";

const initialDataParameters = {
  text: "",
  region: { x1: 0, x2: 100, y1: 0, y2: 100, },
  phases: ["crop_by_region", "match_by_sample", "filter_by_text", "filter_by_image_similarity"],
  process_different_aspects: false,
  match_by_sample_threshold: 0.7,
  filter_by_text_threshold: 0.7,
  expand_matches_by_text_delta: 5,
  app: "other",
  selection_type: "button_icon",
  scroll: false,
  scroll_direction: "DOWN",
  scroll_height: 150,
  stop_on_first: true
};

function RPADesigner() {

  const urlParams = new URLSearchParams(window.location.search);
	const token = urlParams.get("token");
	const userId = urlParams.get("user_id");
  const flowId = urlParams.get("flow_id");
  const pageId = urlParams.get("page_id");

  const [showPicker, setShowPicker] = useState(false);
  const [refresh, setRefresh] = useState(Math.random());
  const [loading, setLoading] = useState(false);
  const listenerMountedRef = useRef(false);
  const screenshotRef = useRef(null);
  const categoryRef = useRef(null);
  const sampleRef = useRef(null);
  const inputRef = useRef(null);
  const parametersRef = useRef(initialDataParameters);
  const responseRef = useRef([]);

  const [callSaveFlow, reqSaveFlow] = usePost('/flows/generate_assisted_flow', {
    onCompleted: (data) => {
      hideBlackScreen();
      closeWindow();
    }
  });

  const [callSaveScreenshot, reqSaveScreenshot] = usePost('/flows/save_b64_image', {
    onCompleted: (data) => {
      screenshotRef.current = data.image;
      setShowPicker(true);
      minimizeWindow();
    },
  });

  const [callSaveSample, reqSaveSample] = usePost('/flows/save_b64_image', {
    onCompleted: async (data) => {

      sampleRef.current = data.image;
      setShowPicker(false);
      setLoading(false);

      let params = {
        ...parametersRef.current,
        ...inputRef.current
      }

      hideBlackScreen();

      postMessage({
        "type": "execute_command",
        "action": categoryRef.current,
        "sample": data.image.url,
        "params": params
      });

    }
  });

  const onExecuteAction = (action) => {
    
    minimizeWindow();
    hideBlackScreen();

    setTimeout(() => {
      categoryRef.current = action;
      postMessage({ "type": "sample_picker", "action": action });
    }, 200);

  }

  const onSamplePicked = async (data) => {

    topMostWindow();
    if(!data) return restoreWindow();
    
    parametersRef.current = data.parameters;
    inputRef.current = data.input;

    callSaveSample({ image: data.sample });
    setLoading(true);

  }

  const onRemoveResponse = (index) => {
    responseRef.current.splice(index, 1);
    setRefresh(Math.random());
  }

  const onFinish = () =>
    callSaveFlow({
      responses: responseRef.current,
      flow_id: flowId,
      page_id: pageId
    });

  useEffect(() => {

    if(!listenerMountedRef.current) {

      listenerMountedRef.current = true;
      
      addListener((data) => {

        setShowPicker(false);

        if(data?.type == "sample_picker") {
          screenshotRef.current = { url: "data:image/png;base64," + data.screenshot };
          callSaveScreenshot({ image: data.screenshot });
        }
        else if(data?.type == "execute_command") {

          let result = {};
          try{ 
            result = JSON.parse(data.result); 
          } catch(e) { 
            result = data.result; 
          }
          
          if(data?.status == "OK") {

            responseRef.current.push({
              screenshot: screenshotRef.current,
              sample: sampleRef.current,
              parameters: parametersRef.current,
              input: inputRef.current,
              category: categoryRef.current,
              result: result
            });

            screenshotRef.current = null;
            categoryRef.current = null;
            sampleRef.current = null;
            inputRef.current = null;
            parametersRef.current = initialDataParameters;

          }

          showBlackScreen();

          setTimeout(() => {
            restoreWindow();
            topMostWindow();
            setRefresh(Math.random());
          }, 100);

        }

      });

    }

  }, []);

  useEffect(() => {

    minimizeAllWindows();
    showBlackScreen();
    locateWindow((sessionStorage.getItem("screenWidth") || window.screen.availWidth) - 300, 100);
    
    setTimeout(() => {
      restoreWindow();
      topMostWindow();
      resizeWindow(300, 800);
    }, 100);
  
  }, []);

  useEffect(() => {
    if(token) { //Preload data from URL
      sessionStorage.setItem("token", token);
      sessionStorage.setItem("id", userId);
      localStorage.setItem("token", token);
      localStorage.setItem("id", userId);
    }
  }, [token, userId]);

  if(reqSaveSample.loading || reqSaveScreenshot.loading || loading) return <></>

  return <View
    category={categoryRef.current}
    showPicker={showPicker}
    input={inputRef.current}
    screenshot={screenshotRef.current}
    parameters={parametersRef.current}
    responses={responseRef.current}
    onSamplePicked={onSamplePicked}
    onExecuteAction={onExecuteAction}
    onRemoveResponse={onRemoveResponse}
    onFinish={onFinish}
  />;

}

export default RPADesigner;