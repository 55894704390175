import React from "react";
import { useGet, usePost } from "seed/api";
import { Loading } from "seed/helpers";
import View from "components/board/node_form/NodeFormIdeas.view";

function NodeFormIdeas({ flowId, action, activePageId, isDetailsShown, onCloseDetail, onOpenDetail }) {
  
  const params = {
    action_id: action.id
  };
  
  const reqSampleFlows = useGet('/flows/get_sample_templates_include_action', params);
  const [callInsertUseCase, reqInsertUseCase] = usePost('/flows/insert_sample_flow_template', {
    onCompleted: () => {
      window.location.replace(`/app/flow/${flowId}/flow`)
    },
    onError: (error) => {
      console.log(error);
    }
  })

  const onCloneCase = (event, sampleFlowId) => {
    event.preventDefault();
    event.stopPropagation();

    callInsertUseCase({
      flow_id: flowId,
      flow_page_id: activePageId,
      sample_flow_id: sampleFlowId
    })
  }

  if (reqSampleFlows.loading) return <Loading />;
  if (reqSampleFlows.error) return "Error";

  const sampleFlows = reqSampleFlows.data?.flows ?? [];
  
  return (
    <View
      action={action}
      flowId={flowId}
      sampleFlows={sampleFlows}
      isDetailsShown={isDetailsShown}
      onCloneCase={onCloneCase}
      onCloseDetail={onCloseDetail}
      onOpenDetail={onOpenDetail}
    />
  );
}

export default NodeFormIdeas;