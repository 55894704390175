import React from "react";
import View from "components/tmisc/settings/plans/Plans.view";

function Plans() {

  const professionalFeatures = [
    "100 créditos mensuales"
  ]

  const premiumFeatures = [
    "200 créditos mensuales"
  ]

  const enterpriseFeatures = [
    "+1000 créditos mensuales",
    "Brandeo personalizado"
  ]


  return (
    <View
      professionalFeatures={professionalFeatures}
      premiumFeatures={premiumFeatures}
      enterpriseFeatures={enterpriseFeatures}
    />
  );
}

export default Plans;