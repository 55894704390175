import React from "react";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";
import { useDetail, useQuery } from "seed/gql";
import { useDelete } from "seed/gql";
import { DELETE_FLOW } from "seed/gql/queries";
import { usePost } from "seed/api";
import { Loading } from "seed/helpers";
import View from "components/start/flows/FormFlow.view";

function FormSetFlow({ match,
  onEdit = () => setTimeout(() => { window.location.reload() }, 50),
  onDelete = () => window.location.replace("/app") }) {

  const { flowId = null } = match.params;
  const userId = sessionStorage.getItem("id");
  const history = useHistory();

  const [callUpdate] = usePost("/flows/edit_flow", {
    onCompleted: () => {
      history.goBack();
      onEdit(flowId);
    }
  });

  const [callDelete] = useDelete(DELETE_FLOW, {
    onCompleted: () =>
      onDelete(flowId)
  })

  const onSubmit = (values) => {

    const newValues = {
      flow_id: flowId,
      name: values.name,
      description: values.description ?? "",
      settings: {
        ...flow.settings,
        show_animations: values.show_animations,
        show_pagination: values.show_pagination,
        is_published: values.is_published,
      }
    }

    if (values.folder != "0")
      newValues.folder = parseInt(values.folder);

    callUpdate(newValues);

  }

  const onClickDelete = () => {
    swal({
      title: "Eliminar proceso",
      icon: "warning",
      text: "¿Estás seguro que desea eliminar el proceso?",
      buttons: {
        confirm: {
          text: "Aceptar",
          className: "swal-button swal-button--cancel btn-success",
        },
        cancel: "Cancelar",
      },
    }).then((response) => {
      if (response) {
        callDelete({ id: flow.id });
      }
    })
  }

  const reqFolders = useQuery(`{
    folders {
      name
    }
  }`, `user.id = ${userId}`);

  const reqFlow = useDetail(`{
    flow { 
      name
      settings
      token
      description
      folder {
        id
      }
    }
  }`, flowId, { orderBy: "name" });

  if (reqFlow.loading || reqFolders.loading) return <Loading />;
  if (reqFlow.error || reqFolders.error) return "Error";

  const { flow = {} } = reqFlow.data;
  const { folders = {} } = reqFolders.data;

  const initialValues = {
    name: flow.name,
    folder: flow.folder?.id ?? "null",
    description: flow.description,
    show_animations: flow.settings.show_animations,
    show_pagination: flow.settings.show_pagination,
    is_published: flow.settings.is_published,
  }

  return (
    <View
      initialValues={initialValues}
      flow={flow}
      folders={folders}
      isEditing={true}
      history={history}
      onSubmit={onSubmit}
      onClickDelete={onClickDelete}
    />
  );
}

export default FormSetFlow;