import React from "react";

const IdeaDetails = ({
  suggestion = {},
  showGuideButton = false,
  showRegenerateButton = false,
  onCreateGuide = () => { },
  onRegenerate = () => { },
}) => {
  return (
    <div className="animated animate__fadeIn animate__faster">
      {
        !suggestion?.response && 
          <div className="d-flex w-100 justify-content-center mt-7">
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
      }

      {
        suggestion?.response && <>
      
          <h3>
            Vista previa <br/>
            <small>{suggestion.response.title}</small>
          </h3>

          <div 
            style={{
              overflowY: "auto"
            }}
          >
            <span class="d-flex justify-content-between dropdown-header px-0">Resumen</span>
            { suggestion.response.explanation }
            <div className="d-flex flex-column my-2">
              <span class="d-flex justify-content-between dropdown-header px-0">Pasos</span>
              <table className="table table-bordered table-hover table-sm m-0 p-0">
                <tr>
                  <th>Paso</th>
                  <th>Descripción</th>
                </tr>
            
              {
                function () {
                  try {
                    return <>
                      {suggestion.extraData.actions.map((a, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{a.description}</td>
                        </tr>
                      ))}
                    </>
                  }
                  catch (e) {
                    return <></>
                  }
                }()
              }
              </table>
            </div>
          </div>

          {
            showRegenerateButton &&
              <button
                type="button"
                className="btn btn-sm btn-link text-left p-0 m-0 mb-2"
                onClick={() => onRegenerate()}
              >
                Volver a generar guía
              </button>
          }

          <div className="text-center text-muted h6 mt-3">
            <div className="py-2 px-2" style={{ background: "#fafafa", border: "1px solid #e0e0e0" }}>
              El generador de ideas puede contener aproximaciones y/o errores, recomendamos utilizarlo como referencia
            </div>
          </div>

          {
            showGuideButton &&
              <div className="d-flex flex-column w-100">
                <hr className="w-100 mb-4"/>
                <button
                  type="button"
                  className="btn btn-accent w-100"
                  onClick={() => onCreateGuide(suggestion)}
                >
                  <i className="fa fa-book"></i> Crear guía
                </button>
              </div>
          }

        </>
      }
    </div>
  );
}

export default IdeaDetails;