import React from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import { ScriptTag, StyleTag, Route } from "seed/helpers";

import StyleColor from "components/util/helpers/StyleColor"
import Start from "components/start/Start";
import Studio from "components/studio/Studio";
import Settings from "components/tmisc/settings/Settings";
import AuthHandler from "components/util/schema_form/fields/account_picker/AuthHandler";

const HomeView = ({ user }) =>
  <BrowserRouter basename="/app">

    <StyleColor user={user} />
    <StyleTag content={`
      #background-hero svg {
        height: 50vh !important;
      }
    `} />

    <div>
      <main id="content" role="main">
        {!user.is_anonymous ?
          <Switch>
            <Route path="/start" component={Start} />
            <Route path="/flow/:flowId" component={Studio} />
            <Route path="/settings/auth" component={AuthHandler} />
            <Route path="/settings" component={Settings} />
            <Route path="/" component={Start} />
          </Switch> :
          <Switch>
            <Route path="/flow/:flowId" component={({ match }) => <Studio match={match} isAnonymous={true} />} />
            <Route path="/settings/auth" component={AuthHandler} />
            <Route path="/" component={() => window.location.href = "/logout"} />
          </Switch>
        }
      </main>

      <ScriptTag content={`
         // Builder toggle invoker
        $('.js-navbar-vertical-aside-toggle-invoker').click(function () {
          $('.js-navbar-vertical-aside-toggle-invoker i').tooltip('hide');
        });

        // Initialization of navbar vertical navigation
        var sidebar = $('.js-navbar-vertical-aside').hsSideNav();

        // Initialization of tooltip in navbar vertical menu
        $('.js-nav-tooltip-link').tooltip({ boundary: 'window' })

        $(".js-nav-tooltip-link").on("show.bs.tooltip", function(e) {
          if (!$("body").hasClass("navbar-vertical-aside-mini-mode")) {
            return false;
          }
        });

        // Initialization of unfold
        $('.js-hs-unfold-invoker').each(function () {
          var unfold = new HSUnfold($(this)).init();
        });

        // Initialization of form search
        $('.js-form-search').each(function () {
          new HSFormSearch($(this)).init()
        });
    `} />
    </div>
  </BrowserRouter>;

export default HomeView;