import React from 'react';
import { downloadFile } from 'components/util/file_util';

const FileViewer = ({ file }) => {
  return (<div className='card'>
    <div class="card-header">
      <h3 class="mb-0">{"Descargar archivo"}</h3>
    </div>
    <div class="card-body py-4">
      <button
        type="button"
        className='btn btn-primary w-100'
        onClick={() => {
          downloadFile(file.url, null, file.name);
        }}
      >
        Descargar
      </button>
    </div>
  </div>)

}

export default FileViewer;