import React, {forwardRef, useImperativeHandle, useState, useEffect } from "react";
import { useGetCall, usePost } from "seed/api";
import { useDetail } from "seed/gql";
import { Loading } from "seed/helpers";
import { execModeTutorialSteps } from "components/util/tour/TourData";
import View from "components/presentation/ExecMode.view";

const ExecMode = forwardRef((props, ref) => {

  const { 
    flow, 
    isShareMode=false, 
    isExecMode=false, 
    onChangeExecMode = () => {},
  } = props;

  const [leftKey, setLeftKey] = useState(Math.random());
  const [rightKey, setRightKey] = useState(Math.random());
  const userId = sessionStorage.getItem("id");
  const [activeTab, setActiveTab] = useState(null);
  const reqUser = useDetail(`{ user { accountSettings } }`, userId);
  const [callGetExecModeSettings, reqGetExecModeSettings] = useGetCall("/flows/get_exec_mode_settings", { flow_id: flow.id });
  const execModeSettings = reqGetExecModeSettings.data ?? [];

  const [callExecute] = usePost("/flows/execute_node");

  const onExecuteNode = (remoteId) =>
    callExecute({
      root_node_id: remoteId,
      user_id: sessionStorage.getItem("id"),
    });

  useImperativeHandle(ref, () => ({
		refetchExecModeSettings() {
			return callGetExecModeSettings({ });
		},
    refetchData() {
      return reqUser.refetch();
    }
	}))

  useEffect(() => {
    reqUser.refetch();
  }, [flow, isExecMode, userId]);

  useEffect(() => {
    callGetExecModeSettings({ });
  }, [flow, isExecMode,]);

  useEffect(() => {
    if(execModeSettings.length > 0 && activeTab == null)
      setActiveTab(execModeSettings.findIndex(execModeSetting => execModeSetting));
  }, [execModeSettings]);

  useEffect(() => {
    setLeftKey(Math.random());
    setRightKey(Math.random());
  }, [activeTab]);

  if(reqGetExecModeSettings.loading) return <div></div>;

  const { user = {} } = reqUser.data;
  const tutorialSettings = user.accountSettings?.tutorial ?? []
  const execModeTutorial = tutorialSettings.find((tutorial) => tutorial.name == "exec_mode") || {};
  const isTutorialFinished = execModeTutorial?.is_finished ?? false;
  const currentTutorialStep = execModeTutorial?.current_step ?? 0;

  if(reqUser.loading) return <div className="content container-fluid px-1 px-md-3 py-3">
    <div className="d-flex justify-content-center">
      <div className="card p-0" style={{ width: "100%", height: "95vh", overflowY: "auto" }}>
        <div class="card-body" style={{ padding: "10rem 0" }}>
          <Loading />
        </div>
      </div>
    </div>
  </div>;

  return <View
    flow={flow}
    leftKey={leftKey}
    rightKey={rightKey}
    execModeSettings={execModeSettings}
    activeTab={activeTab}
    isShareMode={isShareMode}
    isTutorialShown={!isTutorialFinished}
    currentTutorialStep={currentTutorialStep}
    tutorialSteps={execModeTutorialSteps}
    setActiveTab={setActiveTab}
    onExecuteNode={onExecuteNode}
    onChangeExecMode={onChangeExecMode}
  />;

})

ExecMode.displayName = "ExecMode";

export default ExecMode;