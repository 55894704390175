
const getAdjacencyMatrix = (nodes, edges) => {

    const adjacency = {};

    for (const node of nodes)
        adjacency[node.id] = []

    for (const edge of edges) {
        if (Array.isArray(adjacency[edge.source])) {
            adjacency[edge.source].push({
                "node": nodes.find(node => node.id == edge.target),
                "edge": edge
            })
        } else {
            adjacency[edge.source] = [{
                "node": nodes.find(node => node.id == edge.target),
                "edge": edge
            }]
        }
    }

    return adjacency
}

const getFlowRoots = (nodes, edges) => {

    const roots = []
    const adjacency = getAdjacencyMatrix(nodes, edges)
    const uniqueVisited = new Set()

    for (const node of nodes) {
        const neighbours = adjacency[node.id];
        for (const neighbour of neighbours)
            uniqueVisited.add(neighbour["node"]);
    }

    const visited = Array.from(uniqueVisited)
    for (const node of nodes) {
        const onlyVisitedIds = visited.map(nd => nd?.id)
        if (!onlyVisitedIds.includes(node.id))
            roots.push(node)
    }

    return roots;
}


const dfs = (node, index, adjacency, visited) => {

    if (!node) return index + 1
    const onlyVisitedIds = Array.from(visited).map(nd => nd.id)
    if (onlyVisitedIds.includes(node.id))
        return index

    node.data.idx = index;
    visited.add(node)
    const childrens = adjacency[node.id];
    childrens.sort(function (a, b) {
        if (a.edge.mode === "NEXT" && b.edge.mode !== "NEXT") return -1;
        if (a.edge.mode !== "NEXT" && b.edge.mode === "NEXT") return 1;
        const dateA = new Date(a.node.createdAt);
        const dateB = new Date(b.node.createdAt);
        return dateB - dateA;
    });
    let currentIndex = index + 1;

    for (const child of childrens) {
        currentIndex = dfs(child["node"], currentIndex, adjacency, visited)
    }
    return currentIndex
}


function assignIdxNodes(nodes, edges) {

    const roots = getFlowRoots(nodes, edges);
    const adjacency = getAdjacencyMatrix(nodes, edges);
    roots.sort(function (a, b) {
        return new Date(a.createdAt) - new Date(b.createdAt);
    })

    let currentIndex = 1;
    for (const root of roots) {
        const visited = new Set();
        currentIndex = dfs(root, currentIndex, adjacency, visited)
    }

    return nodes;
}

export { assignIdxNodes }
