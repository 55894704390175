import React, { useState } from "react";
import View from "components/tmisc/apps/rpa/generator/Definition.view";

function Definition({
  flow,
  index, 
  currentIndex,
  values,
  handleEdit,
  handleAddDepth,
  handleBackDepth,
  flowResult,
 }) {

  const [isHovered, setIsHovered] = useState(false);

  return (
    <View
      flow={flow}
      index={index}
      currentIndex={currentIndex}
      values={values}
      handleEdit={handleEdit}
      isHovered={isHovered}
      setIsHovered={setIsHovered}
      handleAddDepth={handleAddDepth}
      handleBackDepth={handleBackDepth}
      flowResult={flowResult}
    />
  );

}

export default Definition;