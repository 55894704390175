import React, { useState, useEffect } from 'react';
import { usePost, useGet, useGetCall } from 'seed/api';
import SchemaForm from 'components/util/schema_form/SchemaForm';
import { Loading } from 'seed/helpers';
import { parseCollectionOptions } from 'components/tmisc/apps/forms/util';
import {LANDING_URL} from "settings"

const RouteForm = ({ match }) => {

  const userId = sessionStorage.getItem("id")
  const token = match?.params?.token;

  const [formStatus, setFormStatus] = useState();
  const [lastNodeId, setLastNodeId] = useState(null);

  const [callSendForm] = usePost("/apps/forms/send_form", {
    onCompleted: () => {
      setFormStatus("COMPLETED")
      window.parent.postMessage({ action: "close_modal" })
    },
    onError: () => setFormStatus("ERROR")
  });

  const reqFormData = useGet("/apps/forms/get_form_data", { token: token, user_id: userId }, {
    onCompleted: () => setFormStatus("ACTIVE"),
    onError: () => setFormStatus("NOT_FOUND")
  });

  const [callCollections, reqCollections] = usePost("/collections/get_data_by_queries");
  const collections = reqCollections?.data;
  
  const formData = reqFormData?.data ?? {};
  const formTitle = formData?.title ?? "";
  const nodeId = formData?.node_id;
  const flowId = formData?.flow_id;
  let schema = formData?.schema ?? [];


  const collectionReferences = (schema??[])?.map((field) => field.collection_options).flat();
  const queries = collectionReferences.map((ref) => ref?.query).filter((query) => query != null);

  if(collections != null && schema != null) 
    schema = schema.map((field) => 
      field.collection_options != null 
        ? parseCollectionOptions(collections, field) 
        : field
    );

  useEffect(() => {
    if (localStorage.getItem("id") != null) { //Preload data from localStorage
      sessionStorage.setItem("token", localStorage.getItem("token"));
      sessionStorage.setItem("id", localStorage.getItem("id"));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if(nodeId != lastNodeId) {
      setLastNodeId(nodeId);
      callCollections({ queries, node_id: nodeId });
    }
  }, [schema, collections, queries, nodeId, lastNodeId]);


  const onFormSubmit = (data) => {
    setFormStatus("LOADING")
    callSendForm({
      response: data,
      node_id: nodeId,
      sender_id: userId,
      token: token
    });
  }
  const SchemaComponent = () =>
    <div class="card animate__animated animate__fadeInUp animate__faster">
      <div class="card-header">
        <h3>{formTitle != "" ? formTitle : "Formulario"}</h3>
      </div>
      <div class="card-body animated__animated animated_fadeIn">
        <SchemaForm 
          flowId={flowId}
          nodeId={nodeId}
          onSubmit={onFormSubmit} 
          schema={schema} 
          submitText='Enviar' 
        />

        <a href={LANDING_URL} className='d-flex float-right mt-2 mr-3'>
          <span className='pr-2' style={{color: '#70757a'}}>Powered by</span>
        <img class="z-index-2"
            src="/theme/svg/logos/logo.svg" alt="Logo" style={{ height: "1.1rem" }} />
        </a>
      
      </div>
    </div>

  const Message = ({ icon, iconColor = "#607080", title, subtitle }) =>
    <div class="text-center animate__animated animate__fadeInUp animate__faster">
      {icon ? <i className={icon + " mb-5"} style={{ fontSize: "4em", color: iconColor }}></i> : null}
      {title ? <h2>{title}</h2> : null}
      {subtitle ? <p className='pb-0'>{subtitle}</p> : null}
    </div>

  const MessageComponent = () =>
    <div class="card py-5">
      <div class="card-body py-10">
        {formStatus == "LOADING" ? <Loading /> : null}
        {formStatus == "NOT_FOUND" ? <Message title={"Formulario no encontrado"} subtitle={"Revisa el enlace o el acceso"} /> : null}
        {formStatus == "ERROR" ? <Message icon={"far fa-times-circle"} title={"Ha ocurrido un error en el envío"} subtitle={"Prueba de nuevo"} /> : null}
        {formStatus == "COMPLETED" ? <Message icon={"fas fa-check-circle"} iconColor={"#00C9A7"} title={"Formulario enviado exitosamente"} /> : null}
      </div>
    </div>

  return (
    <main id="content" role="main" class="main pl-0">

      <div class="container py-5 py-sm-10">

        <div class="row justify-content-center">
          {formStatus == "ACTIVE" ? <div class="col-md-7 col-lg-6"><SchemaComponent /></div> : null}
          {formStatus != "ACTIVE" && formStatus != null ? <div class="col-md-7 col-lg-6"><MessageComponent /></div> : null}
        </div>

      </div>
    </main>
  )
}

export default RouteForm;