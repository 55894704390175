import React, { useEffect, useState } from "react";
import JsxParser from 'react-jsx-parser'
import {
  postMessage, 
  addListener
} from "components/util/win";
// import FormCreateNode from "components/util/schema_form/CreateNode";
import SamplePicker from "components/tmisc/apps/rpa/designer/SamplePicker";	
import RPADesigner from "components/tmisc/apps/rpa/designer/RPADesigner";

/* 

   Import components here that can we rendered inside the window
   Example: import MyComponent from "components/MyComponent";
   Const Components = { 
     MyComponent 
   };

*/
const Components = {
  SamplePicker,
  RPADesigner,
};

/* 

  Import handlers here that can be called from the window
   Example: import myHandler from "components/myHandler";
   String JSX: <button onClick={myHandler}>Click me</button>
   Const Handlers = { 

     myHandler
 };

*/
const Handlers = {
};

function WindowRoute(props) {

  const [component, setComponent] = useState(null);
  const [paramsLoaded, setParamsLoaded] = useState(false);

  useEffect(() => {

    addListener((data) => {

      if(data.type == "get_component") {
        setComponent(
          <JsxParser
            components={Components}
            bindings={{
              ...Handlers,
              ...props,
            }}
            jsx={data.component}
            renderInWrapper={false}
            onError={e => console.log(e)}
          />
        );
      }
      else if(data.type == "parameters") {
        sessionStorage.setItem("parameters", JSON.stringify(data.parameters));
        setParamsLoaded(true);
      }

    });

    postMessage({ type: "get_component" });
    postMessage({ type: "parameters" });

  }, []);

  return <div 
    className="w-100 h-100"
    style={{
      backgroundColor: "white",
      overflow: "auto",
    }}
  >
    { paramsLoaded ? component : null }
  </div>;

}

export default WindowRoute;