import React, { useState, useEffect } from 'react';
import { usePost, useGetCall } from 'seed/api';
import View from './RouteApproval.view'
import { Loading } from 'seed/helpers';

const RouteApproval = ({ match }) => {

  const userId = sessionStorage.getItem("id")
  const token = match?.params?.token;

  const [isAuth, setIsAuth] = useState(false)
  const [formStatus, setFormStatus] = useState("LOADING");
  const [selectedToken, setSelectedToken] = useState(null)
  const [approvalStatus, setApprovalStatus] = useState(null)


  const [callAprovalData, reqApprovalData] = useGetCall("/apps/forms/get_approval_data", { token: token, user_id: userId }, {
    onCompleted: (data) => setFormStatus(data.length > 0 ? "ACTIVE" : "NOT_FOUND"),
    onError: () => setFormStatus("NOT_FOUND")
  });

  const [callAuth, reqCall] = useGetCall("/auth/user", "", {
    onCompleted: () => { setIsAuth(true); callAprovalData(); },
    onError: () => window.location.replace(`/login?next=${encodeURIComponent(new URL(window.location.href).pathname)}`)
  })

  const [callSendApproval] = usePost("/apps/forms/send_approval", {
    onCompleted: () => {
      setFormStatus("COMPLETED")
      window.parent.postMessage({ action: "close_modal" })
    },
    onError: () => setFormStatus("ERROR")
  });

  useEffect(() => {
    if (localStorage.getItem("id") != null) { //Preload data from localStorage
      sessionStorage.setItem("token", localStorage.getItem("token"));
      sessionStorage.setItem("id", localStorage.getItem("id"));
    }
    callAuth({ user_id: sessionStorage.getItem("id") });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!isAuth) return null;
  if (reqApprovalData.loading) return <Loading  />

  const approvalList = [...(reqApprovalData.data ?? [])].reverse()
  const approvalData = approvalList.find(approval => approval.approve_token == selectedToken) ?? {};
  const values = approvalData.values ?? [];
  const schema = approvalData.schema ?? [];
  const approveToken = approvalData.approve_token ?? ""
  const formTitle = approvalData.title ?? "";
  const nodeId = approvalData.node_id;
  const executionId = approvalData?.execution_id;

  const onSelectApproval = (approvalToken) =>
    setSelectedToken(approvalToken)

  const onClickBack = () => {
    setFormStatus("ACTIVE");
    callAprovalData();
    setSelectedToken(null)
  } 

  const onFormSubmit = (data) => {
    setFormStatus("LOADING")
    setApprovalStatus(data.approval_status)

    callSendApproval({
      approval_data: {
        response: data,
        approval_status: data.approval_status,
        approve_token: approveToken
      },
      node_id: nodeId,
      sender_id: userId,
      execution_id: executionId
    });
  }

  const pendingApprovalList = approvalList.filter(approval => approval.approve_status == "PENDING")
  const pastApprovalList = approvalList.filter(approval => approval.approve_status != "PENDING")
  
  return <View
    values={values}
    schema={schema}
    token={token}
    formStatus={formStatus}
    formTitle={formTitle}
    approvalStatus={approvalStatus}
    pendingApprovalList={pendingApprovalList}
    pastApprovalList={pastApprovalList}
    selectedToken={selectedToken}
    onSelectApproval={onSelectApproval}
    onFormSubmit={onFormSubmit}
    onClickBack={onClickBack}
  />
}

export default RouteApproval;