import React from "react";
import { useHistory } from "react-router-dom";
import { useSave } from "seed/gql";
import { SAVE_FOLDER } from "seed/gql/queries";
import View from "components/start/folders/FormFolder.view";

function FormSaveFolder({ onSave }) {

  const history = useHistory();

  const userId = sessionStorage.getItem("id");
  const [callSave] = useSave(SAVE_FOLDER, {
    onCompleted: (data) => {
      history.goBack();
      onSave(data.saveFolder.folder.id);
    }
  });

  const onSubmit = (values) => {
    callSave({
      name: values.name,
      user: parseInt(userId)
    })
  }

  return <View onSubmit={onSubmit} />;
}

export default FormSaveFolder;