import React from "react";
import PropTypes from "prop-types";
import View from "components/util/schema_form/fields/dashboard/Chart.view";

function Chart({ chart, isSelected, onSelectChart }) {

  const id = chart.id;
  const type = chart.type;
  const title = chart.title;

  const onClickChart = () => {
    onSelectChart(id);
  }

  return (
    <View
      id={id}
      type={type}
      title={title}
      isSelected={isSelected}
      onClickChart={onClickChart}
    />
  );
}

Chart.propTypes = {};

export default Chart;