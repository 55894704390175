import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { getMention } from "components/util/schema_form/fields/rich_text/Editor.lib";
import View from "components/util/schema_form/fields/rich_text/OptionList.view";


const OptionList = forwardRef(function OptionList({items, command, nodes, collections, datatypes, onClickAddReference}, ref) {

  const [selectedItem, setSelectedItem] = useState(items.length > 0 ? items[0].id : null);

  const onSelectItem = (id) => {
    //id = "#response_412836fd00.input" // TODO
    onClickAddReference(command)
    // const mention = getMention(id, nodes, collections, datatypes)
    // if (mention)
      // command({
      //   id: "add",
      //   label: "Agregar referencia"
      // })
  }

  const onUpHandler = () => {
    const selectedIndex = items.findIndex((item) => item.id == selectedItem);
    const newIndex = ((selectedIndex + items.length) - 1) % items.length;
    setSelectedItem(items[newIndex].id);
  }

  const onDownHandler = () => {
    const selectedIndex = items.findIndex((item) => item.id == selectedItem);
    const newIndex = (selectedIndex + 1) % items.length;
    setSelectedItem(items[newIndex].id)
  }

  const onEnterHandler = () => {
    onSelectItem(selectedItem)
  }

  useEffect(() => {
    setSelectedItem(items.length > 0 ? items[0].id : null);
  }, [items])

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }) => {
      if (event.key === 'ArrowUp') {
        onUpHandler()
        return true
      }

      if (event.key === 'ArrowDown') {
        onDownHandler()
        return true
      }

      if (event.key === 'Enter') {
        onEnterHandler()
        return true
      }

      return false
    },
  }))


  return (
    <View
      items={items}
      selectedItem={selectedItem}
      onSelectItem={onSelectItem}
    />
  );
})

export default OptionList;