import React from "react";
import { ReactFlowProvider } from "reactflow";
import Board from "components/board/Board";


const FlowPreviewView = ({ flowId, nodes, edges, annotations }) => (
  <ReactFlowProvider>
    <div className="w-100 h-100">
      <Board
        flowId={flowId}
        nodes={[...nodes, ...annotations]}
        edges={edges}
        isControlsShown={false}
      />
    </div>
  </ReactFlowProvider>

);

export default FlowPreviewView;