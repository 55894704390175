const filtersData = [
  { name: "events", label: "Eventos", helpMessage: "Eventos para iniciar un proceso" },
  { name: "updates", label: "Actualizaciones", helpMessage: "Acciones para crear o actualizar registros de otros sistemas" },
  { name: "notifications", label: "Notificaciones", helpMessage: "Acciones que realizan notificación a través de diversas apps de comunicación" },
  { name: "ai", label: "IA", helpMessage: "Herramientas IA que permiten integrar capacidades avanzadas para analizar datos, clasificar información, ..." },
]

const categoriesData = [
  { app: "forms", groupLabel: "Iniciales" },
  { name: "events", label: "Eventos", groupLabel: "Iniciales", icon: "fas fa-bolt" },

  { app: "mail", groupLabel: "Básicos" },
  { app: "documents", groupLabel: "Básicos" },
  { app: "process", groupLabel: "Básicos" },
  { app: "data", groupLabel: "Básicos" },


  { app: "files", groupLabel: "Productividad" },
  { app: "sheets", groupLabel: "Productividad" },
  { app: "projects", groupLabel: "Productividad" },
  { app: "calendar", groupLabel: "Productividad" },

  { app: "crm", groupLabel: "Empresarial" },
  { app: "invoicing", groupLabel: "Empresarial" },
  { app: "ecommerce", groupLabel: "Empresarial" },
  { app: "helpdesk", groupLabel: "Empresarial" },
  { app: "payment_processing", groupLabel: "Empresarial" },
  { app: "rrhh", groupLabel: "Empresarial" },
  { app: "document_manager", groupLabel: "Empresarial" },

  { app: "assistants", groupLabel: "Comunicación" },

  { app: "databases", groupLabel: "Avanzados" },
  { app: "web_requests", groupLabel: "Avanzados" },
  { app: "rpa", groupLabel: "Avanzados" },


  { app: "developer", groupLabel: "Internos" },
  
  { app: "rankings", groupLabel: "Específicos" },
  { app: "crz_nd", groupLabel: "Específicos" },
  
  { app: "test", groupLabel: "Test" }  
]


export { filtersData, categoriesData };