import React from "react";
import Form from "components/tmisc/apps/forms/Form";
import Result from "components/tmisc/apps/forms/Result";

const Forms = ({flow}) =>
  <div>
    <Form flow={flow}/>
    <Result flow={flow}/>
  </div>;

export default Forms;