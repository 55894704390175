import React from "react";
import ExecutionsList from "components/executions/ExecutionsList";
import "styles/css/executions/Executions.css";

const ExecutionsView = ({
  flow,
  styles,
  refetchExecutions
}) => (
  <div id="executions"
    className="px-2 scrollbar-narrow"
    style={{ ...styles }}>
    <div className="w-100 px-4 py-3">
      <div className="row">
        <div className="col-lg-12 mb-4" >
          <h2 className="mb-4 mt-2">Resultados</h2>
          <ExecutionsList
            flow={flow}
            placeholder="No hay procesos recientes"
            isColumns={true}
            refetchParentExecutions={refetchExecutions}
          />
        </div>
      </div>
    </div>
  </div>
);

export default ExecutionsView;