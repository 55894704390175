
import React from 'react';
import { ColumnTypes } from "components/collections/util/datatypes";
import { formatNumber, getDate } from "components/collections/util/format";
import { getObjectComponent } from "components/util/table";

const getField = (column, value, collections) => {

  if(column.type == ColumnTypes.FILE)
    return <div className="d-flex">
      { 
        value 
          ? <a href={value?.url} target="_blank" rel="noreferrer" className="text-truncate"> 
              {`Ver archivo`}
            </a>
          : <span className="text-truncate">Sin archivo</span>
      }
    </div>

  if(column.type == ColumnTypes.COLLECTION) {

    const collectionColumn = collections.find(col => col.id == column?.structure?.collection_id);
    if(!value) return "";
    if(!collectionColumn) return "";

    return <div>
      { 
        value 
          ? <span className="d-flex"> 
              <span className="text-truncate">
                {
                  function(){
                    let collectionColumn = collections.find(col => col.id == column?.structure?.collection_id)
                    let item = collectionColumn?.collectionDataes.find(item => item.id == value.id);
                    if(!item) return "No encontrado";
                    return collectionColumn?.initials + '-' + formatNumber(value?.folio) + ' ' + 
                      (item?.data[Object.keys(item?.data)[0]] ?? "");
                  }()
                }
              </span>
            </span>
          : <span className="text-truncate">Sin selección</span>
      }
    </div>

  }

  if(column.type == ColumnTypes.BOOLEAN)
    return value ? "Sí" : "No" 

  if(column.type == ColumnTypes.DATE)
    return getDate(value, "DD/MM/YYYY")

  if(column.type == ColumnTypes.OBJECT && value)
    return getObjectComponent(value);
  
  return <div className="text-truncate">
    {value?.toString()}
  </div>

}

export {
  getField
}