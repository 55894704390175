import React from "react";
import { IdeasList, IdeasListType } from "components/studio/ideas/IdeasHistory.view.lib.list";
import IdeasDetails from "components/studio/ideas/IdeasHistory.view.lib.detail";

function IdeasHistoryView({ 
  savedSuggestions = [],
  historySuggestions = [],
  selectedSuggestion = {},
  onChangeSuggestion,
  onSelectSuggestion,
  onCreateGuide
}) {
  return <div 
    className="card card-body"
    style={{ 
      background: "#f8fafe", 
      marginTop: "12px",
    }}
  >
    <div 
      className="scrollbar-narrow mt-n3"
      style={{
        minHeight: "35rem",
        overflowY: "auto",
        overflowX: "hidden",
        position: "relative",          
      }}
    >
      {
        selectedSuggestion.id 
          ? <>
              <div className="d-flex justify-content-start w-100">
                <button
                  type="button"
                  className="btn btn-link p-0 my-2"
                  onClick={() => onSelectSuggestion({})}
                >
                  <i className="fa fa-chevron-left"></i> Volver
                </button>
              </div>
              <IdeasDetails 
                suggestion={selectedSuggestion} 
                showGuideButton={true}
                onCreateGuide={onCreateGuide}
              />
            </>
          : <>
            <IdeasList
              type={IdeasListType.SAVED}
              suggestions={savedSuggestions}
              onChangeSuggestion={(suggestion) => onChangeSuggestion(suggestion)}
              onSelectSuggestion={onSelectSuggestion}
            />
            {
              savedSuggestions.length > 0 &&
                <hr className="mt-3 mb-2"/>
            }
            <IdeasList
              type={IdeasListType.HISTORY}
              suggestions={historySuggestions}
              onChangeSuggestion={(suggestion) => onChangeSuggestion(suggestion)}
              onSelectSuggestion={onSelectSuggestion}
            />
          </>
      }
    </div>
  </div>
}

export default IdeasHistoryView;