import React from "react";
import RouteForm from "components/tmisc/apps/forms/RouteForm";
import RouteApproval from "components/tmisc/apps/forms/RouteApproval";
import RouteGenerator from "components/tmisc/apps/rpa/RouteGenerator";
import {  Switch, Route, Redirect } from "react-router-dom";

const AppRoutes = () =>
  <div className="w-100 h-100" style={{overflowY: "auto", position: "fixed"}}>
    <Switch>
      <Route path="/f/:token" component={RouteForm} />
      <Route path="/a/:token" component={RouteApproval} />
      <Route path="/r" component={RouteGenerator} />
      <Redirect to="/app" />
    </Switch>
  </div>;

export default AppRoutes;