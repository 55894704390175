/* eslint-disable max-len */
import React, { useState, useEffect } from "react";
import { Field } from "formik";
import { Modal } from "seed/helpers";
import SchemaBuilderComponent from "components/util/schema_form/fields/schema_builder/SchemaBuilder";
import BaseField from "components/util/schema_form/fields/BaseField";


const getTypeName = (type) => {
	if (type == "TEXT") return "Texto"
	if (type == "TEXT_AREA") return "Párrafo"
	if (type == "DATE") return "Fecha"
	if (type == "DATE_TIME") return "Fecha-hora"
	if (type == "FILE") return "Subir archivos"
	if (type == "SELECT") return "Opción múltiple"
	if (type == "SECTION") return "Sección (Grupo)"
	return type
}

function SchemaBuilderField({
	required = false,
	disabled = false,
	schemaButtonText = "Agregar campos",
	schemaFieldsPlaceholder = "Nombre del campo",
	flowId,
	name,
	label,
	schema,
	values,
	value,
	caption,
	helpMessage,
	setFieldValue,
}) {
	const [isEditSchemaShown, setIsEditSchemaShown] = useState(false);
	const onOpenSchemaModal = () => setIsEditSchemaShown(true);
	schema = schema ?? []

	useEffect(() => {
		if (value === undefined) setFieldValue(name, [])
	}, [])

	return (
		<>
			<div className="d-flex align-items-center">
				<BaseField name={name} label={label} required={required} caption={caption} helpMessage={helpMessage}
					enableComments={false} values={values} setFieldValue={setFieldValue}>
					{
						schema.length > 0 ?
							<table class="mb-2 mt-3">
								<tr>
									<th style={{ border: "1px solid #ddd" }}>
										<span class="dropdown-header py-2 px-3"><span style={{ color: "#777" }}>Nombre</span></span>
									</th>
									<th style={{ border: "1px solid #ddd" }}>
										<span class="dropdown-header py-2 px-3"><span style={{ color: "#777" }}>Tipo</span></span>
									</th>
								</tr>

								{
									schema.map((value) =>
										<tr key={`${value.label}-${value.type}`}>
											<td class="px-3 text-align-center"
												style={{ border: "1px solid #ddd", color: "#444", paddingTop: "0.45rem", paddingBottom: "0.45rem" }}>
												<span class="mr-2 text-truncate d-block" style={{ maxWidth: "18rem", fontSize: "0.95em" }}>{value.label}</span>
											</td>
											<td class="px-3"
												style={{ border: "1px solid #ddd", color: "#444", paddingTop: "0.45rem", paddingBottom: "0.45rem" }}>
												<span style={{ color: "#aaa", fontSize: "0.9em" }}>{getTypeName(value.type)} {!value.required ? "(Opcional)" : ""}</span>
											</td>
										</tr>
									)
								}
							</table> : null
					}
					{!disabled ?
						<button type="button" className="btn btn-sm btn-outline-primary mt-1" onClick={onOpenSchemaModal}>
							<i className="fas fa-edit mr-1"></i> {schemaButtonText}
							<Field type="hidden" name={name} id={name} required={required} />
						</button> : null}
				</BaseField>
			</div>
			{isEditSchemaShown && (
				<Modal
					width="750"
					height={window.innerHeight - 150}
					animation=""
					onClose={() => setIsEditSchemaShown(false)}
					component={() =>
						<div className="card">
							<div className="card-header"><h3>{schemaButtonText}</h3></div>
							<div className="card-body">
								<SchemaBuilderComponent
									flowId={flowId}
									schema={schema}
									schemaFieldsPlaceholder={schemaFieldsPlaceholder}
									onSubmit={(data) => {
										setFieldValue(name, data)
										setIsEditSchemaShown(false)
									}} />
							</div>
						</div>
					}
				/>
			)}
		</>
	)
}

export default SchemaBuilderField; 