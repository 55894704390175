/* eslint-disable max-len */
import React from "react";
import "styles/css/board/Toolbox.css";


const SidenavItem = ({
  name,
  label,
  icon,
  iconStyle,
  activeSection,
  onClickItem
}) => (
  <button type="button"
    className={`btn btn-app btn-sm pb-0 px-0 d-block text-center w-100 ` +
      (name == activeSection ? "active" : "")}
    onClick={() => onClickItem(name)}
      >
    <i className={icon} style={iconStyle}></i> <br />
    <label class="w-100 text-truncate d-inline-block">{label}</label>
  </button>

);

export default SidenavItem;
