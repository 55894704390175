/* eslint-disable max-len */
import React from "react";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import BaseField from "components/util/schema_form/fields/BaseField";

const OptionItem = ({ label, icon, disabled, isOnSelector = false }) =>
	<div className="d-flex align-items-center px-1 py-2"
		style={{
			pointerEvents: "none",
			color: "#777", fontSize: "1rem",
			...isOnSelector 
				? { 
						position: "absolute", 
						marginTop: "-2.55rem", 
						marginLeft: "1rem", 
						background: disabled ? "#f8fafd" : "#fff" 
					} 
				: {}
		}}>
		{icon ? <i className={icon + " mr-3"} style={{ color: "#888" }}></i> : null}
		<span style={{ color: "#606a70", fontSize: "0.9em" }}>{label}</span>
	</div>


const SelectField = ({
	name,
	label,
	options,
	value,
	values,
	required,
	disabled,
	optionsIcon,
	openText,
	placeholder = "Selecciona una opción",
	caption,
	helpMessage,
	enableComments,
	setFieldValue,
	onChange = () => { },
	onOpen = () => { },
}) => {

	const getLabelName = (value) => {
		let opt = options.find((option) => option.value == value)
		return opt?.label ?? value;
	}

	return (
		<BaseField
			name={name}
			label={label}
			values={values}
			required={required}
			caption={caption}
			helpMessage={helpMessage}
			enableComments={enableComments}
			setFieldValue={setFieldValue}
		>
			<div className="w-100">
			<Autocomplete
				id={name}
				freeSolo={openText}
				forcePopupIcon
				value={value}
				options={options.map((item) => item.value)}
				required={required}
				disabled={disabled}
				onOpen={onOpen}
				getOptionLabel={(option) => getLabelName(option)}
				renderInput={(params) => {
					let selectedOption = options.find((option) => option.value == value)
					if (selectedOption != null)
						params.inputProps.value = selectedOption?.label ?? selectedOption?.value
					return (<div className="w-100">
						<TextField {...params} required={required} variant="outlined"
							placeholder={placeholder} type="text"
							className={(!openText ? "no-autocomplete " : " ") +
								(selectedOption ? "hidden-input " : " ")} />
						{selectedOption ? <OptionItem label={getLabelName(selectedOption.value)} icon={optionsIcon} disabled={disabled} isOnSelector={true} /> : null}
					</div>
					)
				}
				}
				renderOption={(option, { selected }) =>
					<OptionItem disabled={disabled} label={getLabelName(option)} icon={optionsIcon} />
				}
				onChange={(event, newValue) => { setFieldValue(name, newValue); onChange(newValue) }}
				onInputChange={(event, newValue) => { if (openText) { setFieldValue(name, newValue); onChange(newValue) } }}
				style={{ width: "100%" }}
			/>
			</div>
		</BaseField>
	)
}

export default SelectField;