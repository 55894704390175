import React from 'react';
import ExecutionsList from "components/executions/ExecutionsList";


const ExecModeCharts = ({ 
  flow, 
  isFullWidth,
  execModeSetting,
}) => {

  return <div 
    className='scrollbar-narrow'
    style={{
      height: "100%",
      overflowY: "auto",
    }}
  >
    <div
      className="px-2"
      style={{
        width: "100%",
        height: "calc(100% - 110px)",
        paddingTop: "0.3rem",
      }}
    >
      <div className="w-100 p-4">
        <ExecutionsList 
          flow={flow} 
          rootId={execModeSetting.reference.id}
          placeholder="No hay actividad reciente" 
          isColumns={isFullWidth}
        />
      </div>
    </div>
  </div>;
}

export default ExecModeCharts;