import React from 'react';
import { useDetail } from "seed/gql";
import { Loading } from "seed/helpers";
import View from "components/tmisc/settings/Flows.view";

function Flows() {

  const userId = sessionStorage.getItem("id");

  const reqUser = useDetail(`{
    user {
      firstName
      lastName
      email
      license { name }
    }
  }`, userId);

  if (reqUser.loading) return <Loading />;
  if (reqUser.error) return "Error";

  const { user = {} } = reqUser.data;

  return (
    <View user={user} />
  );
}

export default Flows;