import { toCamelCase } from "components/collections/util/format";

const ColumnCallbaks = ({
  _columns,
  _rows,
  isReadOnly,
  setColumns,
  setRows,
  setSelectedCell,
  onAddColumn,
  onChangeColumn,
  onChangeColumnOptions,
  onDeleteColumn
}) => {

  const _onAddColumn = (type) => {

    if(isReadOnly) return;

    const column = { type, name: `column${_columns.length}`, label: `Column ${_columns.length}` };
    const newColumns = [..._columns, column];

    setColumns(newColumns);
    onAddColumn([
      ..._columns.map(col => ({
        initial: col,
        final: col,
        internal: col.internal,
        action: 'none'
      })),
      {
        initial: null,
        final: column,
        action: 'create'
      }
    ]);
    setSelectedCell({ row: -1, column: _columns.length });

  }

  const _onChangeColumn = (index, column) => {

    if (isReadOnly) return;
    if (!_columns[parseInt(index)] || !column) return;

    const prevKey = _columns[parseInt(index)].name;
    let newColumns = Object.assign([], _columns);
    newColumns = newColumns.map(col => Object.assign({}, col));

    newColumns[parseInt(index)] = column;
    newColumns[parseInt(index)].name = toCamelCase(column.label);
    
    if (prevKey != newColumns[parseInt(index)].name) {

        let newRows = [..._rows];
        newRows = newRows.map(row => Object.assign({}, row));
        newRows.forEach(row => {
          row.data = { ...row.data };
          row.data[newColumns[parseInt(index)].name] = row.data[prevKey];
          delete row.data[prevKey];
        });
        
        setRows(newRows);

    }

    setColumns(newColumns);
    onChangeColumn(_columns.map((col, i) => ({
      initial: col,
      final: i == index ? { ...column, name: toCamelCase(column.label) } : col,
      internal: col.internal,
      action: i == index ? 'update' : 'none'
    })));

  }

  const _onChangeColumnOptions = (index, newColumn) => {
    const newColumns = [..._columns];
    newColumns[parseInt(index)] = newColumn;
    setColumns(newColumns);
    onChangeColumnOptions(_columns.map((col, i) => ({
      initial: col,
      final: i == index ? newColumn : col,
      action: i == index ? 'update' : 'none',
      internal: col.internal,
    })));
  }

  const _onDeleteColumn = (index) => {

    if (isReadOnly) return;

    const column = _columns[parseInt(index)];

    let newColumns = [..._columns];
    newColumns = newColumns.map(col => Object.assign({}, col));
    newColumns.splice(index, 1);

    let newRows = [..._rows];
    newRows = newRows.map(row => Object.assign({}, row));
    newRows.forEach(row => {
      row.data = { ...row.data };
      delete row.data[column.name];
    });

    setColumns(newColumns);
    setRows(newRows);
    onDeleteColumn(_columns.map((col, i) => ({
      initial: col,
      final: i == index ? null : col,
      internal: col.internal,
      action: i == index ? 'delete' : 'none'
    })));

  }

  return {
    _onAddColumn,
    _onChangeColumn,
    _onChangeColumnOptions,
    _onDeleteColumn
  }

}

export default ColumnCallbaks;