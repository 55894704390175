import React from "react";
import { Loading } from "seed/helpers";
import ScrollArrow from "components/util/helpers/ScrollArrow";


const FlowsView = ({
  Flow,
  NoResults,
  flows,
  activeFlow,
  filters,
  scrollAmount,
  style,
  arrowsStyle,
  arrowContainerStyle,
  isTemplateFlowsLoading,
  onSelectFlow,
}) => (
  <div className="d-flex justify-content-center" style={style}>
    {isTemplateFlowsLoading ? <Loading /> : (
      flows.length > 0 ? (
        <ScrollArrow
          scrollAmount={scrollAmount}
          leftButtonStyle={arrowsStyle}
          rightButtonStyle={arrowsStyle}
          itemsContainerStyle={arrowContainerStyle}
        >
          {flows && flows.map((flow, idx) => (
            <div
              key={flow.id}
              onClick={() => onSelectFlow(flow.id)}
              style={{ display: 'contents' }}
            >
              {Flow({
                idx: idx,
                flow: flow,
                isActive: flow.id == activeFlow
              })}
            </div>
          ))}
        </ScrollArrow>
      ) : (
        (filters.search || (filters.template_categories ?? []).length > 0) && !isTemplateFlowsLoading ? 
          <NoResults /> : null
      )
    )}
  </div>
);

FlowsView.propTypes = {};

export default FlowsView;