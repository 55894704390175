import React, { useEffect } from "react";
import lodash from "lodash";
import { Field } from "formik";
import { usePost } from "seed/api"
import { useQuery } from "seed/gql";
import { Loading } from "seed/helpers";
import VariableSelector from "components/util/schema_form/fields/variable_selector/VariableSelector"
import FilterField from "components/util/schema_form/fields/FilterField";
import BaseField from "components/util/schema_form/fields/BaseField";

const matchTypes = {
	"string": "string",
	"text": "string",
	"date": "date",
	"int": "int",
	"float": "float",
	"boolean": "boolean",
	"select": "string",
	"file": "file",
	"array": "object",
	"array-object": "object",
	"object": "object",
	"collection": "collection",
}

const getSchema = (valueI, reference, reqPost, actions, collections, nodes, datatypes) => {

	let schema = null
	if (valueI == null) return null

	if (reference == null) {
		// Collection schema
		if ((valueI ?? "").startsWith("@")) {
			for (let collection of collections)
				if (collection.name == (valueI ?? "@").substring(1))
					schema = collection.schema.structure
		} else {
			// Variable schema
			for (let node of nodes)
				for (let outputData of (node.outputValues ?? [])) {
					if (outputData.value == valueI) {
						let datatype = datatypes.find(opt => opt.name == outputData.type)
						schema = datatype == null ? (outputData.structure ?? [{
							...outputData,
							name: "self"
						}]) : datatype.structure
						
					}
				}
		}
	} else {
		// Reference schema
		const referenceSource = reference.source;
		const referenceValue = reference.value;
		if (referenceSource == "SCHEMA_BUILDER") {
			schema = reqPost.data ?? []
			// Include sending_id at the beginning of the structure
			if (schema.find(item => item.name == "sending_id") == null)
				schema = [{ label: "ID de envío", name: "sending_id", type: "string" },
				{ label: "Fecha de envío", name: "sending_date", type: "date" },
				{ label: "Correo del usuario", "name": "sending_email", "type": "string" }].concat(schema)
		}
		if (referenceSource == "ACTION_OUTPUT") {
			let actionName = referenceValue.split(".")[0]
			let outputName = referenceValue.split(".")[1]
			for (let action of actions)
				if (action.name == actionName)
					for (let outputData of (action.outputStructure ?? []))
						if (outputData.name == outputName)
							schema = outputData
		}
	}

	return schema
}


const initSchemaReference = (reference, callReq, name, values, setFieldValue) => {
	if (reference != null) {
		const referenceSource = reference.source;
		const referenceValue = reference.value;
		setFieldValue(name + ".value_a", referenceValue)
		setFieldValue(name + ".match", null)
		if (referenceSource == "SCHEMA_BUILDER") {
			const schemaBuilderValue = lodash.get(values, referenceValue)
			callReq({ schema: schemaBuilderValue })
		}
	}
}

const MatchField = ({
	name,
	label,
	value,
	values,
	flowId,
	required,
	disabled,
	caption,
	helpMessage,
	showSaveAction = false,
	valueASource = "variable",
	valueBSource = "collection",
	valueAPlaceholder = "Referencia del proceso",
	valueBPlaceholder = "Guardar en",
	valueAHeader = "Valores a guardar",
	valueBHeader = "Columnas de tabla",
	valueAReference,
	valueBReference,
	setFieldValue
}) => {

	const pageId = sessionStorage.getItem("page_" + flowId)
	const [callSchemaReferenceA, reqSchemaReferenceA] = usePost("/flows/get_schema_structure");
	const [callSchemaReferenceB, reqSchemaReferenceB] = usePost("/flows/get_schema_structure");

	const reqNodes = useQuery(`{
		flowNodes {
			outputValues
		}
  }`, `flow.id=${flowId} ${pageId ? "AND page.id=" + pageId : ""}`)

	const reqActions = useQuery(`{
		actions {
			name
			outputStructure
		}
  }`)

	const reqDataTypes = useQuery(`{
		appDatatypes {
			name
			structure
		}
  }`, '')

	const reqCollections = useQuery(`{
		collections {
			name
			schema
		}
  }`, `flow.id=${flowId}`)


	// Variable used to refresh use effect
	const schemaBuilderValueA = valueAReference?.type == "SCHEMA_BUILDER" ? lodash.get(values, valueAReference.value) : null
	const schemaBuilderValueB = valueBReference?.type == "SCHEMA_BUILDER" ? lodash.get(values, valueBReference.value) : null

	useEffect(() => {
		initSchemaReference(valueAReference, callSchemaReferenceA, name, values, setFieldValue)
	}, [schemaBuilderValueA]);

	useEffect(() => {
		initSchemaReference(valueBReference, callSchemaReferenceB, name, values, setFieldValue)
	}, [schemaBuilderValueB]);

	useEffect(() => {
		reqNodes.refetch();
		reqCollections.refetch();
		reqActions.refetch()
		reqDataTypes.refetch()
	}, [flowId]);

	const onSaveCollectionSuggestion = (collectionName) => {
		setFieldValue(name + ".collection", collectionName)
		reqCollections.refetch();
	}

	if (reqNodes.loading || reqCollections.loading || reqActions.loading || reqDataTypes.loading) return <Loading size={27} />
	if (reqNodes.error || reqCollections.error || reqActions.error || reqDataTypes.error) return "Error"

	const nodes = reqNodes.data.flowNodes;
	const actions = reqActions.data.actions;
	const collections = reqCollections.data.collections;
	const datatypes = reqDataTypes.data.appDatatypes;

	// Get schemas
	let schemaValueA = getSchema(value?.value_a, valueAReference, reqSchemaReferenceA, actions, collections, nodes, datatypes)
	let schemaValueB = getSchema(value?.value_b, valueBReference, reqSchemaReferenceB, actions, collections, nodes, datatypes)


	if (value?.match == null && schemaValueA && schemaValueB) {
		let defaultMatch = {}
		for (let varC of schemaValueB) {
			for (let varI of schemaValueA) {
				if (varC.name == varI.name || varC.label == varI.label)
					defaultMatch[varC.name] = varI.name
			}
		}
		setFieldValue(name + ".match", defaultMatch)
	}

	return (
		<div>
			<BaseField name={name} label={label} required={required} caption={caption} helpMessage={helpMessage}
				enableComments={false} values={values} setFieldValue={setFieldValue}>

				<Field type={"hidden"} name={name} id={name} required={required} />

				{valueAReference == null ?
					<div className="mt-1">
						<VariableSelector
							name={name + ".value_a"}
							label={""}
							flowId={flowId}
							values={values}
							value={lodash.get(values, name + ".value_a")}
							required={required}
							disabled={disabled}
							setFieldValue={setFieldValue}
							placeholder={valueAPlaceholder}
							enableAttributes={false}
							showCollectionCondition={false}
							showCollectionAddButton={true}
							onChange={() => { if (value.match != null) setFieldValue(name + ".match", null) }}
							selectorFilters={[{
								type: "SOURCE",
								value: valueASource
							}]}
							{...(valueASource == "collection" ? { acceptTypes: ["collection"] } : null)}
						/>
					</div> : null}

				<div className="mt-1">
					<VariableSelector
						name={name + ".value_b"}
						label={""}
						flowId={flowId}
						values={values}
						value={lodash.get(values, name + ".value_b")}
						required={required}
						disabled={disabled}
						setFieldValue={setFieldValue}
						placeholder={valueBPlaceholder}
						enableAttributes={false}
						showCollectionCondition={false}
						showCollectionAddButton={true}
						onChange={() => { if (value.match != null) setFieldValue(name + ".match", null) }}
						onSaveCollectionSuggestion={onSaveCollectionSuggestion}
						saveCollectionSuggestion={{
							type: "RAW",
							value: schemaValueA ?? [],
						}}
						selectorFilters={[{
							type: "SOURCE",
							value: valueBSource
						}]}
						{...(valueBSource == "collection" ? { acceptTypes: ["collection"] } : null)}
					/>
				</div>

				{schemaValueA && schemaValueB ?
					<div className="mt-3 pl-6 w-100">
						<div class="mt-1 mb-2" style={{ color: "#a0a0a0" }}><i>Enlace de campos</i></div>

						<table className="w-100">
							<tr>
								<th style={{ border: "1px solid #ddd" }}>
									<span class="dropdown-header py-2"><span style={{ color: "#777" }}>{valueBHeader}</span></span>
								</th>
								<th style={{ border: "1px solid #ddd" }}>
									<span class="dropdown-header py-2"><span style={{ color: "#777" }}>{valueAHeader}</span></span>
								</th>
							</tr>
							{schemaValueB.map((fieldCollection, idx) => !fieldCollection.internal &&
								<tr key={value?.collection + "_" + idx}>
									<td class="px-3 py-2" style={{ border: "1px solid #ddd" }}>
										<span class="text-truncate d-block" style={{ maxWidth: "14rem", }}>{fieldCollection.label}</span>
									</td>
									<td class="px-3 py-2 d-flex align-items-center" style={{ border: "1px solid #ddd" }}>
										{
											(value?.match_is_static??{})[fieldCollection.name]
												?	<input 
														type="text" 
														class="form-control w-50"
														placeholder="Valor fijo" 
														required={required} 
														style={{ maxHeight: "2rem" }} 
														defaultValue={value?.match[fieldCollection.name]?.substring(2)}
														onChange={(e) => setFieldValue(name + ".match." + fieldCollection.name, 
															"<>" + e.target.value)}
													/>
												: <Field as="select" name={name + ".match." + fieldCollection.name} class="px-2 py-1 w-50"
														style={{ border: "1px solid #ccc", color: "#444", maxWidth: "10rem", maxHeight: "2.2rem" }}>
														<option value="">Sin valor</option>
														{
															schemaValueA.filter(fieldVariable => 
																matchTypes[fieldVariable.type] == fieldCollection.type).map(fieldVariable =>
																	<option key={fieldVariable.name} value={fieldVariable.name}>
																		{fieldVariable.label ?? fieldVariable.name}
																	</option>)
														}
													</Field>
										}
										<button
											type="button"
											class="btn btn-link btn-sm ml-2"
											onClick={() => setFieldValue(name + ".match_is_static." + fieldCollection.name,
												!(value?.match_is_static??{})[fieldCollection.name])}
										>
											<i 
												class={(value?.match_is_static??{})[fieldCollection.name] ? "fa fa-check-square-o" : "fa fa-square-o"}
												style={{ color: "#007bff" }}
											/>
											<span className="ml-1" style={{ color: "#007bff" }}>
												Valor fijo
											</span>
										</button>
									</td>
								</tr>
							)}
						</table>

						{showSaveAction ?
							<div>
								<div class="mt-1 mb-2" style={{ color: "#a0a0a0" }}><i>Cómo guardar</i></div>
								<Field as="select" name={name + ".action"} class="form-control select-field mt-1"
									required={required}>
									<option value="create">Crear registro</option>
									<option value="update">Actualizar registro</option>
								</Field>
								{value?.action == "update" ?
									<div class="mt-2 ml-4">
										<div class="mb-1" style={{ color: "#a0a0a0" }}><i>Donde</i></div>
										<FilterField
											name={name + ".action_filter"}
											values={values}
											flowId={flowId}
											valueAPlaceholder={"Selecciona una columna"}
											valueAAttrs={schemaValueB}
											conditionDefault={"="}
											value={lodash.get(values, name + ".action_filter")}
											setFieldValue={setFieldValue}
										/>
									</div> : null}
							</div> : null}

					</div> : null}
			</BaseField>
		</div>
	)
}

export default MatchField;