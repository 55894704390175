import React from "react";
import { NavLink, Link } from "react-router-dom";
import { Modal, ModalRoute, ScriptTag } from "seed/helpers";
import Templates from "components/start/templates/Templates";
import Flows from "components/start/flows/Flows";
import FormSaveFolder from "components/start/folders/FormSaveFolder";
import FormSetFolder from "components/start/folders/FormSetFolder";
import Collaboration from "components/studio/forms/collaboration/Collaboration";
import FlowsUser from "components/tmisc/settings/Flows";
import Usage from "components/start/usage/Usage";
import FolderItem from "components/start/folders/FolderItem"
import ProfileIcon from "components/util/helpers/ProfileIcon";
import "styles/css/start/Start.css";


const StartView = ({
  user,
  folders,
  selectedFolder,
  isExecutionsShown,
  isDeveloperMode,
  setSelectedFolder,
  setIsExecutionsShown,
  onClickLogout,
  onCreateFolder,
  onEditFolder,
  onDeleteFolder,
}) => (

  <div>

    <div id="start">

      <div class="container py-4 py-md-7">
        <div id="start-container" class="row m-0 px-2 w-100">

          <div className="col-12 col-md-4 px-0 px-md-3">


            <div id="start-nav" class="card">
              <div class="card-body p-0">
                <aside class="navbar navbar-vertical-aside navbar-vertical navbar-bordered navbar-vertical-aside-initialized">
                  <div class="navbar-vertical-container">
                    <div class="navbar-vertical-footer-offset">

                      <div id="start-nav-header" class="d-flex align-items-center pl-2 py-1">


                        <div class="rounded w-auto">
                          <Link to="/" id="start-icon" className="mt-n1"></Link>
                        </div>

                        <div class="ml-1">

                          <a class="navbar-dropdown-account-wrapper rounded pl-1 pr-2"
                            style={{ background: "transparent" }}>
                            <div class="ml-2">
                              <span class="d-block h5 mb-0">
                                {user?.firstName ?? ""} {user?.lastName ?? ""}
                              </span>
                              <span class="d-block font-size-sm text-body">
                                Plan {(user?.license?.name ?? "básico").toLowerCase()}
                              </span>
                            </div>
                          </a>

                        </div>
                      </div>

                      <div id="start-settings-menu" class="navbar-vertical-content mt-3 mb-5 overflow-hidden">
                        <ul class="navbar-nav navbar-nav-lg nav-tabs">

                          <li class="navbar-vertical-aside-has-menu">
                            <a class={`js-nav-tooltip-link nav-link ${!selectedFolder && "active"}`} title="Principal"
                              style={{ cursor: "pointer" }} onClick={() => setSelectedFolder(null)}>
                              <i class="fas fa-home mr-2"></i>
                              <span class="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">Principal</span>
                            </a>

                            <ul class="nav nav-sub d-block">
                              {folders.map((folder) => (
                                <FolderItem folder={folder} key={folder.id} selectedFolder={selectedFolder} setSelectedFolder={setSelectedFolder} />
                              ))}
                              <li class="nav-item mt-1">
                                <Link className="nav-link create-folder-button" to="/create_folder">
                                  <i class="fas fa-plus mr-2"></i>
                                  <span class="text-truncate">Crear carpeta</span>
                                </Link>
                              </li>
                            </ul>
                          </li>

                          <li class="nav-item py-2">
                            <div class="nav-divider"></div>
                          </li>

                          <li class="nav-item">
                            <NavLink to="/settings" className={`js-nav-tooltip-link nav-link`} >
                              <i class="fas fa-cog ml-1 mr-3"></i>
                              <span class="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">Ajustes</span>
                            </NavLink>
                          </li>

                          <li class="nav-item">
                            <a href="#" onClick={onClickLogout} className={`js-nav-tooltip-link nav-link`}>
                              <i class="fas fa-sign-out-alt ml-1 mr-3"></i>
                              <span class="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">Cerrar sesión</span>
                            </a>
                          </li>

                        </ul>
                      </div>

                    </div>
                  </div>
                </aside>
              </div>
            </div>

          </div>

          <div className="col-12 col-md-8 px-0 px-md-2 mt-3 mt-md-0 mb-10">

            <Flows
              user={user}
              selectedFolder={selectedFolder}
              setSelectedFolder={setSelectedFolder} />

            {user.license?.name != "Test-ext" ?
              <div className="mt-3">
                <Templates />
              </div> : null}

            <Usage />

          </div>

        </div>
      </div>

    </div>

    <ModalRoute
      width="425"
      height="450"
      path="/create_folder"
      component={() => <FormSaveFolder onSave={onCreateFolder} />}
    />

    <ModalRoute
      width="425"
      height="450"
      path="/:folderId/edit_folder"
      component={({ match }) =><FormSetFolder match={match} onEdit={onEditFolder} onDelete={onDeleteFolder} />}
    />

    <ModalRoute
      width="800"
      height={window.innerHeight - 150}
      path="/:flowId/share"
      component={(props) =>
        <Collaboration flowId={props.match?.params?.flowId} />
      }
    />


    {isExecutionsShown && (
      <Modal
        width={500}
        height={500}
        onClose={() => setIsExecutionsShown(false)}
        component={FlowsUser}
      />
    )}

    <ScriptTag content={`
      // Initialization of unfold
      $('.js-hs-unfold-invoker').each(function () {
        var unfold = new HSUnfold($(this)).init();
      });
    `} />

  </div>
);

export default StartView;