
const getOption = (data, parentName) => ({
	value: data.value ?? data.name,
	label: data.value ?? data.label ?? data.name,
	path: parentName != "" ? (parentName + "." + data.name) : data.value ?? data.name,
	basePath: parentName,
	type: data.type,
	category: data.type == "collection" ? "collection" :
		parentName == "" ? "variable" : "attribute",
	data: data
})

const isComplexType = (type, datatypes) =>
	type == "variable" || type == "collection" || type == "object" || (type ?? "").startsWith("array") ||
	datatypes.find(opt => opt.name == type)


const getSuboptions = (data, selectorFilters, acceptTypes, datatypes, parentName, subFieldName) => {
	if (acceptTypes == "all" || acceptTypes.includes(data.type) || isComplexType(data.type, datatypes)) {

		let option = getOption(data, parentName)
		let datatype = datatypes.find(opt => opt.name == data.type)
		let subfields = datatype == null ? data[subFieldName] : datatype.structure
		
		return getOptions(subfields, selectorFilters, acceptTypes, datatypes, option.path)
	} 
	return [];
}

const getOptions = (dataset, selectorFilters, acceptTypes, datatypes, parentName = "", subFieldName = "structure") => {
	let options = [];
	if (isComplexType(dataset?.type, datatypes) || Array.isArray(dataset)) {
		if (Array.isArray(dataset)) {
			for (let data of dataset) {
				let option = getOption(data, parentName)
				let subOptions = getSuboptions(data, selectorFilters, acceptTypes, datatypes, parentName, subFieldName)
				if (subOptions.length > 0 || acceptTypes == "all" || acceptTypes.includes(data.type))
					options.push(option)
				options = options.concat(subOptions)
			}
		} else {
			let data = dataset[subFieldName]
			let option = getOption(data, parentName)
			let subOptions = getSuboptions(data, selectorFilters, acceptTypes, datatypes, parentName, subFieldName)
			if (subOptions.length > 0 || acceptTypes == "all" || acceptTypes.includes(data.type))
				options.push(option)
			options = options.concat(subOptions)

		}
	}
	return options
}


const filterVariable = (node, selectorFilters) => {

	const filtersApps = selectorFilters.find((filter) => filter.type == "APP");
	const filtersActions = selectorFilters.find((filter) => filter.type == "ACTION");
	const filtersSources = selectorFilters.find((filter) => filter.type == "SOURCE");

	const action = node.action ?? {}
	const app = action?.app ?? {};

	if (filtersApps && filtersApps.value.includes(app.name))
		return true

	if (filtersActions && filtersActions.value.includes(action.name))
		return true;

	if (filtersSources)
		if (filtersSources.value == "all" || filtersSources.value == "variable")
			return true;

	if (!filtersApps && !filtersApps && !filtersSources)
		return true

	return false
}

const filterCollection = (selectorFilters) => {

	const filtersSources = selectorFilters.find((filter) => filter.type == "SOURCE");
	if (filtersSources) {
		if (filtersSources.value == "all" || filtersSources.value == "collection")
			return true
	}
	if (!filtersSources)
		return true
	return false
}

const filterOptions = (options, path) =>
	options.filter(option => option.basePath == path)


// TODO CHECK HARDCODE
const parseConditionStrToState = (condition) =>{
	const includesValue = (str, vals) => {
		for(let val of vals)
			if (str.includes(val)) return val
		return null
	}

	condition = condition.slice(1,-1)
	let operator = includesValue(condition, ["=", "<>", ">=", "<=", "~", ">", "<", "is true"])
	if (operator == null) return {value_a: condition}
	let parts = condition.split(operator)
	return {
		value_a: parts.length > 0 ? parts[0].trim() : "",
		condition: operator,
		value_b: parts.length > 1 ? parts[1].trim() : ""
	}
}

const parseConditionStateToStr = condition =>
	((condition.value_a ?? "") + " " + (condition.condition ?? "") + " " + (condition.value_b ?? "")).trim()

const parseValueToState = (value = "", nodes, collections, datatypes, selectorFilters, acceptTypes, includeAddCollection) => {

	//Remove parentesis (conditions)
	const conditionMatch = (value ?? "").match(/\((.*)\)/);
	const condition = conditionMatch ? conditionMatch[0] : ""
	value = (value ?? "").replace(/ *\([^)]*\) */g, "");

	let variableNodes = [...nodes].filter(node => filterVariable(node, selectorFilters));
	let variableOutputs = variableNodes.map((node) => 
		node.outputValues.map((out) => 
			({ ...out, action: node.action, input: node.inputValues, id: node.id, idx: node.idx, output_count: node.outputValues.length }))).flat()
	let variableOptions = getOptions(variableOutputs, selectorFilters, acceptTypes, datatypes, "");

	let collectionNodes = [...collections].filter(node => filterCollection(selectorFilters))
	let collectionData = collectionNodes.map(collection => (
		{ ...collection, name: "@" + collection.name, type: "collection", "structure": collection.schema?.structure }))
	let collectionOptions = getOptions(collectionData, selectorFilters, acceptTypes, datatypes, "")


	// Add collection option
	if (filterCollection(selectorFilters) && includeAddCollection)
		collectionOptions.push({ label: "", value: "", type: "collection", category: "addCollection", basePath: "" });

	// Start parser
	const conditionState = parseConditionStrToState(condition)
	const options = [...variableOptions, ...collectionOptions];
	let state = [{ options: filterOptions(options, ""), condition: conditionState }];
	if (!value || value == "") return state

	const selections = String(value).split(".");
	if (selections[0] == "") return state

	const isCollection = selections[0].startsWith("@");
	const isVariable = selections[0].startsWith("#");

	// If is raw value
	if (!isCollection && !isVariable) {
		state[0].selectedOption = value
		return state
	}

	if (isCollection) {
		state[0].selectedOption = selections[0];
		const selection = selections[1] ?? ""
		const nextOptions = filterOptions(options, selections[0]);
		if (selections.length > 1)
			state.push({ options: nextOptions, selectedOption: selection });
		return state
	}

	if (isVariable) {
		state[0].selectedOption = selections[0];
		for (let idx = 1; idx < selections.length; idx++) {
			const basePath = selections.slice(0, idx).join(".");
			const selection = selections[idx] ?? "";
			const nextOptions = filterOptions(options, basePath);
			if (nextOptions.length > 0)
				state.push({ options: nextOptions, selectedOption: selection });
		}
		return state;
	}
	return state;

}

const parseStateToValue = (state) => {
	let selections = state.map(stateItem => stateItem?.selectedOption ?? "");
	if (state.length > 0 && state[0].condition){
		let conditionState = state[0].condition
		let conditionStr = parseConditionStateToStr(conditionState);
		if (conditionStr != "")
		selections[0] = `${selections[0]}(${conditionStr})`
	}
	return selections.join(".")
}

export { parseValueToState, parseStateToValue, parseConditionStateToStr, isComplexType }