import React from "react";
import { Formik, Field, Form } from "formik"
import { APP_URL } from "settings";
import { HelpComponent } from "components/util/schema_form/util/help_util";
import CopyClipboardButton from "components/util/helpers/CopyClipboardButton";


const FormFlowView = ({ 
  initialValues = {}, 
  flow = {},
  folders, 
  isEditing = false, 
  history, 
  onSubmit,
  onClickDelete = () => {},
}) => (
  <div className="card">

    <div class="card-header">
      <h3 class="card-header-title">
        {isEditing ? "Editar proceso" : "Crear proceso"}
      </h3>
    </div>

    <div className="card-body px-4">
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {({values, setFieldValue}) =>
          <Form>

            {/* Name */}
            <div class="form-group mb-4">
              <label class="input-label" for="name">Nombre del proceso</label>
              <Field type="text" name="name" id="name" autocomplete="off"
                class="form-control form-control" required autoFocus={true} />
            </div>

            {/* Name */}
            <div class="form-group mb-4">
              <label class="input-label" for="name">Descripción (Opcional)</label>
              <textarea 
                name="description" 
                id="description" 
                class="form-control form-control" 
                value={values.description}
                onChange={(e) => setFieldValue("description", e.target.value)}
              />
            </div>

            <div class="form-group mb-4">
              <label class="input-label" for="folder">Carpeta</label>
              <Field as="select" name="folder" id="folder"
                class="form-control form-control" required
                onChange={(e) => {
                  const val = e.target.value
                  if (val == "create") history.push("/create_folder")
                  else setFieldValue("folder", val)
                }}>
                <option value="0">Sin carpeta</option>
                {folders.map((folder) => (
                  <option key={folder.id} value={folder.id}>{folder.name}</option>
                ))}
                <option value="create">+ Crear carpeta</option>
              </Field>
            </div>

            <div class="form-group mb-4">
              <span 
                class="text-primary" 
                data-toggle="collapse" 
                href="#flow-advance-options" 
                role="button" 
                aria-expanded="false" 
                aria-controls="flow-advance-options"
              >
                Mostrar opciones avanzadas
              </span>
            </div>

            <div class="collapse" id="flow-advance-options">

              <div class="form-group mb-4">
                <div class="custom-control custom-checkbox">
                  <Field type="checkbox" name="show_animations" id="show_animations"
                    class="custom-control-input" />
                  <label class="custom-control-label" for="show_animations">
                    Mostrar animaciones
                  </label>
                </div>
              </div>

              <div class="form-group mb-4">
                <div class="custom-control custom-checkbox">
                  <Field type="checkbox" name="show_pagination" id="show_pagination"
                    class="custom-control-input" />
                  <label class="custom-control-label" for="show_pagination">
                    Mostrar paginación
                  </label>
                </div>
              </div>

              <div class="form-group mb-3">
                <div class="custom-control custom-checkbox">
                  <Field type="checkbox" name="is_published" id="is_published"
                    class="custom-control-input" />
                  <label class="custom-control-label" for="is_published">
                    Publicar proceso
                  </label>
                </div>
              </div>

              {
                flow.token && flow.settings?.is_published &&
                  <div className="mt-0 pt-0 mb-4">
                    <span class="d-flex justify-content-between dropdown-header px-0">
                      <span style={{ color: '#777' }}>Liga para compartir <HelpComponent helpMessage={"Liga "} />
                      </span>
                    </span>
                    <a href={`${APP_URL}/p/${flow.token}`}>
                      {`${APP_URL}/p/${flow.token}`}
                    </a>
                    <CopyClipboardButton link={decodeURIComponent(`${APP_URL}/p/${flow.token}`)} />
                  </div>
              }

            </div>
            
            <div className="row">
              <div className={`col-md-${(isEditing) ? "9 pr-0" : "12"}`}>
                <button type="submit" class="btn btn-block btn-primary">
                  {isEditing ? "Editar proceso" : "Crear proceso"}
                </button>
              </div>

              {(isEditing) && (
                <div className="col-md-3">
                  <button type="button" class="btn btn-block btn-outline-danger" onClick={onClickDelete}>
                    Eliminar
                  </button>
                </div>
              )}
            </div>

          </Form>}
      </Formik>
    </div>
  </div>
);

export default FormFlowView;