import React from "react";
import HelpMessage from "components/util/helpers/HelpMessage"


const TemplateCategory = ({ idx, category, isActive, onChangeProfile }) => (
    <HelpMessage delay={0} forceShow={true} style={{ marginTop: "-7px" }}
        message={category?.uiSettings?.display?.title} >
        <div className="mx-1" style={{ cursor: "pointer" }} onClick={() => onChangeProfile(category)}>
            <span className={`icon icon-circle ${isActive ? "icon-light text-primary" : "icon-primary text-white"}`}
                style={{ border: "1px solid #2080E5" }}>
                <i className={category?.uiSettings?.display?.icon}></i>
            </span>
        </div>
    </HelpMessage>
)

export default TemplateCategory;