/* eslint-disable max-len */
import React, { useState } from 'react';
import lodash from "lodash";
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import HelpMessage from 'components/util/helpers/HelpMessage';
import { Field } from "formik";


const helpMessages = [
    {
        name: "text",
        message: "Texto a encontrar en la imagen"
    },
    {
        name: "region",
        message: "Región rectangular para encontrar en la imagen, referente a la ubicación aproximada donde puede estar el objeto a encontrar"
    },
    {
        name: "phases",
        message: `
            <div>
                Fases que emplea el algoritmo para encontrar la muestra en la imagen, dichas fases se ejecutan de forma consecutiva
                <ul>
                    <li><b>Crop by region: </b>Recorta la imagen de acuerdo con los parámetros de región</li>
                    <li><b>Match by sample: </b>Obtiene los elementos que se asemejen a la muestra</li>
                    <li><b>Filter by text: </b>Filtra las coincidencias obtenidas según el parámetro de texto a encontrar</li>
                    <li><b>Expand matches by text: </b>Aumenta la región de coincidencia para incluir posible texto cortado</li>
                    <li><b>Filter by image similarity: </b>Filtra las coincidencias según su parecido con la muestra</li>
                </ul>
            </div>
        `
    },
    {
        name: "process_different_aspects",
        message: "Ejecutar el algoritmo usando diferentes aspectos de la muestra"
    },
    {
        name: "match_by_sample_threshold",
        message: "Umbral para la conincidencia con la muestra, mientras mas alto sea el valor se bucará mayor coincidencia"
    },
    {
        name: "filter_by_text_threshold",
        message: "Umbral para la conincidencia con el texto, mientras mas alto sea el valor se bucará mayor coincidencia"
    },
    {
        name: "expand_matches_by_text_delta",
        message: `<div>Valor en píxeles para configurar la fase <b>Expand matches by text</b></div>`
    },
    {
        name: "wait_after",
        message: "Tiempo de espera en segundos depues de ejecutar la acción"
    },
    {
        name: "offset_x",
        message: "Desplazamiento horizontal en píxeles de la acción desde el centro del elemento"
    },
    {
        name: "offset_y",
        message: "Desplazamiento vertical en píxeles de la acción desde el centro del elemento"
    },
    {
        name: "code",
        message: "Código para ser insertado en el script, debe respetar la sintaxis de robotframework"
    },
    {
        name: "scroll",
        message: "Realizar scroll al componente en caso de ser necesario"
    },
    {
        name: "scroll_direction",
        message: "Dirección del scroll a aplicar al componente, solo válido si scroll está activado"
    },
    {
        name: "apps",
        message: "La aplicación a la que corresponde la captura que se está procesando"
    },
    {
        name: "selection_type",
        message: "Tipo de input seleccionado en la imagen"
    },
    {
        name: "scroll_height",
        message: "Tamaño del scroll a realizar en pixeles"
    },
    {
        name: "stop_on_first",
        message: "Detener el scroll cuando encuentre una coincidencia"
    }
];


const HelpComponent = ({ helpMessage, icon="far fa-question-circle" }) =>
    <HelpMessage delay={0} message={helpMessage} style={{backgroundColor: '#e8ecf0'}} forceShow={true}>
        <i class={icon} style={{ color: "#c0c5d0", cursor: "pointer", marginLeft: "0.5rem" }}></i>
    </HelpMessage>


const CommentComponent = ({ name, setFieldValue, values }) => {

    // For multiple fields
    const regIdx = /\[[0-9]+\]$/g
    const commentName = name.replace(new RegExp(regIdx), "") + "_comment"
    const value = lodash.get(values, commentName)

    const [comment, setComment] = useState(value)
    const [overlayShow, setOverlayShow] = useState(false)

    const onSave = () => {
        setFieldValue(commentName, comment);
        setOverlayShow(false)
    }
    const onRemove = () => {
        setFieldValue(commentName, "")
        setOverlayShow(false)
    }

    return <OverlayTrigger key="bottom" placement="bottom" trigger="click"
        show={overlayShow}
        onToggle={(show) => setOverlayShow(show)}
        overlay={
            <Popover id="popover-positioned-right">
                <Popover.Body className="p-2"
                    style={{ backgroundColor: "#eaecf0", border: "1px solid #e0e5ea", color: "black", borderRadius: "10px" }}>
                    <div>
                        <Field as="textarea" value={comment} rows={4}
                            style={{ minWidth: "15rem" }}
                            onChange={e => setComment(e.target.value)}
                            class={"form-control"} />
                        <div className="d-flex justify-content-end mt-2">
                            <div className="bg-white mr-1">
                                <a className="btn btn-sm btn-outline-primary py-1" onClick={onSave}>Guardar</a>
                            </div>
                            <div className="bg-white">
                                <a className="btn btn-sm btn-outline-secondary py-1" style={{ border: "1px solid #b0b0b0" }} onClick={onRemove}>Quitar</a>
                            </div>
                        </div>

                    </div>
                </Popover.Body>
            </Popover>
        }
    >
        {
            value == null || value == "" ?
                <a className={"btn btn-sm btn-outline-secondary " + (overlayShow ? "active" : "")}>
                    <i class="far fa-comments mr-2 ml-1" style={{ color: "#c5cad5", cursor: "pointer" }}></i> Agregar comentario
                </a> :
                <a className={"btn btn-sm btn-accent " + (overlayShow ? "active" : "")}>
                    <i class="far fa-comment mr-2 ml-1" style={{ cursor: "pointer" }}></i> Comentarios
                </a>
        }
    </OverlayTrigger >
}

const getHelpMessage = (name) => {
    return helpMessages.find(item => item.name == name)?.message ?? "";
}

export { getHelpMessage, HelpComponent, CommentComponent }