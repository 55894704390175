import React, { useState, useEffect } from "react";
import { usePost } from "seed/api";
import View from "components/executions/ExecutionDetails.view";

function ExecutionDetails({ 
  idx, 
  execution, 
  style = {}, 
  isShown,
  isColumns,
  isButtonHidden,
  isActive,
  onSelectExecution,
  onResumeExecution, 
  onCancelExecution 
}) {

  const isDeveloperMode = sessionStorage.getItem("developer_mode") == "true";
  const randomKey = Math.random().toString(36).substring(7);
  const [callGetExecutions, reqExecutions] = usePost("/executions/get_executions");
  const [isDisplayed, setIsDisplayed] = useState(isShown);

  const { executions = [] } = reqExecutions.data || {};
  let details = null;
  if(executions.length > 0) details = executions[0];

  const onShowDetails = () => {
    if(onSelectExecution && isColumns) return onSelectExecution(execution);
    callGetExecutions({ execution_id: execution.id });
    setIsDisplayed(!isDisplayed);
  }

  useEffect(() => {
    if(isShown) 
      callGetExecutions({ execution_id: execution.id });
  }, [isShown]);

  return (
    <View
      idx={idx}
      randomKey={randomKey}
      execution={execution}
      details={details}
      style={style}
      isDeveloperMode={isDeveloperMode}
      isShown={isShown}
      isColumns={isColumns}
      isButtonHidden={isButtonHidden}
      isActive={(isColumns && isActive) || (!isColumns &&isDisplayed)}
      onResumeExecution={onResumeExecution}
      onCancelExecution={onCancelExecution}
      onShowDetails={onShowDetails}
    />
  );
}

export default ExecutionDetails;