import React from "react";
import PropTypes from "prop-types";
import VariableSelector from "components/util/schema_form/fields/variable_selector/VariableSelector";
import SchemaForm from "components/util/schema_form/SchemaForm";


const VariableSelectorModalView = ({ flowId, nodeId, onClickAddReference }) => (
  <div className="card">
    <div className="card-header">
      <h3 className="card-title">Selecciona una variable</h3>
    </div>

    <div className="card-body px-4 pt-5 pb-4">
      <SchemaForm
        schema={[{
          name: "reference",
          type: "VARIABLE",
          label: "Referencia"
        }]}
        submitText={"Agregar referencia"}
        flowId={flowId}
        nodeId={nodeId}
        onSubmit={onClickAddReference}
      />
    </div>
  </div>
)

VariableSelectorModalView.propTypes = {};

export default VariableSelectorModalView;