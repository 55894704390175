import React, {useState} from "react";
import { Formik, Form } from "formik";
import ValueView from "components/util/schema_form/fields/schema_builder/SchemaBuilder.sub.Value.view";
import "styles/css/util/schema_form/Form.css";

function SchemaBuilderView({ initialValues, defaultFieldValue, schemaFieldsPlaceholder, flowId, onSubmit }) {
  const [activeKey, setActiveKey] = useState("[0]")

  return <div class="pl-0 pr-4 pt-2 pb-2">

    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}>
      {({ values, setValues, setFieldValue }) =>
        <Form>
          <div class="mb-3">
            <ul className="step step-builder step-eicon-xs pl-4 pr-2">
              <ValueView
                values={values}
                flowId={flowId}
                activeKey={activeKey}
                defaultFieldValue={defaultFieldValue}
                placeholder={schemaFieldsPlaceholder}
                setFieldValue={setFieldValue}
                setValues={setValues}
                setActiveKey={setActiveKey}
                />
            </ul>
          </div>
          <button type="submit" class="btn btn-block btn-primary w-100 mt-4">Guardar</button>
        </Form>}
    </Formik>

  </div >;
}

export default SchemaBuilderView;