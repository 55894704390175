/* eslint-disable max-len */
import React from "react";
import * as util from "components/board/toolbox/util/toolbox";
import { CategoryItem, CollapseView } from "components/board/toolbox/actions/Actions.sub.NavHelpers.view";
import "styles/css/board/Toolbox.css";

const NavApps = ({
  apps,
  flowId,
  actionSearch,
  actionFilters,
  isDeveloper,
  onEnableApp,
  onDragStart,
  onClickAction
}) => {
  let hasFilters = actionFilters.length > 0;

  let groupIdx = 0
  return apps.map((app, idx) => {
    if (!app) return <div key={idx}></div>;
    const isPrevDifferentGroup = (idx > 0 && apps[idx].uiSettings?.group_label != apps[idx - 1].uiSettings?.group_label)
    const isNextDifferentGroup = (idx < apps.length && apps[idx].uiSettings?.group_label != apps[idx + 1]?.uiSettings?.group_label)
    const isEvenSection = groupIdx % 2 == 1 || isNextDifferentGroup

    const getCollapse = (app) => {
      const mainActions = util.getMainActions(app, actionSearch, actionFilters, isDeveloper)
      const hiddenActions = util.getHiddenActions(app, actionSearch, actionFilters, isDeveloper)

      return <CollapseView
        id={app.id}
        flowId={flowId}
        mainActions={mainActions}
        hiddenActions={hiddenActions}
        actionSearch={actionSearch}
        hasFilters={hasFilters}
        onEnableApp={onEnableApp}
        onClickAction={onClickAction}
        onDragStart={onDragStart}
      />;
    }

    const title =
      (idx == 0 || apps[idx].uiSettings?.group_label != apps[idx - 1].uiSettings?.group_label) && actionSearch == "" && !hasFilters ?
        <div className={"w-100 section-title pr-1 " + (idx == 0 ? "mt-0" : "")}>
          <label className="w-100 text-left">
            {apps[idx].uiSettings?.group_label}
          </label>
        </div> : null


    const category =
      <CategoryItem
        id={app.id}
        groupIdx={groupIdx}
        label={util.getAppName(app)}
        icon={app.uiSettings?.icon}
        actionSearch={actionSearch}
        hasFilters={hasFilters} />;

    const collapse = isEvenSection && !hasFilters && actionSearch == "" ?
      <div className="w-100">
        {!isPrevDifferentGroup ? getCollapse(apps[idx - 1]) : null}
        {getCollapse(app)}
      </div> : hasFilters || actionSearch != "" ?
        <div className="w-100">
          {getCollapse(app)}
        </div> : null

    groupIdx = !isNextDifferentGroup ? groupIdx + 1 : 0
    return [title, category, collapse]
  })
}


export default NavApps;