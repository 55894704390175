import React from "react";
import { StyleTag } from "seed/helpers";


function StyleColor({ user }) {

	let theme = JSON.parse(sessionStorage.getItem("theme") || "{}");

	let primaryColor = theme.primaryColor ?? "#2080e5";
	let accentColor = theme.accentColor ?? "#2065a5";
	let darkColor = theme.darkColor ?? getDarkerColor(primaryColor, -30);
	let lightColor = theme.lightColor ?? getDarkerColor(primaryColor, 30);
	

	if (user.email.includes("@chevez")) {
		primaryColor = "#007473"
		darkColor = "#00454d"
		accentColor = "#82cf6d"
		lightColor = "#007473"
	}
	else if (!theme.primaryColor || theme.primaryColor == "default") return;

	return <StyleTag content={`

		  /*
			** General components
			*/

			/* Background */

			#background-hero .path-a {
				fill: ${darkColor} !important;
			}
			#background-hero .path-b {
				fill: ${primaryColor} !important;
			}
			#background-hero .path-c {
				fill: ${accentColor} !important;
			}

			/* Buttons & Text */

			.btn.btn-primary {
				background: ${primaryColor} !important;
				border-color: ${primaryColor} !important;
			}
			.btn.btn-primary:hover {
				background: ${darkColor} !important;
			}
			.btn.btn-outline-primary {
				border-color: ${primaryColor} !important;
				color: ${primaryColor} !important;
			}
			.btn.btn-outline-primary:hover{
				background: ${primaryColor} !important;
				color: white !important;
			}
			.btn.btn-outline-primary-white {
				border-color: ${primaryColor} !important;
				color: ${primaryColor} !important;
			}
			.btn.btn-outline-primary-white:hover {
				background: ${primaryColor} !important;
				color: white !important;
			}
			.icon-primary {
				border-color: ${primaryColor} !important;
				background: ${primaryColor} !important;
			}
			.text-primary {
				color: ${primaryColor} !important;
			}
			.navbar-nav.nav-tabs .nav-link.active {
				border-left-color: ${primaryColor} !important;
			}
			.navbar .nav-link.active {
				color: ${primaryColor} !important;
			}			

			/* Icons */
			.process-icon-1 { fill:${primaryColor} !important;  }

			/* Pagination */

			.page-item.active .page-link {
				background: ${primaryColor} !important;
				border-color: ${primaryColor} !important;
			}

			.page-item .page-link:hover {
				background: ${darkColor} !important;
				color: white !important;
			}

			/*
			** Specific views
			*/

			/* Start */

			#start-templates {
				background: #ffffff !important;
			}

			#start .navbar-vertical-content .nav-link.active i, #start .navbar-vertical-content .nav-link.active span, 
			#start .navbar-vertical-content .nav-link:hover i, #start .navbar-vertical-content .nav-link:hover span{
				color: ${primaryColor} !important
			}

			#background-hero-template .path-a {
				fill: ${primaryColor}20 !important;
			}
			#background-hero-template .path-b {
				fill: ${primaryColor}20 !important;
			}
			#background-hero-template .path-c {
				fill: ${darkColor}20 !important;
			}

			/* Settings */

			#settings .navbar-vertical-content .nav-link.active i, #settings .navbar-vertical-content .nav-link.active span, 
			#settings .navbar-vertical-content .nav-link:hover i, #settings .navbar-vertical-content .nav-link:hover span{
				color: ${primaryColor} !important
			}


			/* Flow */
			
			#flow-tabs a.active, #flow-tabs a:hover{
				color: ${primaryColor} !important;
				border-bottom-color: ${primaryColor} !important;
			}
			
			#toolbox-sidenav {
				background: ${primaryColor} !important;
			}

			#toolbox-container #toolbox-main #toolbox-actions div.apps a.btn-category[aria-expanded=true]{
				background: ${primaryColor} !important;
			}
			

			/* Collection */			

			#collection-tabs .collection-tab.active {
				background: ${primaryColor} !important;
				color: white !important;
			}

			#collection-tabs .collection-tab:hover {
				background: ${darkColor} !important;
				color: white !important;
			}


			/* Presentation */

			exec-mode-tab.active, exec-mode-tab:hover{
				color: ${primaryColor} !important;
				border-bottom-color: ${primaryColor} !important;
			}

		` } />

}

function getDarkerColor(color, v) {

	const normalize = (x) =>
		x > 255 ? x - (x - 255) : x;

	const getHex = (x) => {
		if (x < 0 || isNaN(x)) x = 0;
		if (x > 255) x = 255;
		x = x.toString(16);
		return x.length === 1 ? "0" + x : x;
	}

	if (color.length > 6)
		color = color.substring(1, color.length)

	var rgb = parseInt(color, 16);

	var r = Math.abs(((rgb >> 16) & 0xFF) + v);
	r = normalize(r);
	r = getHex(r);

	var g = Math.abs(((rgb >> 8) & 0xFF) + v);
	g = normalize(g);
	g = getHex(g);

	var b = Math.abs((rgb & 0xFF) + v);
	b = normalize(b);
	b = getHex(b);

	return "#" + r + g + b;

}

export default StyleColor;