import React from "react";
import { Field } from "formik";
import BaseField from "components/util/schema_form/fields/BaseField";


const TextField = ({
    name,
    label,
    value,
    values,
    required,
    disabled,
    caption,
    helpMessage,
    textType,
    placeholder,
    enableComments,
    setFieldValue
}) => {
    return (
        <BaseField
            name={name}
            label={label}
            values={values}
            required={required}
            caption={caption}
            helpMessage={helpMessage}
            enableComments={enableComments}
            setFieldValue={setFieldValue}
        >
            <Field
                id={name}
                name={name}
                class={"form-control"}
                type={textType}
                autocomplete="off"
                placeholder={placeholder}
                required={required}
                disabled={disabled}
                style={{ paddingLeft: "1.2rem", paddingRight: "1.2rem" }}
                value={value??values[name]}
                onChange={(e) => setFieldValue(name, e.target.value)}
            />
        </BaseField>
    )
}

export default TextField;