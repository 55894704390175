import React, { useState } from "react";
import BaseField from "components/util/schema_form/fields/BaseField";
import { usePost } from "seed/api";
import { Field } from "formik";
import { Modal } from "seed/helpers";
import { downloadFile } from "components/util/file_util";

const ButtonField = ({
    name,
    label,
    value,
    values,
    actionId,
    flowId,
    nodeId,
    executionId,
    required,
    caption,
    helpMessage,
    sectionIdx,
    buttonType,
    buttonAction,
    buttonCallback,
    buttonFile,
    buttonText,
    buttonStyle,
    templateUrl,
    setFieldValue
}) => {

    const userId = sessionStorage.getItem("id");
    const [showPreview, setShowPreview] = useState(false);
    const [callCall, reqCall] = usePost(`/call/${actionId}/${buttonAction}`, {
        onCompleted: (data) => {
            if(buttonType == "call") setFieldValue(name, true);
            else if(buttonType == "preview") setFieldValue(name, data);
            else if(buttonType == "file") {
              if(Array.isArray(data)) data.forEach(file => downloadFile(file.url, null, file.name));
              else downloadFile(data.url, null, data.name);
            }
        }
    })

    const onClick = () => {

        if(buttonType == "link") {
            window.location.href = buttonAction;
            setFieldValue(name, true);
        }
        
        if(buttonType == "call")
            callCall({
                user_id: parseInt(userId),
                flow_id: parseInt(flowId),
                node_id: parseInt(nodeId),
                execution_id: parseInt(executionId),
                values: values,
            });

        if(buttonType == "preview") {
            callCall({
                user_id: parseInt(userId),
                flow_id: parseInt(flowId),
                node_id: parseInt(nodeId),
                values: values,
            });
            setShowPreview(true);
        }

        if(buttonType == "callback")
            buttonCallback(sectionIdx, values);

        if(buttonType == "template")
            downloadFile(templateUrl);

        if(buttonType == "file")
          callCall({
              user_id: parseInt(userId),
              flow_id: parseInt(flowId),
              node_id: parseInt(nodeId),
              execution_id: parseInt(executionId),
              button_file: buttonFile,
          });
            
    }

    let buttonClass = "btn-outline-primary";
    if(buttonStyle == "filled") buttonClass = "btn-primary";
    if(buttonStyle == "soft") buttonClass = "btn-soft-primary";
    if(buttonStyle == "link") buttonClass = "btn-link";

    return (
        <BaseField
            name={name}
            label={label}
            required={required}
            values={values}
            caption={caption}
            helpMessage={helpMessage}
            enableComments={false}
            setFieldValue={setFieldValue}
        >
            {showPreview && <Modal
                title={"Vista previa"}
                onClose={() => setShowPreview(false)}
                width={800}
                height={600}
                component={() => 
                    <div 
                        style={{
                            backgroundColor: "white",
                            width: "100%",
                            height: "100%",
                            overflowY: "auto",
                            padding: "20px"
                        }} 
                        dangerouslySetInnerHTML={{ __html: value }}
                    />
                }
            >
            </Modal>}
            {
                buttonType == "template" 
                ? <div className="card p-3">
                    <div className="text-muted mb-3">
                      INSTRUCCIONES
                    </div>
                    <div>
                      <span className="h5 text-primary mr-1">
                        Paso 1. 
                      </span> 
                      <span className="h5 font-weight-light">
                        Descargar la plantilla
                      </span>
                    </div>
                    <div>
                      <button type={"button"} className={"btn btn-sm " + buttonClass} onClick={onClick}>
                        Descargar
                      </button>
                    </div>
                    <div className="mt-3">
                      <span className="h5 text-primary mr-1">
                        Paso 2. 
                      </span> 
                      <span className="h5 font-weight-light">
                        Modifica el archivo
                      </span>
                    </div>
                    <div className="mt-2">
                      <span className="h5 text-primary mr-1">
                        Paso 3. 
                      </span> 
                      <span className="h5 font-weight-light">
                        Sube el archivo modificado
                      </span>
                    </div>
                  </div>
                : <button type={"button"} className={"btn btn-sm " + buttonClass} onClick={onClick}>
                    {reqCall.loading ? "Cargando..." : 
                    reqCall.error ? "Ha ocurrido un error, prueba de nuevo" : 
                    `${value && required ? "✓ " : ""} ${buttonText}`}
                  </button>
            }
            <Field type={"text"} name={name} required={required} style={{opacity: "0", width: "1px", position: "absolute", zIndex: "-1"}} />
        </BaseField>
    )
}

export default ButtonField;