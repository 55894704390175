import React, { useState } from "react";
import BaseField from "components/util/schema_form/fields/BaseField";
import { BlockPicker, TwitterPicker } from 'react-color'
import "styles/css/util/schema_form/ColorPicker.css";


function ColorPicker({
  name,
  label,
  value,
  required,
  caption,
  helpMessage,
  values,
  setFieldValue,
  defaultValue,
  openColor,
  colorOptions

}) {

  const [auxValue, setAuxValue] = useState({ hex: value || defaultValue });
  const [isColorPickerShown, setIsColorPickerShown] = useState(false);


  const onCloseColorPicker = () => {
    setIsColorPickerShown(false);
  }

  const onChangeColor = (color, _) => {
    const hexColor = color?.hex
    setFieldValue(name, hexColor)
    setAuxValue(color);
  }

  const onChangePreview = (color, _) => {
    setAuxValue(color);
  }

  const onCloseOpenPicker = () => {
    setIsColorPickerShown(!isColorPickerShown);
  }


  return (
    <div>
      <BaseField
        name={name}
        label={label}
        values={values}
        required={required}
        caption={caption}
        helpMessage={helpMessage}
        enableComments={false}
        setFieldValue={setFieldValue}
      >

        <div className="color-picker-preview" onClick={onCloseOpenPicker}>
          <div className="color" style={{ backgroundColor: auxValue?.hex }}></div>
        </div>

        {isColorPickerShown && (
          <div className="color-picker-popover">
            <div className="color-picker-close" onClick={onCloseColorPicker} />
            {openColor ?
              <BlockPicker color={auxValue} disableAlpha={true}
                onChange={onChangePreview} onChangeComplete={onChangeColor}
                presetColors={colorOptions.map(item => {
                  return { color: item.color, title: item.label };
                })}
              /> :
              <TwitterPicker
                color={auxValue}
                width="240px"
                onChange={onChangePreview}
                onChangeComplete={onChangeColor}
                colors={colorOptions.map(item => item.color)}
              />
            }
          </div>
        )}


      </BaseField>
    </div>
  );

}


export default ColorPicker;