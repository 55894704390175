import React from "react";
import { Link } from "react-router-dom";
import { ModalRoute } from "seed/helpers";
import AddAccount from "components/util/schema_form/fields/account_picker/AddAccount";

const ExternalAppsView = ({ integrationAccounts, integrations }) => (
  <div className="card" id="external-apps">
    <div id="external-apps-header" className="card-header">
      <h3 className="card-title">Aplicaciones externas</h3>
      <Link to="/settings/external_apps/add_account" className="btn btn-sm btn-primary">
        <i class="fas fa-plus mr-1"></i> Agregar cuenta
      </Link>
    </div>

    <div id="external-apps-body" className="card-body animate__animated animate__fadeIn animate__faster">
      {integrationAccounts.length > 0 ? (
        <ul id="integration-accounts" className="list-group">
          {integrationAccounts.map(integrationAccount => (
            <li key={integrationAccount.id} className="integration-account list-group-item mb-3 py-1">
              <div className="row align-items-center gx-2">
                {integrationAccount.integration.app.uiSettings && (
                  <div className="col-auto mr-2">
                    <i className={(integrationAccount.integration.app.uiSettings).icon ?? "fas fa-layer-group"}></i>
                  </div>
                )}
                <div className="col">
                  <div className="h5 mb-0">{integrationAccount.username}</div>
                  <small>{integrationAccount.integration.app.uiSettings.label ?? integrationAccount.integration.app.name}</small>
                </div>
                <div class="integration-settings btn-group dropup ml-auto">
                  <button type="button" class="btn btn-lighter text-center dropdown-toggle py-3 px-4 m-1"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                  >
                    <i class="fas fa-ellipsis-v"></i>
                  </button>
                  <div class="dropdown-menu">
                    <a class="dropdown-item" style={{ cursor: "pointer" }}>
                      <i class="tio-delete dropdown-item-icon"></i> Eliminar
                    </a>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <p>No hay aplicaciones externas configuradas.</p>
      )}
    </div>

    <ModalRoute
      path="/settings/external_apps/add_account"
      component={() => <AddAccount
        integrationNames={integrations} />}
      closeLink="/settings/external_apps"
    />

  </div>
);

export default ExternalAppsView;