import { events, eqMultiEvents, findValue } from "components/tmisc/apps/rpa/generator/util/events";

const FLOW_QUESTIONS = {
  "key_00": {
    "next": [
      {
        "id": "key_01",
        "condition": (answers, captures, index) => 
          findValue(captures[index], "ENTER")
      },
      {
        "id": "key_04",
        "condition": (answers, captures, index) => true
      }
    ]
  },
  "key_01": {
    "name": "EXECUTED_ACTION",
    "label": (answers, captures, index) => 
      "¿Al presionar \"ENTER\" enviaste información o ejecutaste alguna acción?",
    "component": "CHECKBOX",
    "options": {
      "true_label": "Sí, ejecuté una acción",
      "false_label": "No, solo es parte del texto",
    },
    "next": [
      {
        "id": "key_02",
        "condition": (answers, captures, index) => 
          answers["EXECUTED_ACTION"]
      },
      {
        "id": "key_03",
        "condition": (answers, captures, index) => 
          answers["EXECUTED_ACTION"] == false
      }
    ]
  },
  "key_02": {
    "definition": (answers, captures, index) => 
      "KEY"
  },
  "key_03": {
    "definition": (answers, captures, index) => 
      "TEXT"
  },
  "key_04": {
    "definition": (answers, captures, index) => 
      "KEY"
  },
};
  
export default FLOW_QUESTIONS;