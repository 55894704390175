import React, { useState, useEffect } from "react";
import { usePost } from "seed/api";
import BaseField from "components/util/schema_form/fields/BaseField";
import Table from "components/collections/Table";
import { formatNumber } from "components/collections/util/format";

const TableField = ({
  name,
	label,
	values,
	flowId,
	required,
	caption,  
	helpMessage,
  setFieldValue,
  dataSource,
  dataColumns,
}) => {

  const [query, setQuery] = useState("");
  const [filters, setFilters] = useState([]);
  const [search, setSearch] = useState("");
  const [pageNum, setPageNum] = useState(parseInt(sessionStorage.getItem("collection_page")) || 1);
  const pageSize = 10;

  const [callGetData, reqGetData] = usePost("/collections/get_data_by_query");
  
  const refreshData = () => callGetData({
    parent_query: dataSource,
    flow_id: flowId,
    search: search,
    query: query,
		page_size: pageSize,
		page_number: pageNum,
    ignore_compress: true
	});

  useEffect(() => {
    refreshData();
  }, [pageNum, dataSource, search, query]);

  const collection = reqGetData.data?.collection || {};
  const rows = reqGetData.data?.data || [];
  const columns = Object.assign([], collection.schema?.structure || [])
    .filter(c => dataColumns.length == 0 || dataColumns.includes(c.name));

  const totalPages = reqGetData.data?.total_pages || 0;
  const loading = reqGetData.loading;

  return (
      <BaseField
          name={name}
          label={label}
          required={required}
          values={values}
          caption={caption}
          helpMessage={helpMessage}
          enableComments={false}
          setFieldValue={setFieldValue}
      >
        <Table
          trackKeybindings={false}
          isReadOnly={true}
          useCustomFilters={true}
          useCustomPagination={true}
          useCustomSearch={true}
          showExport={false}
          showImport={false}
          showSearch={true}
          showFilters={true}
          checkValidation={true}
          search={search}
          filters={filters}
          query={query}
          pageNum={pageNum}
          pageSize={pageSize}
          totalPages={totalPages}
          values={rows}
          structure={columns}
          loading={loading}
          onChangeQuery={(value) => setQuery(value)}
          onChangeFilters={(value) => setFilters(value)}
          onChangeSearch={(value) => setSearch(value)}
          onPageChange={(value) => setPageNum(value)}
          folioComponent={(data, _) => {
            if (!data.folio) return;
            if (!collection) return <label className="text-center" style={{ minWidth: "2.85rem" }}>{data.folio}</label>;
            return <label className="text-center" style={{ minWidth: "2.85rem" }}>{collection.initials}-{formatNumber(data.folio)}</label>;
          }}
        />
      </BaseField>
  );

}

export default TableField;