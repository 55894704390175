import React from "react";
import { useDetail } from "seed/gql";
import { Loading } from "seed/helpers";
import View from "components/tmisc/settings/Panel.view";

function Panel() {

  const userId = sessionStorage.getItem("id");

  const reqUser = useDetail(`{
    user {
      firstName
      lastName
      email
      license { name }
    }
  }`, userId);

  if (reqUser.loading) return <PlaceholderView />;
  if (reqUser.error) return "Error";

  const { user = {} } = reqUser.data;
  const developerMode = sessionStorage.getItem("developer_mode") == "true";

  return <View 
    user={user} 
    developerMode={developerMode} 
  />;

}

const PlaceholderView = () =>
  <div class="card">
    <div class="card-body" style={{ padding: "10rem 0" }}>
      <Loading />
    </div>
  </div>

export default Panel;