import React, { useState } from "react";
import { useQuery } from "seed/gql";
import { Modal } from "seed/helpers";

const IdeasReport = () => {

  const [hiddenUsers, setHiddenUsers] = useState({});
  const [hiddenSuggestions, setHiddenSuggestions] = useState({});
  const [selectedPrompt, setSelectedPrompt] = useState(null);

  const qUsers = useQuery(`{
    suggestions {
      content
      user {
        id
        firstName
        lastName
        email
      }  
    }
  }`);

  const { suggestions = [] } = qUsers.data || {};

  const users = suggestions.reduce((acc, suggestion) => {
    
    const { user, content } = suggestion;

    if(!user) {

      const userIndex = acc.findIndex(u => u.id === null);

      if(userIndex === -1) 
        acc.push({
          id: null,
          firstName: "Sin usuario",
          suggestions: [{
            ...content,
            id: suggestion.id,
          }]
        });
      else 
        acc[userIndex].suggestions.push({
          ...content,
          id: suggestion.id,
        });

    }
    else {

      const userIndex = acc.findIndex(u => u.id === user.id);

      if(userIndex === -1)
        acc.push({
          id: user.id,
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          suggestions: [{
            ...content,
            id: suggestion.id,
          }]
        });
      else
        acc[userIndex].suggestions.push({
          ...content,
          id: suggestion.id,
        });

    }
    
    return acc;

  }, []);

  return (
    <div className="card card-body w-100 h-100" style={{ overflowY: "auto" }}>
      
      {
        users?.map((user) => 
          <div key={user.id} className="card my-2">
            <div className="card-header d-flex justify-content-between">
              <div>
                <h4>
                  {user.firstName} {user.lastName} 
                  <small>({user.email})</small>
                </h4>
              </div>
              <div>
                <button
                  className="btn btn-link"
                  onClick={() => setHiddenUsers({ ...hiddenUsers, [user.id]: !hiddenUsers[user.id] })}
                >
                  { hiddenUsers[user.id] ? "Mostrar" : "Ocultar" }
                </button>
              </div>                    
            </div>
            <div className="card-body" style={{ overflowY: "auto" }}>
              {
                !hiddenUsers[user.id] &&
                  <table className="table table-striped w-100" style={{ overflowY: "auto" }}>
                    <thead>
                      <tr>
                        <th>Input del usuario</th>
                        <th>Acciones sugeridas</th>
                        <th>Salida GPT</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        (user.suggestions??[]).map((suggestion, index) => 
                          <tr key={index}>
                              <td 
                                style={{ width: "15%" }}
                              >
                                {suggestion.description}
                              </td>
                              {
                                !hiddenSuggestions[suggestion.id] &&
                                  <>
                                    <td style={{ width: "30%" }}>
                                      {
                                        suggestion?.actions?.map((action, index) => 
                                          <div key={action.id}>
                                            <div>
                                              <b>{action.action}</b>
                                              {
                                                action.under_development
                                                  ? <span className="badge badge-success ml-2">En desarrollo</span>
                                                  : <span className="badge badge-info ml-2">Sugerida</span>
                                              }
                                            </div>
                                            <small>{action.description}</small>
                                          </div>
                                        )
                                      }
                                    </td>
                                    <td>
                                      {
                                        <div className="">
                                          <h5>{suggestion?.suggestion.title}</h5>
                                          <p>{suggestion?.suggestion.explanation}</p>
                                          <table 
                                            className="table table-sm w-100 border"
                                            style={{ overflowY: "auto" }}
                                          >
                                            <thead>
                                              <tr>
                                                <th>Paso</th>
                                                <th>Descripción</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {
                                                suggestion?.suggestion.steps?.map((step, index) =>
                                                  <tr key={index}>
                                                    <td style={{ width: "5%" }}>
                                                      {index + 1}
                                                    </td>
                                                    <td style={{ width: "95%" }}>
                                                      {step.description}
                                                    </td>
                                                  </tr>
                                                )
                                              }
                                            </tbody>
                                          </table>
                                        </div>
                                      }
                                    </td>
                                  </>
                              }
                              {
                                hiddenSuggestions[suggestion.id] &&
                                  <td colSpan="2">
                                    <div className="d-flex justify-content-between align-items-start">
                                      <div>
                                        <h5>{suggestion?.suggestion.title}</h5>
                                        <p>{suggestion?.suggestion.explanation}</p>
                                      </div>
                                    </div>
                                  </td>
                              }
                              <td>
                                <div className="d-flex">
                                  <button
                                    className="btn btn-link m-0 p-0"
                                    onClick={() => setHiddenSuggestions({ ...hiddenSuggestions, [suggestion.id]: !hiddenSuggestions[suggestion.id] })}
                                  >
                                    <i className={`fa fa-${hiddenSuggestions[suggestion.id] ? "eye-slash" : "eye"}`}></i>
                                  </button>
                                  <button 
                                    className="btn btn-link m-0 p-0 ml-2"
                                    onClick={() => setSelectedPrompt(suggestion?.suggestion_prompt)}
                                  >
                                    <i className="fa fa-file"></i>
                                  </button>
                                </div>
                              </td>

                          </tr>
                        )
                      }
                    </tbody>
                  </table>
              }
              {
                hiddenUsers[user.id] &&
                  <div className="text-center">
                    {user.suggestions?.length??0} ideas generadas
                  </div>
              }
            </div>
          </div>
        )
      }

      {
        selectedPrompt &&
          <Modal
            className="card card-body"
            width="800"
            height="600"
            onClose={() => setSelectedPrompt(null)}
          >
            {
              selectedPrompt &&
                <div className="w-100 h-100 px-2" style={{ overflowY: "auto" }}>
                  {
                    selectedPrompt?.split("\n").map((line, index) =>
                      <>
                        {
                          line.split("\t").map((part, index) =>
                            <span key={index} className="d-inline-block">{part}</span>
                          )
                        } 
                        <br />
                      </>
                    )
                  }
                </div>
            }
          </Modal>
      }

    </div>
  );
}

export default IdeasReport;