import React from "react";
import Chart from "components/util/schema_form/fields/dashboard/Chart";
import VariableSelector from "components/util/schema_form/fields/variable_selector/VariableSelector";


const DataGroupers = ({ activeGrouper, fieldName, onChangeGrouper }) => (

  <div className="my-3">

    <div class="form-group">
      <div class="custom-control custom-radio">
        <input type="radio" id={`id-${fieldName}-1`} class="custom-control-input" name={fieldName}
          onChange={() => onChangeGrouper("sum")} checked={activeGrouper == "sum"} />
        <label class="custom-control-label" for={`id-${fieldName}-1`}>Sumatoria (Sum)</label>
      </div>
    </div>

    <div class="form-group">
      <div class="custom-control custom-radio">
        <input type="radio" id={`id-${fieldName}-2`} class="custom-control-input" name={fieldName}
          onChange={() => onChangeGrouper("average")} checked={activeGrouper == "average"} />
        <label class="custom-control-label" for={`id-${fieldName}-2`}>Promedio (Average)</label>
      </div>
    </div>

    <div class="form-group">
      <div class="custom-control custom-radio">
        <input type="radio" id={`id-${fieldName}-3`} class="custom-control-input" name={fieldName}
          onChange={() => onChangeGrouper("count")} checked={activeGrouper == "count"} />
        <label class="custom-control-label" for={`id-${fieldName}-3`}>Conteo (Count)</label>
      </div>
    </div>

    <div class="form-group">
      <div class="custom-control custom-radio">
        <input type="radio" id={`id-${fieldName}-4`} class="custom-control-input" name={fieldName}
          onChange={() => onChangeGrouper("max")} checked={activeGrouper == "max"} />
        <label class="custom-control-label" for={`id-${fieldName}-4`}>Máximo (Max)</label>
      </div>
    </div>

    <div class="form-group">
      <div class="custom-control custom-radio">
        <input type="radio" id={`id-${fieldName}-5`} class="custom-control-input" name={fieldName}
          onChange={() => onChangeGrouper("min")} checked={activeGrouper == "min"} />
        <label class="custom-control-label" for={`id-${fieldName}-5`}>Mínimo (Min)</label>
      </div>
    </div>

  </div>
)



const ModalBuilderView = ({
  charts,
  flowId,
  activeChart,
  dashboardText,
  onChangeTitle,
  onSelectChart,
  onChangeXData,
  onChangeYData,
  onChangeChartType,
  onChangeXDataGrouper,
  onChangeYDataGrouper,
  onSaveDashboard
}) => (
  <div className="card">

    <div className="card-header">
      <h3>{dashboardText}</h3>
    </div>

    <div className="card-body">
      <div className="row d-flex align-items-stretch">

        <div className="col-md-5 d-flex">
          <div className="card flex-fill">
            <div className="card-body">

              <div class="form-group">
                <label for="chart-type" class="input-label">Titulo del gráfico</label>
                <input type="text" class="form-control" value={activeChart?.title ?? ""}
                  onChange={(event) => onChangeTitle(event.target.value)} />
              </div>

              <div class="form-group">
                <label for="chart-type" class="input-label">Tipo de grafico</label>
                <select className="custom-select" id="chart-type" value={activeChart?.type ?? ""}
                  onChange={(event) => onChangeChartType(event.target.value)}>
                  <option value="">Selecciona una opción</option>
                  <option value="bar">Barras</option>
                  <option value="line">Lineal</option>
                  <option value="pie">Circular</option>
                  <option value="scatter">Dispersión</option>
                  <option value="doughnut">Dona</option>
                </select>
              </div>

              <div class="form-group">
                <label for="x-data" class="input-label">Datos en X</label>
                <VariableSelector
                  name="x-data"
                  label=""
                  acceptTypes="all"
                  values={{
                    "x-data": activeChart?.data?.x?.value
                  }}
                  required={true}
                  flowId={flowId}
                  value={activeChart?.data?.x?.value}
                  onChange={onChangeXData}
                />
              </div>

              <div class="h4">
                <button className="btn btn-outline-primary" type="button" data-toggle="collapse" data-target="#data-groupers-x" aria-expanded="false" aria-controls="data-groupers-x">
                  Incluir agrupadores Datos en X <i class="fas fa-chevron-down ml-2"></i>
                </button>
              </div>
              <div class="collapse" id="data-groupers-x">
                <DataGroupers fieldName={"x-groupers"} activeGrouper={activeChart?.data?.x?.grouper ?? ""} onChangeGrouper={onChangeXDataGrouper} />
              </div>

              <div class="form-group mt-5">
                <label for="y-data" class="input-label">Datos en Y</label>
                <VariableSelector
                  name="y-data"
                  label=""
                  acceptTypes="all"
                  values={{
                    "y-data": activeChart?.data?.y?.value
                  }}
                  required={true}
                  flowId={flowId}
                  value={activeChart?.data?.y?.value}
                  setFieldValue={(name, value) => { }}
                  onChange={onChangeYData}
                />
              </div>

              <div class="h4">
                <button className="btn btn-outline-primary" type="button" data-toggle="collapse" data-target="#data-groupers-y" aria-expanded="false" aria-controls="data-groupers-y">
                  Incluir agrupadores Datos en Y<i class="fas fa-chevron-down ml-2"></i>
                </button>
              </div>
              <div class="collapse" id="data-groupers-y">
                <DataGroupers fieldName={"y-groupers"} activeGrouper={activeChart?.data?.y?.grouper ?? ""} onChangeGrouper={onChangeYDataGrouper} />
              </div>

            </div>
          </div>
        </div>

        <div className="col-md-7 d-flex">
          <div className="card flex-fill">
            <div className="card-body">
              <div className="row">
                {charts.map((chart, idx) => (
                  <div className="col-md-6" key={idx}>
                    <Chart
                      chart={chart}
                      isSelected={chart.id == activeChart?.id}
                      onSelectChart={onSelectChart}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <button className="btn btn-block btn-primary mt-4 mb-2" onClick={onSaveDashboard}>
        Guardar
      </button>
    </div>
  </div>
);

ModalBuilderView.propTypes = {};

export default ModalBuilderView;