import { MarkerType } from "reactflow";

const parseNodesDatabaseToUi = (nodes) => {

    const parsedNodes = [];
    nodes.forEach((node) => {
        let ui = node.uiSettings;
        const newNode = {
            id: `${node.id}-node`,
            createdAt: node.createdAt,
            remoteId: node.id,
            type: node.action.name == "node_annotation" ? "node_annotation" : "node",
            isAnnotation: false,
            position: ui.position,
            dragHandle: ".drag-handle-container",
            data: {
                flowId: node?.flow?.id,
                is_enabled: node.isEnabled,
                remoteId: node.id,
                action: {...node?.action},
                input_values: node.inputValues,
                output_values: node.outputValues,
                is_iterable: node.isIterable,
                iterable_field: node.iterableField,
                warnings: node?.warnings ?? [],
                errors: node?.errors ?? [],
                width: ui.width,
                height: ui.height,
            }
        };

        if (ui.width && ui.height && node.action.name == "node_annotation") {
            newNode["width"] = ui.width;
            newNode["height"] = ui.height;
            newNode["style"] = {
                width: ui.width,
                height: ui.height
            }
        }

        parsedNodes.push(newNode);
    })

    return parsedNodes;
}

const parseNodesUiToDatabase = (nodes) => {

    const parsedNodes = nodes.map((node) => {

        const values = {};
        for (const input of node.data.input_values) {
            values[input.name] = input.value
        }

        const extraAttributes = {
            is_enabled: node.data?.is_enabled,
            is_iterable: node.data?.is_iterable,
            iterable_field: node.data?.iterable_field,
            parent_id: node.data?.parent_id,
            position: node.data?.position,
            mode: node.data?.mode
        };

        const newNode = {
            id: node?.remoteId,
            action: node.data?.action?.id,
            ui: { 
                position: node?.position,
                width: node?.width,
                height: node?.height
            },
            values: values,
            extra_attributes: extraAttributes
        };

        return newNode;

    })

    return parsedNodes;
}

const parseEdgesDatabaseToUi = (edges) => {

    const parsedEdges = [];
    edges.forEach((edge) => {
        const source = `${edge.source.id}-node`;
        const target = `${edge.target.id}-node`;

        const newEdge = {
            id: `${edge.id}-edge`,
            type: "edge",
            createdAt: edge.createdAt,
            source: source,
            target: target,
            mode: edge.type,
            sourceHandle: edge.sourceName,
            targetHandle: edge.targetName,
            style: { strokeWidth: 0.4, stroke: "#111" },
            remoteId: edge.id,
            markerEnd: {
                type: MarkerType.ArrowClosed,
                width: 65,
                height: 65,
                color: "#333"
            },
        };

        parsedEdges.push(newEdge);
    })

    return parsedEdges;
}

const parseEdgesUiToDatabase = (edges) => {

    const parsedEdges = [];
    edges.forEach((edge) => {
        const newEdge = {
            id: edge?.remoteId,
            mode: edge.mode,
            source_id: edge.source.split("-")[0],
            target_id: edge.target.split("-")[0],
            source_name: edge.sourceHandle,
            target_name: edge.targetHandle
        }
        parsedEdges.push(newEdge)
    })
    return parsedEdges;
}

const parseAnnotationsDatabaseToUi = (annotations) => {

    const parsedAnnotations = [];
    annotations.forEach((annotation) => {
        let ui = annotation.uiSettings;
        const newAnnotation = {
            id: `${annotation.id}-annotation`,
            type: annotation.action.name,
            createdAt: annotation.createdAt,
            isAnnotation: true,
            position: ui.position,
            remoteId: annotation.id,
            dragHandle: ".drag-handle-container",
            data: {
                action: {...annotation?.action},
                input_values: annotation.inputValues,
                width: ui.width,
                height: ui.height,
            }
        };

        if (ui.width && ui.height) {
            newAnnotation["width"] = ui.width;
            newAnnotation["height"] = ui.height;
            newAnnotation["style"] = {
                width: ui.width,
                height: ui.height
            }
        }

        parsedAnnotations.push(newAnnotation);
    })

    return parsedAnnotations;

}

const parseAnnotationsUiToDatabase = (annotations) => {

    const parsedAnnotations = [];
    annotations.forEach((annotation) => {

        const values = {};
        for (const input of annotation.data.input_values) {
            values[input.name] = input.value
        }

        const newAnnotation = {
            id: annotation?.remoteId,
            action: annotation.data?.action?.id,
            ui: {
                position: annotation?.position,
                width: annotation?.width,
                height: annotation?.height
            },
            values: values
        };

        parsedAnnotations.push(newAnnotation);

    })

    return parsedAnnotations;

}

export {
    parseNodesDatabaseToUi,
    parseEdgesDatabaseToUi,
    parseNodesUiToDatabase,
    parseEdgesUiToDatabase,
    parseAnnotationsDatabaseToUi,
    parseAnnotationsUiToDatabase
};