import React from "react";
import { useEdges, useNodes } from 'reactflow';



function InsertChildren({ nodeId, isConditional = false, insertChildAttrs, onInsertChild }) {
	const nodes = useNodes();
	const edges = useEdges();

	const node = nodes.find(node => node.id == nodeId);
	const parentEdges = edges.filter(edge => edge.target == nodeId);
	const childEdges = edges.filter(edge => edge.source == nodeId);

	const defaultConnectionOrder = ["BOTTOM", "RIGHT", "LEFT", "TOP"]
	let allConnections = [
		{ "position": "TOP", "mode": "NEXT" },
		{ "position": "RIGHT", "mode": "NEXT" },
		{ "position": "BOTTOM", "mode": "NEXT" },
		{ "position": "LEFT", "mode": "NEXT" }
	]

	if (isConditional) {
		const parentEdge = parentEdges.length > 0 ? parentEdges[0] : null;
		if (parentEdge) {
			const parentPosition = parentEdge.targetHandle.split("_")[1].toUpperCase();
			if (parentPosition == "LEFT" || parentPosition == "RIGHT") {
				allConnections = [
					{ "position": "TOP", "mode": "ELSE" },
					{ "position": "BOTTOM", "mode": "NEXT" }
				]

			} else if (parentPosition == "TOP" || parentPosition == "BOTTOM") {
				allConnections = [
					{ "position": "LEFT", "mode": "ELSE" },
					{ "position": "RIGHT", "mode": "NEXT" }
				]

			}
		} else {
			allConnections = [];
		}
	}

	// Calc busy connections from parent nodes
	for (const edge of parentEdges) {
		if (edge.targetHandle.split("_").length != 2) continue;
		const position = edge.targetHandle.split("_")[1].toUpperCase();
		allConnections = allConnections.filter((connection) => connection.position != position)
	}

	// Calc busy connections from child nodes
	for (const edge of childEdges) {
		if (edge.sourceHandle.split("_").length != 2) continue;
		const position = edge.sourceHandle.split("_")[1].toUpperCase();
		allConnections = allConnections.filter((connection) => connection.position != position)
	}

	// Calc available connections
	const availableConnections = allConnections.map(connection => {
		return {
			"position": connection.position,
			"mode": connection.mode
		}
	})

	// Set default connection
	for (const connectionOrderPosition of defaultConnectionOrder) {
		const connection = availableConnections.find(connection => connection.position == connectionOrderPosition)
		if (connection) {
			connection.isDefault = true;
			break;
		}
	}

	const onClickConnection = (connection) => {
		const params = {
			"node_id": node.remoteId,
			"position": connection.position,
			"mode": connection.mode
		}
		onInsertChild(params);
	}

	
	return (
		<>
			{availableConnections.map((connection, idx) => (
				<div key={`insert-child-${idx}`}
					className={"insert-child-wrapper "
						+ (isConditional ? "conditional " : "")
						+ (insertChildAttrs?.position == connection.position ? "default active " : "")
						+ connection.position.toLowerCase()
					}>
					<div
						className={`insert-child`}
						onClick={() => onClickConnection(connection)}
					>
						<i class="fas fa-plus"></i>
					</div>
				</div>
			))}
		</>
	)

}

export default InsertChildren;