import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from 'react-router-dom';
import { useDetail } from "seed/gql";
import { Loading } from "seed/helpers";
import View from "components/board/node_form/NodeForm.view";


function EditNodeForm({ match, flowId, activePageId, ignoreSamplePicker = false, onSubmit }) {

  const nodeId = match?.params?.nodeId;
  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);
  const [loading, setLoading] = useState(true);
  const [isDetailsShown, setIsDetailsShown] = useState(searchParams.get("flip") == "true");

  const reqNode = useDetail(`{
    flowNode {
      inputValues
      outputValues
      action {
        name 
        inputStructure
        outputStructure
        uiSettings
        app {
          uiSettings
        }
      }
    }
  }`, nodeId, {
    onCompleted: () => {
      setLoading(false);
    },
    onError: () => history.replace(`/flow`)
  });

  useEffect(() => {
    reqNode.refetch();
  }, [nodeId])

  if (loading) return <PlaceholderView />;
  if (reqNode.error) return "Error";

  const node = reqNode.data?.flowNode ?? {};
  const action = node?.action ?? {};
  const initialData = {};
  for (const input of node?.inputValues ?? []) {
    initialData[input.name] = input.value;
  }

  const onSubmitForm = (values) => {
    onSubmit(values, nodeId);
  }

  const onDeleteNode = () => {
    onSubmit({}, nodeId, true);
  }

  const onOpenDetail = () => setIsDetailsShown(true);
  const onCloseDetail = () => setIsDetailsShown(false);


  return (
    <View
      node={node}
      action={action}
      flowId={flowId}
      activePageId={activePageId}
      initialData={initialData}
      isEditing={true}
      isDetailsShown={isDetailsShown}
      ignoreSamplePicker={ignoreSamplePicker}
      onSubmit={onSubmitForm}
      onDeleteNode={onDeleteNode}
      onOpenDetail={onOpenDetail}
      onCloseDetail={onCloseDetail}
    />
  );

}

const PlaceholderView = () =>
  <div className="card pb-10">
    <div className="card-header">
      &nbsp;
    </div>
    <div className="card-body py-10">
      <Loading />
    </div>
  </div>

export default EditNodeForm;