import React, { useRef } from "react";
import { useLocation } from "react-router-dom";
import swal from "sweetalert";
import { usePost, useGet } from "seed/api";
import { Loading } from "seed/helpers";
import View from "components/tmisc/auth/Signup.view";

function Signup() {

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const invitation = searchParams.get("invitation");
  const formikRef = useRef();

  const reqInvitation = useGet("/users/get_invitation_details",
    { "invitation": invitation },
    { includeAuth: false }
  );

  const [callSignup, reqSignup] = usePost("/users/signup", {
    onCompleted: (data) => {
      if (data) {
        sessionStorage.setItem("token", data.key);
        sessionStorage.setItem("id", data.user);
        sessionStorage.setItem("developer_mode", false);
        window.location.replace("/app")
      } else {
        swal("¡Registro exisoto!",
          "Bienvenido a Workix, tu aliado en tus procesos digitales, te hemos mandado un correo para verificar tu cuenta",
          "success");
        if (formikRef.current) {
          formikRef.current.setFieldValue("first_name", "");
          formikRef.current.setFieldValue("last_name", "");
          formikRef.current.setFieldValue("email", "");
          formikRef.current.setFieldValue("company", "");
          formikRef.current.setFieldValue("phone", "");
          formikRef.current.setFieldValue("password", "");
        }
      }
    },
    includeAuth: false
  });

  const onSubmit = (values) => {
    if (invitation != null) values.invitation = invitation;
    callSignup(values)
  }

  let requireVerification = false;
  if (reqInvitation.loading) return <Loading />;
  if (reqInvitation.error) requireVerification = true;

  const participant = reqInvitation.data;
  const initialValues = {}
  if (participant) initialValues.email = participant.email;

  const isLoading = callSignup.isLoading;
  const error = reqSignup.error ? "Usuario ya se encuentra registrado" : null;


  return (
    <View
      initialValues={initialValues}
      error={error}
      isLoading={isLoading}
      formikRef={formikRef}
      onSubmit={onSubmit}
    />
  );
}

export default Signup;