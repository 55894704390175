import { events, eqMultiEvents, findValue } from "components/tmisc/apps/rpa/generator/util/events";

const FLOW_QUESTIONS = {
  "text_multiple_00": {
    "name": "IS_TEXT_ENTER",
    "next": [
      {
        "id": "key_00",
        "condition": (answers, captures, index) =>
          eqMultiEvents(captures[index], events.TEXT_EVENT, events.KEY_EVENT) &&
          findValue(captures[index], "ENTER")
      }
    ]
  }
};

export default FLOW_QUESTIONS;