import React, { useState, useEffect } from "react";
import moment from "moment";
import { usePost } from "seed/api";
import { useSet } from "seed/gql";
import { SET_EXECUTION } from "seed/gql/queries";
import { addPostMessageListener } from "components/util/flow";
import View from "components/executions/ExecutionsList.view";

function ExecutionsList({ 
  flow = {}, 
  status, 
  rootId, 
  user_id, 
  placeholder = "No hay actividad reciente",
  isColumns = false,
  refetchParentExecutions = () => {},
}) {

  const [pageNum, setPageNum] = useState(1);
  const pageSize = 5;

  const [filterDate] = useState(moment().subtract(7, "days").format("YYYY-MM-DD"));
  const [called, setCalled] = useState(false);

  const [callGetExecutionDetail, reqExecutionDetail] = usePost("/executions/get_executions");

  const [callGetExecutions, reqExecutions] = usePost("/executions/get_executions", {
    onCompleted: () => setCalled(true)
  });

  const refetchExecutions = () => 
    callGetExecutions({
      status: status,
      flow_id: flow?.id,
      user_id: user_id,
      page_size: pageSize,
      page_num: pageNum,
      date: filterDate,
      root_id: rootId,
      include_steps: false
    });

  const [callResume] = usePost("/executions/resume_execution", {
    onCompleted: () => refetchExecutions()
  });

  const [callCancel] = usePost("/executions/cancel_execution", {
    onCompleted: () => refetchExecutions()
  });

  const [callSetRead] = useSet(SET_EXECUTION);

  useEffect(() => {
    refetchExecutions();
  }, [pageNum]);

  useEffect(() => {
    addPostMessageListener((data) => {
      if(data.type == "refetch-exec-mode-list")
        refetchExecutions();
    });
  }, []);

  const onSelectExecution = (execution) => 
    callGetExecutionDetail({ execution_id: execution.id });

  const onResumeExecution = (execution) =>
    callResume({ execution_id: execution.id });  

  const onCancelExecution = (execution) =>
    callCancel({ execution_id: execution.id });

  const isLoadingDetail = reqExecutionDetail.loading;
  const { executions = [], totalPages = 0 } = reqExecutions.data || {};
  let { executions: executionDetail = [] } = reqExecutionDetail.data || {};
  executionDetail = executionDetail.length > 0 ? executionDetail[0] : null;

  useEffect(() => {
    if (executions && executions.length > 0) {
      executions.filter((execution) => !execution.isRead).forEach((execution) => {
        callSetRead({ id: execution.id, isRead: true });
      });
      refetchParentExecutions();
    }
  }, [executions]);

  useEffect(() => {
    if(!executionDetail && executions.length > 0)
      onSelectExecution(executions[0]);
  }, [executions]);

  let sortedExecutions = executions.map((execution) => {

    let auxExecution = Object.assign({}, execution);
    auxExecution.executionSteps = Object.assign([], execution.executionSteps);
    auxExecution.executionSteps.sort((a, b) => {
      if (a.step < b.step) return -1;
      if (a.step > b.step) return 1;
      return 0;
    });

    return auxExecution;

  });

  if(reqExecutions.loading || !called)
    return <div className="d-md-block" style={{ padding: "0.72rem 0rem", minWidth: "285px" }}>
      <div className="d-flex justify-content-center">
        <div className="spinner-border text-primary" role="status">
        </div>
      </div>
    </div>

  return (
    <View
      executions={sortedExecutions}
      totalPages={totalPages > 5 ? 5 : totalPages}
      pageNum={pageNum}
      placeholder={placeholder}
      executionDetail={executionDetail}
      isColumns={isColumns}
      isLoadingDetail={isLoadingDetail}
      setPageNum={setPageNum}
      onSelectExecution={onSelectExecution}
      onResumeExecution={onResumeExecution}
      onCancelExecution={onCancelExecution}
    />
  );

}

ExecutionsList.propTypes = {};

export default ExecutionsList;