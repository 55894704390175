import React, { useState, useImperativeHandle, forwardRef, useEffect } from 'react';
import { useWS } from "seed/ws";

const WS = forwardRef((props, ref) => {

  const [room, setRoom] = useState(null);
  const { onMessageReceived, initialRoom, autoSuscribe = false } = props;

  useImperativeHandle(ref, () => ({
    suscribe: (url) => {
      setRoom(url);
    },
    unsuscribe: () => {
      setRoom(null);
    }
  }));

  useEffect(() => {
    if(autoSuscribe)
      setRoom(initialRoom);
  }, [initialRoom, autoSuscribe]);

  return room 
    ? <WSHook 
        room={room}
        onMessageReceived={onMessageReceived} 
      /> 
    : null;

});

const WSHook = ({ room, onMessageReceived }) => {
  useWS(room, {
    onMessageReceived: (message) => {
      console.log("WS message received", message);
      if (onMessageReceived) {
        onMessageReceived(message);
      }
    },
  });
}

WS.displayName = 'WS';

export default WS;