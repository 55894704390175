import React from "react";
import { HelpComponent } from "components/util/schema_form/util/help_util";


const getPlanName = (plan) => {
  if (plan == "BETA") return "Beta";
  if (plan == "BASIC") return "Básico";
  if (plan == "PRO") return "PRO";
  if (plan == "ENTERPRISE") return "Empresarial";
  return "";
}

const BillingView = ({ user, isDetailsShown, setIsDetailsShown, onRechargePoints }) => (
  <div id="billing" className="card">
    <div className="card-body animate__animated animate__fadeIn animate__faster">
      <div id="billing-container" className="card">

        <div className="card-body">
          <div className="row">

            <div id="plan-details" className="col-md mb-3 mb-md-0">

              <div className="mb-4">
                <p className="mb-1 h2 name">
                  <strong>Plan {getPlanName(user?.accountType)}</strong> incluye
                </p>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis, aliquam illo.
                  Inventore quam sequi molestias exercitationem quod porro illum temporibus qui voluptas!
                  Placeat, corporis doloremque. Corrupti error minus quo a?
                </p>
              </div>

              <span className="card-subtitle mb-0">Total al año</span>
              <h2 className="h1 text-primary">$250 USD</h2>

            </div>

            <ul className="list-unstyled col-md-auto">

              {user?.accountType == "PRO" && (
                <li className="list-item mb-2">
                  <button className="btn btn-block btn-white">Cancelar plan</button>
                </li>
              )}

              {(user?.accountType == "BETA" || user?.accountType == "BASIC") && (
                <li className="list-item mb-2">
                  <button className="btn btn-block btn-primary">Actualizar a PRO</button>
                </li>
              )}
            </ul>

          </div>
        </div>

        <hr className="my-3" />

        <div className="card-body">

          <div class="row align-items-center flex-grow-1 mb-2">
            <div class="col">
              <h4 class="card-header-title">Créditos usados</h4>
            </div>

            <div class="col-auto">
              <span class="font-weight-bold text-dark">250</span> usados de 500
            </div>
          </div>

          <div class="progress rounded-pill mb-3">
            <div class="progress-bar w-50" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
          </div>

          <div className="d-flex justify-content-between">
            <a className="btn btn-primary py-1 px-2" onClick={onRechargePoints}>
              Recargar
            </a>
            <a className="btn btn-secondary py-1 px-2" data-toggle="collapse" href="#creditDetails"
              role="button" aria-expanded="false" aria-controls="creditDetails"
              onClick={() => setIsDetailsShown((prev) => !prev)}
            >
              {isDetailsShown ? "Ocultar" : "Mostrar"} desglose
            </a>
          </div>

        </div>

        <div className="collapse" id="creditDetails">
          <hr className="my-3" />
          <div className="card-body">
            <ul class="list-group list-group-flush">
              <li class="list-group-item">
                <i class="fas fa-brain list-group-icon"></i> Análisis sentimental
                <HelpComponent helpMessage={`Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis, aliquam illo.
                  Inventore quam sequi molestias exercitationem quod porro illum temporibus qui voluptas!
                  Placeat, corporis doloremque. Corrupti error minus quo a?`} />
                <div class="progress rounded-pill my-3">
                  <div class="progress-bar" role="progressbar" style={{ width: "20%" }}
                    aria-valuenow="20" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </li>
              <li class="list-group-item">
                <i class="fas fa-copy list-group-icon"></i> Clasificación de documentos
                <HelpComponent helpMessage={`Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis, aliquam illo.
                  Inventore quam sequi molestias exercitationem quod porro illum temporibus qui voluptas!
                  Placeat, corporis doloremque. Corrupti error minus quo a?`} />
                <div class="progress rounded-pill my-3">
                  <div class="progress-bar" role="progressbar" style={{ width: "20%" }}
                    ria-valuenow="20" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </li>
              <li class="list-group-item">
                <i class="fas fa-copy list-group-icon"></i> Extracción de datos de documentos
                <HelpComponent helpMessage={`Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis, aliquam illo.
                  Inventore quam sequi molestias?`} />
                <div class="progress rounded-pill my-3">
                  <div class="progress-bar" role="progressbar" style={{ width: "10%" }}
                    aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </li>
            </ul>
          </div>
        </div>

      </div>
    </div>
  </div>
);

export default BillingView;