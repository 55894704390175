import React, { useState, useRef } from "react";
import { useDetail, useQuery } from "seed/gql";
import { usePost } from "seed/api";
import View from "components/collections/forms/Form.view";

function CollectionFormSet({ 
  flowId, 
  collectionId, 
  onSave = () => {}, 
  closeModal = () => {} 
}) {

  const userId = sessionStorage.getItem("id");
  const formRef = useRef(null);
  const [error, setError] = useState(null);

  const reqPlayer = useDetail(`{
    collection {
      name
      initials
      schema
    }
  }`, collectionId);

  const [callSet, reqSet] = usePost("/collections/update_collection", {
    onCompleted: (data) => {
      onSave(data);
      closeModal();
    },
    onError: (error) => {
      if(error.status == 409) setError("Ya existe una tabla con ese identificador");
      else setError("Ocurrió un error al crear la tabla");
    }
  });

  const qCollections = useQuery(`{
		collections {
			id
			name
			initials
		}
	}`, "flowId=" + flowId);

  const onSubmit = (values) => {

    delete values.id;
    delete values.schema;
    let structure = values.structure;

    callSet({
      ...values,
      flow_id: flowId,
      user_id: parseInt(userId),
      collection_id: collectionId,
      structure,
    });

  }

  const { collection = {} } = reqPlayer.data || {};
  const { collections = [] } = qCollections.data || {};
  let structure = collection?.schema?.structure || [];
  structure = structure.map((s) => ({
    initial: s,
    final: s,
    internal: s.internal,
    action: "none"
  }));

  return <View
    formRef={formRef}
    error={error}
    loading={reqSet.loading}
    collection={collection}
    collections={collections}
    initialColumns={structure}
    onSubmit={onSubmit}
  />;

}

export default CollectionFormSet;