import React from "react";

const IdeasListType = {
  GENERATED: "GENERATED",
  SAVED: "SAVED",
  HISTORY: "HISTORY",
};

const IdeasList = ({
  type,
  suggestions,
  onChangeSuggestion = () => { },
  onSelectSuggestion = () => { },
}) => {

  return (
    <div className={`mt-2 mb-3 ${type == IdeasListType.GENERATED ? "animated animate__fadeIn animate__faster" : ""}`}>

      {
        function () {
          return <>
            <span  class="text-decoration-none text-dark p-0 m-0 w-100 d-flex justify-content-between">
              <h6  className="text-muted mt-1">
                {
                  function () {
                    switch (type) {
                      case IdeasListType.GENERATED:
                        return "Ideas";
                      case IdeasListType.SAVED:
                        return "Ideas guardadas";
                      case IdeasListType.HISTORY:
                        return "Historial de ideas";
                      default:
                        return "";
                    }
                  }()
                }
              </h6>
            </span>
          </>
        }()
      }

      {
        suggestions.length == 0 &&
        <div className="text-start text-muted">
          {
            function () {
              switch (type) {
                case IdeasListType.GENERATED:
                  return "No se encontraron ideas";
                case IdeasListType.SAVED:
                  return "No se encontraron ideas guardadas";
                case IdeasListType.HISTORY:
                  return "No se encontraron ideas en el historial";
                default:
                  return "";
              }
            }()
          }
        </div>
      }

      <div>
        {
          suggestions.map((s, index) => (
            <div 
              key={index} 
              className="border border-radius py-2 px-3 mb-2 rounded"
              style={{ cursor: "pointer" }}
              onClick={() => onSelectSuggestion(s)}
            >
              {
                s.type == "SUGGESTION" &&
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex flex-column pr-2">
                      <div>
                        {s.response.title}
                      </div>
                      {
                        type == IdeasListType.HISTORY &&
                        <div  className="text-muted">
                          <small>
                            {s.description}
                          </small>
                        </div>
                      }
                    </div>
                    <div  className="d-flex align-items-center">
                      <button
                        className="btn btn-sm btn-outline-primary mr-2"
                        onClick={() => onSelectSuggestion(s)}
                      >
                        Ver
                      </button>
                      <span>
                        <i
                          className={`fa-star p-1 ${s.isFavorite ? "fas text-warning" : "far text-muted"}`}
                          style={{ cursor: "pointer" }}
                          onClick={(e) => { e.stopPropagation(); onChangeSuggestion({ id: s.id, isFavorite: !s.isFavorite }); }}
                        />
                      </span>
                      <span>
                        <i
                          
                          className="fas fa-trash-alt text-danger ml-2 p-1"
                          style={{ cursor: "pointer" }}
                          onClick={(e) => { e.stopPropagation(); onChangeSuggestion({ id: s.id, isShown: false }); }}
                        />
                      </span>
                    </div>
                  </div>
              }
            </div>
          ))
        }
      </div>

    </div>
  );

}

export {
  IdeasList,
  IdeasListType
}