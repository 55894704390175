const getStructure = (structure = [], appdatatypes = []) => {

  const finalStructure = [];

  if(Array.isArray(structure))
    structure?.forEach((output) => {

      const { name, value, label, type, structure = [] } = output;
      let auxStructure = { name, label, type, value, structure };
      auxStructure.label = label ?? name;

      const datatype = appdatatypes?.find(datatype => datatype?.name == type);
      if(datatype) auxStructure.structure = datatype?.structure;
      if(auxStructure?.structure) auxStructure.structure = getStructure(auxStructure?.structure, appdatatypes);

      finalStructure.push(auxStructure);

    });

  return finalStructure;

}

const parseData = (structure, values, isInput = false) => {

  const parseDictValues = () => {

    let finalValues = {};

    Object.keys(values).forEach((key) => {

      let value = values[key];

      const structureItem = structure?.find(item => {

        let structureKey =  "";

        if(!isInput && typeof item?.value == "string" && item?.value?.startsWith("#")) 
          structureKey = item.value.slice(1);
        else 
          structureKey = item?.name;

        return structureKey == key;

      });

      if(!structureItem)
        return finalValues[key] = value;

      if(structureItem?.structure) 
        value = parseData(structureItem?.structure, value, isInput);

      finalValues[structureItem.label] = value;

    });

    return finalValues;

  }

  if(Array.isArray(values))
    return values.map((item) => parseData(structure, item));
  else if(values instanceof Object)
    return parseDictValues();

  return values;

}

const parseInput = (inputValues, appdatatypes, input) => {
  const structure = getStructure(inputValues, appdatatypes);
  return parseData(structure, input, true);
}

const parseOutput = (outputValues, appdatatypes, result) => {
  const structure = getStructure(outputValues, appdatatypes);
  return parseData(structure, result);
}

export { parseInput, parseOutput };