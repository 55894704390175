import React, { useState } from "react";
import { useQuery, useDelete, useDetail, useSet } from "seed/gql";
import { usePost } from "seed/api";
import { Loading } from "seed/helpers";
import { SET_FLOW, DELETE_PARTICIPANT } from "seed/gql/queries";
import View from "components/studio/forms/collaboration/Collaboration.view";


function Collaboration({ flowId }) {

  const userId = sessionStorage.getItem("id");
  const isDeveloper = sessionStorage.getItem("developer_mode") == "true";
  const [users, setUsers] = useState([]);
  const [shareUsers, setShareUsers] = useState([]);
  const [shareRole, setShareRole] = useState("EDITOR");
  const [currentInputValue, setCurrentInputValue] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(true);
  const roleOptions = [
    { "label": "Editor", "value": "EDITOR" },
    { "label": "Lector", "value": "VIEWER" }
  ];

  const reqUsers = useQuery(`{
    users {
      email
      firstName
      lastName
      username
      participants {
        flow { name }
      }
    }
  }`, `id <> ${userId} AND isAnonymous=False`, {
    onCompleted: (data) => {
      const auxUsers = data?.users ?? [];
      const filteredUsers = auxUsers.filter((user) => {
        const participations = user?.participants ?? [];
        if (participations.find((participation) => participation.flow.id == flowId)) {
          return false;
        }
        return true;
      })
      setUsers(filteredUsers);
    }
  })

  const reqParticipants = useQuery(`{
    participants {
      type
      email
      user {
        email
        firstName
        lastName
        username
      }
    }
  }`, `flow.id = ${flowId}`)

  const reqFlow = useDetail(`{
     flow {
       name
       token
       settings
     } 
    }`, flowId)

  const [callDelete, reqDelete] = useDelete(DELETE_PARTICIPANT, {
    onCompleted: () => {
      reqUsers.refetch();
      reqParticipants.refetch();
    },
    onError: (err) => {
      console.log(err);
    }
  })

  const [callShare, reqShare] = usePost("/flows/share", {
    onCompleted: () => {
      reqUsers.refetch();
      reqParticipants.refetch();
    },
    onError: (err) => {
      console.log(err)
    }
  })

  const [callSet] = useSet(SET_FLOW);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const onInputChange = (event, value) => {
    setCurrentInputValue(value);
    const isValid = validateEmail(value)
    if (value == "") setIsEmailValid(true);
    else setIsEmailValid(isValid);
  }

  const onChangeShareUsers = (event, newUsers) => {
    if (isEmailValid) setShareUsers(newUsers);
  }

  const onChangeShareRole = (newRole) => {
    setShareRole(newRole);
  }

  const onClickShare = () => {
    if (shareUsers.length > 0 && ["EDITOR", "VIEWER"].includes(shareRole)) {
      setShareUsers([])
      callShare({
        role: shareRole,
        emails: shareUsers,
        flow_id: flowId
      })
    }
  }

  const onChangeParticipantRole = (userId, newRole) => {
    if (["EDITOR", "VIEWER"].includes(newRole)) {
      callShare({
        role: newRole,
        users: [userId],
        flow_id: flowId
      })
    }
  }

  const onRemoveParticipant = (participantId) => {
    callDelete({ id: participantId });
  }

  const onChangePublish = (value) => 
    callSet({ id: flowId, settings: { ...flow.settings, is_published: value } });

  if (reqUsers.loading || reqParticipants.loading || reqFlow.loading) return <Loading />;
  if (reqUsers.error || reqParticipants.error || reqFlow.error) return <div />;

  const { participants = [] } = reqParticipants.data;
  const { flow = {} } = reqFlow.data

  return (
    <View
      flow={flow}
      users={users}
      shareUsers={shareUsers}
      shareRole={shareRole}
      currentInputValue={currentInputValue}
      participants={participants}
      roleOptions={roleOptions}
      isDeveloper={isDeveloper}
      isEmailValid={isEmailValid}
      onChangeShareUsers={onChangeShareUsers}
      onChangeShareRole={onChangeShareRole}
      onClickShare={onClickShare}
      onChangeParticipantRole={onChangeParticipantRole}
      onRemoveParticipant={onRemoveParticipant}
      onInputChange={onInputChange}
      onChangePublish={onChangePublish}
    />
  );
}

export default Collaboration;