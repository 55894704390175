
const getAppName = (app) => {
    const appUiSettings = (app.uiSettings);
    return appUiSettings?.label ?? app.name;
}

const getActionName = (action) => {
    if (!action.uiSettings) return "";
    let actionSettings = (action.uiSettings);
    if (actionSettings?.dummy == true) return "Dummy node";
    else return actionSettings?.general?.title ?? "";
}

const getMainActions = (app, actionSearch, actionFilters, isDeveloper) =>
    getActions(app, actionSearch, actionFilters, isDeveloper)
        .filter(action => !(action.isHidden || action.developerOnly || action.underDevelopment) || (actionSearch != "" || actionFilters.length > 0))
        .map(action => ({ ...action, app: app }))

const getHiddenActions = (app, actionSearch, actionFilters, isDeveloper) =>
    getActions(app, actionSearch, actionFilters, isDeveloper)
        .filter(action => (action.isHidden || action.developerOnly || action.underDevelopment) && (actionSearch == "" && !actionFilters.length > 0))
        .map(action => ({ ...action, app: app }))



const searchCondition = (app, action, actionSearch) =>
    actionSearch == "" ||
    getActionName(action).toLowerCase().includes(actionSearch.toLowerCase()) ||
    getAppName(app).toLowerCase().includes(actionSearch.toLowerCase()) ||
    (action.uiSettings.general?.keywords ?? []).toString().toLowerCase().includes(actionSearch.toLowerCase()) ||
    (app.uiSettings.keywords ?? []).toString().toLowerCase().includes(actionSearch.toLowerCase())

const filterCondition = (action, actionFilters) =>
    !actionFilters.length > 0 ||
    (action.uiSettings.general?.categories ?? []).find(category => actionFilters.includes(category))

const getActions = (app, actionSearch, actionFilters, isDeveloper) => {
    return app.actions
        .filter((action) =>
            searchCondition(app, action, actionSearch) &&
            filterCondition(action, actionFilters) &&
            ((!action.developerOnly && !action.underDevelopment) || isDeveloper))
        .sort((a, b) => a.relevance - b.relevance)
}

export { getActionName, getAppName, getMainActions, getHiddenActions };