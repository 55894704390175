import React, { useState, useEffect } from "react";
import { useQuery } from "seed/gql";
import View from "components/tmisc/settings/ExternalApps.view";

function ExternalApps() {

  const [integrationAccounts, setIntegrationAccounts] = useState([]);
  const [integrations, setIntegrations] = useState([]);

  const userId = sessionStorage.getItem("id");

  const reqIntegrationAccounts = useQuery(`{
    integrationAccounts{
      status
      username      
      user{
        id
      }
      integration{
        app{
          name
          uiSettings
        }
      }
    }
  }`, "user.id", userId);

  const reqIntegrations = useQuery(`{
    integrations{
      name
    }
  }`);

  useEffect(() => {
    if (reqIntegrationAccounts.data.integrationAccounts && reqIntegrations.data.integrations) {
      setIntegrationAccounts(reqIntegrationAccounts?.data?.integrationAccounts ?? []);
      setIntegrations(reqIntegrations?.data?.integrations.map(integration => integration.name) ?? []);
    }
  }, [reqIntegrationAccounts.loading, reqIntegrations.loading]);

  return (
    <View
      integrationAccounts={integrationAccounts}
      integrations={integrations}
    />
  );
}

export default ExternalApps;