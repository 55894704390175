/* eslint-disable max-len */
import React from "react";
import { getFlowName } from "components/util/flow";
import Slider from "react-slick";
import { APP_URL } from "settings";


const NodeFormIdeasView = ({ action, flowId, sampleFlows, isDetailsShown, onCloneCase, onCloseDetail, onOpenDetail }) => (
  <>
    <div className="card-header bg-primary">
      <div className="w-100">
        <div className="d-flex justify-content-between align-items-center">
          <h3 className="node-form-title mb-0">
            <label>{action.uiSettings.form?.title ?? action.uiSettings?.general?.title}</label><br />
            <span className="caption">
              {action.uiSettings?.form?.caption ?? action.uiSettings?.general?.caption ?? ""}
            </span>
          </h3>
          {/* Flip icon to show detils about the action */}
          <div className="mr-4">
            <i class="fa-solid fa-reply-all" id="flip-details-icon"
              onClick={() => isDetailsShown ? onCloseDetail() : onOpenDetail()}
            ></i>
          </div>
        </div>
      </div>
    </div>

    <div className="card-body">

      <div id="flow-samples">
        <Slider
          dots={false}
          infinite={sampleFlows.length > 1}
          speed={800}
          slidesToShow={1}
          slidesToScroll={1}
        >
          {sampleFlows.map((sampleFlow) => (
            <div className="card bg-primary" key={sampleFlow.id}>
              <div class="card-body">
                <div className="row">
                  <div className="col-lg-12 mb-3 mb-lg-0">
                    <h4 className="sample-flow-title mb-3">
                      {getFlowName(null, sampleFlow)}
                    </h4>

                    <p className="sample-flow-description">
                      <div dangerouslySetInnerHTML={{ __html: sampleFlow.templateSettings?.usage?.description }}></div>
                    </p>
                  </div>

                  <div className="col-lg-12">

                    <iframe className="w-100" style={{ height: "25rem" }} 
                      src={`${APP_URL}/flow_preview/${sampleFlow.id}`}
                      referrerpolicy="same-origin" title="website"
                    />

                    <div className="d-flex w-100 align-items-center justify-content-center">
                      <button type="button" className="btn btn-block insert-case-button" onClick={(event) => onCloneCase(event, sampleFlow.id)}>
                        Utilizar idea
                      </button>
                    </div>


                  </div>
                </div>

              </div>
            </div>
          ))}
        </Slider>
      </div>

    </div>
  </>
)

NodeFormIdeasView.propTypes = {};

export default NodeFormIdeasView;