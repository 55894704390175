import React, { useState, useEffect } from "react";
import { useQuery } from "seed/gql";
import { Loading } from "seed/helpers";
import { assignIdxNodes } from "components/studio/Controller.lib.index"
import * as parser from "components/studio/Controller.lib.parser";
import * as lib from "components/util/schema_form/fields/variable_selector/VariableSelector.lib";
import View from "components/util/schema_form/fields/variable_selector/VariableSelector.view";

function VariableSelector({
  acceptTypes = "all",
  openText = false,
  enableAttributes = true,
  placeholder = "Selecciona una referencia del proceso",
  textType = "text",
  selectorFilters = [],
  nodeId,
  name,
  label,
  required,
  disabled,
  caption,
  helpMessage,
  showAdvancedOptions = false,
  showAcceptButton = false,
  showTitleOption = false,
  showColumnsOption = false,
  showViewOption = false,
  showEditableOption = false,
  showFiltersOption = false,
  showCollectionAddButton = false,
  showCollectionCondition = true,
  saveCollectionColumns,
  displayAttributesManually = false,
  value,
  flowId,
  values,
  setFieldValue = () => { },
  onSaveCollectionSuggestion = () => { },
  onChange = () => { },
}) {

  const pageId = sessionStorage.getItem("page_" + flowId)
  const [isAdvancedOptionsShown, setIsAdvancedOptionsShown] = useState(false);
  const [showOptionsWhenEmpty, setShowOptionsWhenEmpty] = useState(!openText);
  const [showModal, setShowModal] = useState(false);
  const [pendingCollection, setPendingCollection] = useState(null);
  const [showCondition, setShowCondition] = useState(false);
  const conditionTempName = Math.floor(Math.random() * 100000000)

  const queries = [];
  queries.push(`flow.id=${flowId}`);
  if (pageId) queries.push(`page.id=${pageId}`);

  const reqNodes = useQuery(`{
    flowNodes {
      createdAt
      inputValues
      outputValues
      uiSettings
      isEnabled
      action { 
        name
        type
        isEditable
        inputStructure
        outputStructure
        uiSettings
        isAnnotation
        app { 
          name
          uiSettings
        }
      }
      isVerified
      isIterable
      iterableField
      flow { name }
    }
  }`, queries.join(" AND "))

  const reqEdges = useQuery(`{
    flowEdges {
      type
      createdAt
      sourceName
      targetName
      source { }
      target { }
      flow { name }
    }
  }`, `flow.id=${flowId} ${pageId ? "AND page.id=" + pageId : ""}`);

  const reqCollections = useQuery(`{
    collections {
      name
      schema
    }
  }`, `flow.id=${flowId}`, { orderBy: "name" })

  const reqDataTypes = useQuery(`{
    appDatatypes {
      name
      structure
    }
  }`, '')

  useEffect(() => {
    if (value == null || (typeof value === 'object' && Object.keys(value).length === 0))
      setFieldValue(name, "")
    reqNodes.refetch();
    reqCollections.refetch();
    reqDataTypes.refetch();
  }, [flowId, nodeId]);

  const onClickArrow = () => setShowOptionsWhenEmpty(true);
  const onClickAddCollection = () => setShowModal(true)
  const onCloseCollectionModal = () => setShowModal(false)
  const onSaveCollection = (data) => {
    onSaveCollectionSuggestion("@" + data.name)
    setPendingCollection("@" + data.name)
    setShowModal(false);
    reqCollections.refetch();
  }

  const onInputChange = (idx, inputValue, reason) => {
    let newState = lib.parseValueToState(
      value, nodes, collections, datatypes, selectorFilters, acceptTypes, showCollectionAddButton)
    newState = newState.slice(0, idx + 1)
    if (idx > 0 && reason == "clear") {
      let prevOption = newState[idx - 1].options.find(opt => opt.value == newState[idx - 1].selectedOption)
      if (acceptTypes == "all" || acceptTypes.includes(prevOption?.data?.type))
        newState = newState.slice(0, idx)
      else
        newState[idx].selectedOption = inputValue;
    } else
      newState[idx].selectedOption = inputValue;

    const newValue = lib.parseStateToValue(newState)
    const newType = newState[parseInt(idx)]?.options.find(opt => opt.value == inputValue)?.type
    const nextState = lib.parseValueToState(
      newValue + ".", nodes, collections, datatypes, selectorFilters, acceptTypes, showCollectionAddButton);
    const nextOptions = nextState[idx + 1]?.options ?? []

    const fieldValue = (reason == "select-option" || (acceptTypes != "all" && !acceptTypes.includes(newType))) &&
      nextOptions.length > 0 && enableAttributes && !displayAttributesManually ? (newValue + ".") : newValue
    setFieldValue(name, fieldValue);
    onChange(fieldValue)
  }

  const onCollectionFilterChange = (value) => {
    if (value != null) {
      let newState = [...state]
      newState[0].condition = value
      const fieldValue = lib.parseStateToValue(state)
      setFieldValue(name, fieldValue)
      onChange(fieldValue)
    }
  }

  const onConditionSelectChange = (value) => {
    setShowCondition(value != "all")
    if (value == "all") {
      setFieldValue("temp_filter_" + conditionTempName, {})
      onCollectionFilterChange({})
    }
  }

  const onClickAttributeButton = () =>
    setFieldValue(name, value + ".")

  if (reqNodes.loading || reqEdges.loading || reqCollections.loading || reqDataTypes.loading) return <Loading size={27} />;
  if (reqNodes.error || reqEdges.error || reqCollections.error || reqDataTypes.error) return "Error";

  if (openText && placeholder == "Selecciona una referencia del proceso")
    placeholder = "Ingresa un valor"

  const nodes = [];
  const tempNodes = [...reqNodes.data.flowNodes];
  const tempEdges = [...reqEdges.data.flowEdges];
  const collections = reqCollections.data.collections;
  const datatypes = reqDataTypes.data.appDatatypes

  // Assign IDX to nodes
  const parsedNodes = parser.parseNodesDatabaseToUi(tempNodes);
  const parsedEdges = parser.parseEdgesDatabaseToUi(tempEdges);
  const nodesWithIdx = assignIdxNodes(parsedNodes, parsedEdges);

  for (const node of nodesWithIdx) {
    let currentNode = {...tempNodes.find(nd => nd.id == node.data.remoteId)};
    if (currentNode && currentNode.action.outputStructure.length > 0 && currentNode.id != nodeId) {
      currentNode.idx = node.data.idx
      nodes.push(currentNode)
    }
  }  

  const state = lib.parseValueToState(
    value, nodes, collections, datatypes, selectorFilters, acceptTypes, showCollectionAddButton);
  const nextState = lib.parseValueToState(
    value + ".", nodes, collections, datatypes, selectorFilters, acceptTypes, showCollectionAddButton);

  if (pendingCollection && state.length > 0) {
    let collectionOption = state[0].options.find(option => option.value == pendingCollection)
    if (collectionOption) {
      setPendingCollection(null)
      onInputChange(0, pendingCollection, "select-option")
    }
  }

  const saveCollectionSource = saveCollectionColumns?.source
  const saveCollectionValue = saveCollectionColumns?.value
  let saveCollectionSchema = []
  if (saveCollectionSource == "RAW")
    saveCollectionSchema = saveCollectionValue


  const selectedCollection = collections.find(
    collection => state.length > 0 && collection.name == (state[0].selectedOption ?? "").replaceAll("@", ""))
  const conditionStructure = selectedCollection ? selectedCollection.schema.structure : null;
  const conditionColumn = (conditionStructure ?? []).find(
    column => state.length > 1 && column.name == (state[state.length - 1].selectedOption ?? "").replaceAll("@", "")
  ) ?? (conditionStructure ?? []).length > 0 ? conditionStructure[0].name : ""
  const conditionState = state.length > 0 ? state[0].condition ?? {} : {}
  const conditionStr = lib.parseConditionStateToStr(conditionState)


  return (
    <View
      name={name}
      label={label}
      required={required}
      disabled={disabled}
      openText={openText}
      enableAttributes={enableAttributes}
      caption={caption}
      helpMessage={helpMessage}
      placeholder={placeholder}
      textType={textType}
      flowId={flowId}
      values={values}
      state={state}
      nextState={nextState}
      showModal={showModal}
      showOptionsWhenEmpty={showOptionsWhenEmpty}
      showCondition={showCondition}
      conditionTempName={conditionTempName}
      conditionStructure={conditionStructure}
      conditionColumn={conditionColumn}
      conditionState={conditionState}
      conditionStr={conditionStr}
      isAdvancedOptionsShown={isAdvancedOptionsShown}
      saveCollectionSchema={saveCollectionSchema}
      showCollectionCondition={showCollectionCondition}
      showAdvancedOptions={showAdvancedOptions}
      showAcceptButton={showAcceptButton}
      showTitleOption={showTitleOption}
      showColumnsOption={showColumnsOption}
      showViewOption={showViewOption}
      showEditableOption={showEditableOption}
      showFiltersOption={showFiltersOption}
      setFieldValue={setFieldValue}
      setIsAdvancedOptionsShown={setIsAdvancedOptionsShown}
      onInputChange={onInputChange}
      onClickArrow={onClickArrow}
      onClickAttributeButton={onClickAttributeButton}
      onCloseCollectionModal={onCloseCollectionModal}
      onSaveCollection={onSaveCollection}
      onClickAddCollection={onClickAddCollection}
      onConditionSelectChange={onConditionSelectChange}
      onCollectionFilterChange={onCollectionFilterChange}
    />
  );
}

export default VariableSelector;