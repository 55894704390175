import React, { useState, useEffect, useRef } from "react";
import { useDetail, useQuery } from "seed/gql";
import { usePost } from "seed/api";
import { Loading } from "seed/helpers";
import { flowTutorialSteps } from "components/util/tour/TourData";
import * as parser from "components/studio/Controller.lib.parser";
import View from "components/studio/Studio.view";

function Studio({ match, isAnonymous = false }) {

  const userId = sessionStorage.getItem("id");
  const flowId = match?.params?.flowId;
  const isDeveloper = sessionStorage.getItem("developer_mode") == "true";
  const [activePage, setActivePage] = useState(sessionStorage.getItem("page_" + flowId));
  const [history, setHistory] = useState([{ nodes: [], edges: [], annotations: [] }]);
  const [historyPosition, setHistoryPosition] = useState(0);
  const [callActivateApps, reqActivateApps] = usePost('/flows/update_apps');
  const execModeRef = useRef(null);
  const controllerRef = useRef(null);

  useEffect(() => {
    callActivateApps({
      flowId: flowId,
    })
  }, [])

  const filterPage = `flow.id=${flowId} AND ` + (activePage ? `page.id=${activePage}` : "page.isDefault=True");

  const reqUser = useDetail(`{
    user {
      firstName
        lastName
        email
        license {
          name
      }
      accountSettings
    }
  }`, userId)

  const reqFlow = useDetail(`{
    flow {
      name
      description
      token
      settings
      executions {
        status
        startDate
        isRead
      }
      user {
        email
      }
    }
  }`, flowId);

  const reqNodes = useQuery(`{
    flowNodes {
      createdAt
      inputValues
      outputValues
      uiSettings
      isEnabled
      action { 
        name
        type
        isEditable
        inputStructure
        outputStructure
        uiSettings
        isAnnotation
        app { 
          name
          uiSettings
        }
      }
      isVerified
      isIterable
      iterableField
      flow { name }
    }
  }`, filterPage, {
    onCompleted: (data) => {
      const flowNodes = data?.flowNodes ?? [];
      const nodes = parser.parseNodesDatabaseToUi(flowNodes);
      setHistory((prevHistory) => {
        if (typeof prevHistory == "object" && prevHistory.length > 0) {
          prevHistory[0].nodes = nodes;
        }
        return prevHistory;
      });
    }
  });

  const reqEdges = useQuery(`{
    flowEdges {
      type
      createdAt
      sourceName
      targetName
      source { }
      target { }
      flow { name }
    }
  }`, filterPage, {
    onCompleted: (data) => {
      const flowEdges = data?.flowEdges ?? [];
      const edges = parser.parseEdgesDatabaseToUi(flowEdges);
      setHistory((prevHistory) => {
        if (typeof prevHistory == "object" && prevHistory.length > 0) {
          prevHistory[0].edges = edges;
        }
        return prevHistory;
      });
    }
  });

  const reqAnnotations = useQuery(`{
    flowAnnotations {
      createdAt
      inputValues
      uiSettings      
      action { 
        name
        type
        isEditable
        inputStructure
        outputStructure
        uiSettings
        isAnnotation
        app { 
          name
          uiSettings
        }
      }
      flow { name }
    }
  }`, filterPage, {
    onCompleted: (data) => {
      const flowAnnotations = data?.flowAnnotations ?? [];
      const annotations = parser.parseAnnotationsDatabaseToUi(flowAnnotations);
      setHistory((prevHistory) => {
        if (typeof prevHistory == "object" && prevHistory.length > 0) {
          prevHistory[0].annotations = annotations;
        }
        return prevHistory;
      });
    }
  });

  const reqActions = useQuery(`{
    actions {
      name
      type
      isEditable
      inputStructure
      outputStructure
      uiSettings
      isAnnotation
      app { name }
    }
  }`)

  const reloadFlow = () => {
    setHistory([{ nodes: [], edges: [], annotations: [] }]);
    setHistoryPosition(0);
    reqFlow.refetch();
    reqNodes.refetch();
    reqEdges.refetch();
    reqAnnotations.refetch();
  }

  const onUpdateHistory = (allNodes, edges) => {
    const nodes = allNodes.filter((item) => !item.isAnnotation);
    const annotations = allNodes.filter((item) => item.isAnnotation);
    setHistory((prev) => [...prev.slice(0, historyPosition + 1), { nodes: nodes, edges: edges, annotations: annotations }]);
    setHistoryPosition((prev) => prev + 1);
  }

  const onCloseModalController = () => {
    if (controllerRef.current) {
      controllerRef.current.onCloseModalRoute();
    }
  }

  const { flow = {} } = reqFlow.data;
  const { flowNodes = [] } = reqNodes.data;
  const { flowEdges = [] } = reqEdges.data;
  const { flowAnnotations = [] } = reqAnnotations.data;
  const { actions = [] } = reqActions.data;
  const { user = {} } = reqUser.data;

  useEffect(() => {
    reloadFlow();
  }, [activePage])

  if (reqFlow.loading || reqNodes.loading || reqEdges.loading ||
    reqAnnotations.loading || reqActions.loading || reqUser.loading) 
      return <PlaceholderView />;
  if (reqFlow.error || reqNodes.error || reqEdges.error ||
    reqAnnotations.error || reqActions.error || reqUser.error) 
      return <div></div>;

  const tutorialSettings = user.accountSettings?.tutorial ?? []
  const flowTutorial = tutorialSettings.find((tutorial) => tutorial.name == "flow") || {};
  const isTutorialFinished = flowTutorial?.is_finished ?? false;
  const currentTutorialStep = flowTutorial?.current_step ?? 0;

  const nodes = parser.parseNodesDatabaseToUi(flowNodes);
  const edges = parser.parseEdgesDatabaseToUi(flowEdges);
  const annotations = parser.parseAnnotationsDatabaseToUi(flowAnnotations);

  return (
    <View
      flow={flow}
      user={user}
      nodes={nodes}
      edges={edges}
      annotations={annotations}
      actions={actions}
      activePage={activePage}
      tutorialSteps={flowTutorialSteps}
      currentTutorialStep={currentTutorialStep}
      history={history}
      historyPosition={historyPosition}
      isTutorialShown={!isTutorialFinished}
      isAnonymous={isAnonymous}
      isDeveloper={isDeveloper}
      execModeRef={execModeRef}
      controllerRef={controllerRef}
      setActivePage={setActivePage}
      setHistoryPosition={setHistoryPosition}
      reloadFlow={reloadFlow}
      onUpdateHistory={onUpdateHistory}
      onCloseModalController={onCloseModalController}
    />
  );
}

const PlaceholderView = () =>
  <div className="content container-fluid p-0">
    <div className="d-flex justify-content-center">
      <div className="bg-white" style={{ width: "100%", height: "100vh", overflowY: "auto" }}>
        <div class="card-body" style={{ padding: "10rem 0" }}>
          <Loading />
        </div>
      </div>
    </div>
  </div>

export default Studio;