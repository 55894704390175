import React from "react";
import { useHistory } from 'react-router-dom';
import { useNodes } from 'reactflow';
import { useDetail } from "seed/gql";
import { Loading } from "seed/helpers";
import View from "components/board/annotations/Pipe.view";

function Pipe({ id, data }) {

  const nodes = useNodes();
  const input = data?.input_values ?? [];
  const action = data?.action ?? {};
  const isEditable = action?.isEditable;
  const title = input.find((inp) => inp.name == "title")?.value ?? "";
  const orientation = input.find((inp) => inp.name == "orientation")?.value ?? "";
  const historyRouter = useHistory();

  
  const onEditAnnotation = () => {
    if (!isEditable) return;
    const annotation = nodes.find((nd) => nd.id == id);
    if (annotation) {
      const url = `/flow/edit_annotation/${annotation.remoteId}`
      historyRouter.replace(url);
    }
    return nodes;
  }

  return (
    <View
      title={title}
      orientation={orientation}
      isEditable={isEditable}
      onEditAnnotation={onEditAnnotation}
    />
  );
}

export default Pipe;