import React from "react";
import { Formik, Field, Form } from "formik";
import { ScriptTag } from "seed/helpers";
import { Loading } from "seed/helpers";
import { Analytics } from "components/util/analytics";

const LoginView = ({ error, isLoading, onSubmit, onMicrosoftLogin, onGoogleLogin }) =>

  <main id="content" role="main" class="main pl-0 h-100 w-100" style={{overflowY: "auto"}}>

    <div class="container py-5 py-sm-7">
      <a class="d-flex justify-content-center mb-6" href={"/"}>
        <img class="z-index-2"
          src="/theme/svg/logos/logo-white.svg" alt="Logo" style={{ height: "2.65rem" }} />
      </a>

      <div class="row justify-content-center">
        <div class="col-md-7 col-lg-5">
          <div class="card card-lg mb-5 pt-1 px-1 pb-2 animate__animated animate__fadeInUp animate__faster"
            style={{ border: "1px solid #d2dae0" }}>
            <div class="card-body">
              <Formik
                initialValues={{}}
                onSubmit={onSubmit}>
                {() =>
                  <Form>
                    <div class="text-center">
                      <div class="mb-5">
                        <h1 class="display-4">Iniciar Sesión</h1>
                      </div>
                    </div>

                    {/* Email */}
                    <div class="form-group">
                      <label class="input-label" for="formEmail">Usuario</label>
                      <Field type="email" name="email" id="formEmail"
                        class="form-control form-control-lg" tabindex="1"
                        placeholder="email@address.com" required autofocus="1" />
                    </div>

                    {/* Password */}
                    <div class="form-group">
                      <label class="input-label" for="formPassword">
                        <span class="d-flex justify-content-between align-items-center">
                          Contraseña
                          <a class="input-label-secondary" href="#">¿Olvídaste tu contraseña?</a>
                        </span>
                      </label>

                      <div class="input-group input-group-merge">
                        <Field type="password" name="password" id="formPassword"
                          class="js-toggle-password form-control form-control-lg" tabindex="2"
                          placeholder="password" required
                          data-hs-toggle-password-options={`{
                                "target": "#changePassTarget",
                                "defaultClass": "tio-hidden-outlined",
                                "showClass": "tio-visible-outlined",
                                "classChangeTarget": "#changePassIcon"
                              }`} />
                        <div id="changePassTarget" class="input-group-append">
                          <a class="input-group-text">
                            <i id="changePassIcon" class="tio-visible-outlined"></i>
                          </a>
                        </div>
                      </div>
                    </div>

                    {/* Remember me */}
                    <div class="form-group">
                      <div class="custom-control custom-checkbox">
                        <Field type="checkbox" name="rememberMe"
                          id="formRememberMe" class="custom-control-input" />
                        <label class="custom-control-label text-muted" for="formRememberMe">
                          Recordar cuenta
                        </label>
                      </div>
                    </div>


                    <button type="submit" class="btn btn-lg btn-block btn-success" onClick={() => Analytics.CreateEvent("App-Button-Iniciar sesión")}>
                      Iniciar sesión
                    </button>

                    {error ?
                      <div class="alert alert-soft-danger text-center mt-2" role="alert">
                        {error}
                      </div> : null}

                    {/* Divider */}
                    <span class="divider text-muted mb-4 mt-4">O</span>

                    {/* Google and Microsoft Login Buttons */}
                    <div className="form-group mb-1">
                      <button type="button" className="btn btn-lg btn-block btn-outline-secondary mb-2" onClick={onMicrosoftLogin}>
                        <i className="fab fa-microsoft" style={{ marginRight: '8px' }}></i>
                        Iniciar sesión con Microsoft
                      </button>
                      <button type="button" className="btn btn-lg btn-block btn-outline-secondary" onClick={onGoogleLogin}>
                        <i className="fab fa-google" style={{ marginRight: '8px' }}></i>
                        Iniciar sesión con Google
                      </button>
                    </div>
                    
                  </Form>}
              </Formik>
            </div>
          </div>

        </div>
      </div>

      {isLoading ? <div style={{ marginTop: "-30rem" }}><Loading /></div> : null}

    </div>

    <ScriptTag content={`
      $('.js-toggle-password').each(function () {
        new HSTogglePassword(this).init()
      });
    `} />

  </main>;

export default LoginView;