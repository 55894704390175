/* eslint-disable max-len */
import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Modal } from "seed/helpers";
import { formatNumber } from "components/collections/util/format";
import Table from "components/collections/Table";
import FormSave from "components/collections/forms/FormSave";
import FormSet from "components/collections/forms/FormSet";
import FormImport from "components/collections/forms/FormImport";
import HelpMessage from 'components/util/helpers/HelpMessage';
import "styles/css/collections/Collections.css";
import CollapseMenu from "components/util/helpers/CollapseMenu";


const createTableMessage = "Crea tablas personalizadas para guardar información del proceso";
const importExcelDataMessage = "Importa datos desde un archivo Excel para integrarlos en tu proceso"


const CollectionsView = ({
  styles,
  flowId,
  showTabs,
  columns,
  rows,
  pageNum,
  pageSize,
  totalPages,
  collections,
  collectionColumns,
  activeCollectionId,
  query,
  filters,
  search,
  height,
  loading,
  currentTutorialStep,
  isTutorialShown,
  isModalOpen,
  isEditModalOpen,
  isImportModalOpen,
  isImportModalShown,
  isExportModalShown,
  isFilterModalShown,
  isOptionModalShown,
  isReadOnly,
  onClickTab,
  onClickDelete,
  setIsModalOpen,
  setIsEditModalOpen,
  setIsImportModalOpen,
  onAddColumn,
  onClickEdit,
  onChangeColumn,
  onChangeColumnOptions,
  onDeleteColumn,
  onAddRow,
  onDeleteRow,
  onInsertRow,
  onChangeRow,
  onImport,
  onExport,
  onDownloadZip,
  onChangeSearch,
  onPageChange,
  onChangeQuery,
  onChangeFilters,
  onClickCell,
  onSaveCollection,
  onEditCollection,
}) => (
  <div id="tables" class="px-2 py-1" style={styles}>

    <BrowserRouter basename="/app/data">


      {showTabs && (
        <div className="w-100" id="tables-header">
          <div id="collection-tabs" className="scrollbar-narrow d-flex w-100 align-items-baseline pt-3 px-3 pb-2">
            {(collections ?? []).map((collection, index) => (
              <a
                key={collection.id}
                className={"btn collection-tab d-flex align-items-center mr-2 px-3 py-0 " +
                  (activeCollectionId == collection.id ? "active" : "")}
              >
                <span className="text-truncate py-2" onClick={() => onClickTab(collection.id)}>{collection.name}</span>
              </a>
            ))}

            {!isReadOnly && (
              <div id="add-collection-tab">

                <div class="dropdown">

                  <button
                    id="create-table-dropdown"
                    class="btn collection-tab d-flex align-items-center dropdown-toggle"
                    type="button"
                    data-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span>
                      <i className="fas fa-plus mr-2 tab-icon"></i>
                      <span>Crear tabla</span>
                    </span>
                  </button>

                  <div id="create-table-dropdown-content">
                    <div>
                      <div class="dropdown-menu" aria-labelledby="create-table-dropdown">

                      <HelpMessage message={importExcelDataMessage}>
                          <span class="dropdown-item" style={{ cursor: "pointer" }} onClick={() => setIsImportModalOpen(true)}>
                            <i class="tio-file-add dropdown-item-icon"></i>
                            <span>Importar desde excel</span>
                          </span>
                        </HelpMessage>


                        <HelpMessage message={createTableMessage}>
                          <span class="dropdown-item" style={{ cursor: "pointer" }} onClick={() => setIsModalOpen(true)}>
                            <div>
                              <i class="tio-table dropdown-item-icon"></i>
                              <span>Crear tabla</span>
                            </div>
                          </span>
                        </HelpMessage>

                       
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            )}

          </div>
        </div>
      )}

      <div className="h-100" id="tables-body">

        {!activeCollectionId && !loading && (
          <div className="w-100 h-100" id="no-tables-content">
            <div className="d-flex flex-column justify-content-center align-items-center w-100 h-75 pb-7">
              <h3 className="text-muted text-center" style={{ width: "27rem" }}>
                {
                  collections && collections.length > 0 && !isReadOnly
                    ? <span>No se ha seleccionado ninguna tabla</span>
                    : <span>
                      <span className="mb-1">Aún no tienes tablas creadas<br /></span>
                      <small>Utiliza las tablas para guardar información que genere o utilice tu proceso.</small>
                    </span>
                }
              </h3>
              {
                collections && collections.length == 0 && !loading && !isReadOnly &&
                <div id="no-table-ctas" className="d-flex justify-content-center">
                  <div>
                    <HelpMessage message={createTableMessage}>
                      <button
                        type="button"
                        className="btn btn-lighter-white mt-2"
                        onClick={() => setIsModalOpen(true)}
                      >
                        <i className="fas fa-plus mr-2"></i>
                        Crear tabla
                      </button>
                    </HelpMessage>
                  </div>
                  <div>
                    <HelpMessage message={importExcelDataMessage}>
                      <button
                        type="button"
                        className="btn btn-lighter-white mt-2 ml-2"
                        onClick={() => setIsImportModalOpen(true)}
                      >
                        <i className="fas fa-file-import mr-2"></i>
                        Importar excel
                      </button>
                    </HelpMessage>
                  </div>
                </div>
              }
            </div>
          </div>
        )}

        
        {
          function(){
            
            const activeCollection = collections.find(collection => collection.id == activeCollectionId);
            if(isReadOnly) return;
            if(!activeCollection) return;

            return <div id="table-title" className="d-flex align-items-center w-50">
              <h2 className='ml-3 my-auto mr-2'>
                {activeCollection.name}
              </h2>
              <CollapseMenu
                component={
                  <button 
                    type="button" 
                    class="btn p-0 px-1 ml-1"
                    style={{color: "#888"}} 
                  >
                    <i class="fas fa-edit"></i>
                  </button>
                }
                options={[
                  {
                    label: <> <i class="tio-edit dropdown-item-icon"></i> Editar</>,
                    onClick: () => onClickEdit(activeCollection.id)
                  },
                  {
                    label: <> <i class="tio-delete dropdown-item-icon"></i> Eliminar</>,
                    onClick: () => onClickDelete(activeCollection.id)
                  }
                ]}
              />
            </div>

          }()
        }

        {activeCollectionId && (
          <div className="px-3 mt-4">
            <Table
              trackKeybindings={(
                !isModalOpen && !isEditModalOpen && !isImportModalShown &&
                !isExportModalShown && !isFilterModalShown && !isOptionModalShown
              )}
              isReadOnly={isReadOnly || !showTabs}
              useCustomPagination={true}
              useCustomSearch={true}
              useCustomFilters={true}
              useCustomImport={true}
              useCustomExport={true}
              showExport={!isReadOnly}
              showImport={!isReadOnly}
              showOptions={!isReadOnly}
              showSearch={!isReadOnly}
              showFilters={!isReadOnly}
              checkValidation={true}
              pageNum={pageNum}
              pageSize={pageSize}
              totalPages={totalPages}
              values={rows}
              structure={columns}
              collections={collections}
              collectionColumns={collectionColumns}
              query={query}
              filters={filters}
              search={search}
              height={height}
              loading={loading}
              onAddColumn={onAddColumn}
              onChangeColumn={onChangeColumn}
              onChangeColumnOptions={onChangeColumnOptions}
              onDeleteColumn={onDeleteColumn}
              onAddRow={onAddRow}
              onDeleteRow={onDeleteRow}
              onInsertRow={onInsertRow}
              onChangeRow={onChangeRow}
              onImport={onImport}
              onExport={onExport}
              onDownloadZip={onDownloadZip}
              onPageChange={onPageChange}
              onChangeSearch={onChangeSearch}
              onChangeQuery={onChangeQuery}
              onChangeFilters={onChangeFilters}
              onClickCell={onClickCell}
              onShowEditModal={() => setIsEditModalOpen(true)}
              folioComponent={(data, _) => {
                const collection = collections?.find(c => c.id == activeCollectionId);
                if (!data.folio) return;
                if (!collection) return <label className="text-center" style={{ minWidth: "2.85rem" }}>{data.folio}</label>;
                return <label className="text-center" style={{ minWidth: "2.85rem" }}>{collection.initials}-{formatNumber(data.folio)}</label>;
              }}
            />
          </div>
        )}

      </div>

      {
        isModalOpen && <Modal
          width="600"
          height="550"
          flowId={flowId}
          onSave={onSaveCollection}
          onClose={() => setIsModalOpen(false)}
          component={FormSave}
        />
      }

      {
        isEditModalOpen && <Modal
          width="600"
          height="550"
          flowId={flowId}
          onSave={onEditCollection}
          collectionId={activeCollectionId}
          onClose={() => setIsEditModalOpen(false)}
          component={FormSet}
        />
      }

      {
        isImportModalOpen && <Modal
          width="600"
          height="550"
          flowId={flowId}
          onSave={onEditCollection}
          collectionId={activeCollectionId}
          onClose={() => setIsImportModalOpen(false)}
          component={FormImport}
        />
      }

    </BrowserRouter>
  </div>
);

export default CollectionsView;